import { Pipe, PipeTransform } from '@angular/core';

/**
 * Formatiert Byteangaben in eien Leserliche Form
 */
@Pipe({
  name: 'formatFilesize'
})
export class FormatFilesizePipe implements PipeTransform {
  /**
   * Wandelt die Größe einer Datei von Byte in Kilo-, Mega- oder Gigabyte um
   * @param filesize größe der Datei
   * @param decimalDigits Anzahl der Dezimalstellen
   */
  transform(filesize: number, decimalDigits: number = 2): string {
    if (filesize > 1000000000) {
      return (filesize / 1000000000).toFixed(decimalDigits) + ' GB';
    }
    if (filesize > 1000000) {
      return (filesize / 1000000).toFixed(decimalDigits) + ' MB';
    }
    if (filesize > 1000) {
      return (filesize / 1000).toFixed(decimalDigits) + ' KB';
    }
    return filesize + ' Bytes';
  }
}
