import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import * as headerActions from './actions/header.action';
import * as sessionActions from './actions/session.action';
import { ClientState } from './client.state';
export const DEFAULT_HEADER = {
    hideBack: true,
    backText: null,
    backIcon: null,
    backFn: null,
    showHomeBtn: false,
    showSettingsBtn: false,
    headline: '',
    translateHeadline: false,
    showUserProfile: false
};
export const APPLICATION_STATE_NAME = 'application';
/**
 * Application State
 * Hier wird ueber die State annotation dem Store mittgeteilt wie
 * das State Slice mit dem Namen application aussehen soll. Zudem befinden
 * sich in dieser Klasse alle Selectoren & die Reducer Funktionen die mit
 * Action annotiert sind.
 */
let ApplicationState = class ApplicationState {
    constructor() { }
    /**
     * Gibt die aktuelle Session zurück
     */
    static selectSession(state) {
        return state.session;
    }
    /**
     * Gibt die aktuellen HeaderParams zurück
     */
    static selectHeaderParams(state) {
        return state.header;
    }
    /**
     * Setzt die aktuelle Session
     */
    setSession(ctx, action) {
        ctx.patchState({ session: action.payload });
    }
    /**
     * patcht die aktuelle Session
     */
    patchSession(ctx, action) {
        ctx.patchState({
            session: Object.assign({}, ctx.getState().session, action.payload)
        });
    }
    /**
     * patcht die HeaderParams
     */
    setHeaderParams(ctx, action) {
        ctx.patchState({
            header: Object.assign({}, ctx.getState().header, action.payload)
        });
    }
};
tslib_1.__decorate([
    Action(sessionActions.SetSession),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, sessionActions.SetSession]),
    tslib_1.__metadata("design:returntype", void 0)
], ApplicationState.prototype, "setSession", null);
tslib_1.__decorate([
    Action(sessionActions.PatchSession),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, sessionActions.PatchSession]),
    tslib_1.__metadata("design:returntype", void 0)
], ApplicationState.prototype, "patchSession", null);
tslib_1.__decorate([
    Action(headerActions.PatchHeaderParams),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, headerActions.PatchHeaderParams]),
    tslib_1.__metadata("design:returntype", void 0)
], ApplicationState.prototype, "setHeaderParams", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Object)
], ApplicationState, "selectSession", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Object)
], ApplicationState, "selectHeaderParams", null);
ApplicationState = tslib_1.__decorate([
    State({
        name: APPLICATION_STATE_NAME,
        defaults: {
            session: undefined,
            header: Object.assign({}, DEFAULT_HEADER)
        },
        children: [ClientState]
    }),
    tslib_1.__metadata("design:paramtypes", [])
], ApplicationState);
export { ApplicationState };
