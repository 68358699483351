import * as i0 from "@angular/core";
import * as i1 from "../utils/injection-tokens";
/**
 * Behandelt `The "Unicode Problem"`.
 *
 * Solution #2 –JavaScript's UTF-16 => UTF8-in-16
 * {@link https://wiki.developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding$revision/1539905#The_Unicode_Problem}
 */
export class Base64Service {
    constructor(window) {
        this.window = window;
        // IE Polyfill
        this.clz32 = Math.clz32 || (x => (31 - Math.log(x >>> 0) / Math.LN2) | 0);
    }
    encode(inputString, BOMit) {
        return this.window.btoa((BOMit ? '\xEF\xBB\xBF' : '') +
            inputString.replace(/[\x80-\uD7ff\uDC00-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]?/g, (nonAsciiChars) => this.btoaReplacer(nonAsciiChars)));
    }
    decode(inputString, keepBOM) {
        if (!keepBOM && inputString.substring(0, 3) === '\xEF\xBB\xBF') {
            inputString = inputString.substring(3); // eradicate UTF-8 BOM
        }
        // 0xc0 => 0b11000000; 0xff => 0b11111111; 0xc0-0xff => 0b11xxxxxx
        // 0x80 => 0b10000000; 0xbf => 0b10111111; 0x80-0xbf => 0b10xxxxxx
        return this.window
            .atob(inputString)
            .replace(/[\xc0-\xff][\x80-\xbf]*/g, (encoded) => this.atobReplacer(encoded));
    }
    btoaReplacer(nonAsciiChars) {
        // make the UTF string into a binary UTF-8 encoded string
        let point = nonAsciiChars.charCodeAt(0);
        if (point >= 0xd800 && point <= 0xdbff) {
            const nextcode = nonAsciiChars.charCodeAt(1);
            if (nextcode !== nextcode) {
                // NaN because string is 1 code point long
                return String.fromCharCode(0xef /*11101111*/, 0xbf /*10111111*/, 0xbd /*10111101*/);
            }
            // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
            if (nextcode >= 0xdc00 && nextcode <= 0xdfff) {
                point = (point - 0xd800) * 0x400 + nextcode - 0xdc00 + 0x10000;
                // if (point > 0xffff) {
                return String.fromCharCode((0x1e /*0b11110*/ << 3) | (point >>> 18), (0x2 /*0b10*/ << 6) | ((point >>> 12) & 0x3f) /*0b00111111*/, (0x2 /*0b10*/ << 6) | ((point >>> 6) & 0x3f) /*0b00111111*/, (0x2 /*0b10*/ << 6) | (point & 0x3f) /*0b00111111*/);
                // }
            }
            // else {
            return String.fromCharCode(0xef, 0xbf, 0xbd);
            // }
        }
        if (point <= 0x007f) {
            return nonAsciiChars;
        }
        if (point <= 0x07ff) {
            return String.fromCharCode((0x6 << 5) | (point >>> 6), (0x2 << 6) | (point & 0x3f));
        }
        return String.fromCharCode((0xe /*0b1110*/ << 4) | (point >>> 12), (0x2 /*0b10*/ << 6) | ((point >>> 6) & 0x3f) /*0b00111111*/, (0x2 /*0b10*/ << 6) | (point & 0x3f) /*0b00111111*/);
    }
    atobReplacer(encoded) {
        let codePoint = encoded.charCodeAt(0) << 24;
        const leadingOnes = this.clz32(~codePoint);
        let endPos = 0;
        const stringLen = encoded.length;
        let result = '';
        if (leadingOnes < 5 && stringLen >= leadingOnes) {
            codePoint = (codePoint << leadingOnes) >>> (24 + leadingOnes);
            for (endPos = 1; endPos < leadingOnes; ++endPos) {
                codePoint = (codePoint << 6) | (encoded.charCodeAt(endPos) & 0x3f) /*0b00111111*/;
            }
            if (codePoint <= 0xffff) {
                // BMP code point
                result += String.fromCharCode(codePoint);
            }
            else if (codePoint <= 0x10ffff) {
                // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
                codePoint -= 0x10000;
                result += String.fromCharCode((codePoint >> 10) + 0xd800, // highSurrogate
                (codePoint & 0x3ff) + 0xdc00 // lowSurrogate
                );
            }
            else {
                endPos = 0; // to fill it in with INVALIDs
            }
        }
        for (; endPos < stringLen; ++endPos) {
            result += '\ufffd'; // replacement character
        }
        return result;
    }
}
Base64Service.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Base64Service_Factory() { return new Base64Service(i0.ɵɵinject(i1.WINDOW_REF)); }, token: Base64Service, providedIn: "root" });
