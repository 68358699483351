import { NgZone } from '@angular/core';
import { fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EnvironmentService } from './environment.service';
import { filter, map, first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppStorageService } from '../statemanagement/app-storage.service';
import { isPresent } from '../utils/fp-utils';
import { onZone } from '../utils/operator-utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./environment.service";
import * as i4 from "@angular/common";
import * as i5 from "../statemanagement/app-storage.service";
export class NavigationService {
    constructor(router, dialog, enviroService, location, appStorageService, zone, document) {
        this.router = router;
        this.dialog = dialog;
        this.enviroService = enviroService;
        this.location = location;
        this.appStorageService = appStorageService;
        this.zone = zone;
        this.document = document;
        /**
         * Unter Android soll wenn der Backbutton geklickt wird zurück navigiert werden,
         * falls ein Dialog geöffnet ist soll dieser zunächst geschlossen werden
         */
        if (this.enviroService.isAndroid) {
            fromEvent(this.document, 'backbutton')
                .pipe(onZone(this.zone))
                .subscribe(ev => {
                // Dialoge geöffnet -> obersten schließen
                const openDialogs = this.dialog.openDialogs;
                if (openDialogs && openDialogs.length > 0) {
                    const highestDialog = openDialogs[openDialogs.length - 1];
                    highestDialog.close();
                }
                else {
                    // Kein Dialog geöffnet -> zurück navigieren
                    this.back();
                }
            });
        }
    }
    /**
     * Navigiert zurück
     */
    back() {
        this.location.back();
    }
    /**
     * Navigiert auf die Startseite der Anwendung
     * @param replaceUrl Flag, ob die aktuelle URL ersetzt werden soll
     */
    openDefaultFirstRoute(replaceUrl = false) {
        this.appStorageService
            .selectSession()
            .pipe(filter(isPresent), first(), map(session => this.router.navigate(['clients', session.clientId], { replaceUrl })))
            .subscribe();
    }
}
NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.MatDialog), i0.ɵɵinject(i3.EnvironmentService), i0.ɵɵinject(i4.Location), i0.ɵɵinject(i5.AppStorageService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i4.DOCUMENT)); }, token: NavigationService, providedIn: "root" });
