import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FullSizeLoadingSpinnerComponent } from 'src/app/common-ui/full-size-loading-spinner/full-size-loading-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingCircleService {
  private overlayRef: OverlayRef = null;

  constructor(private overlay: Overlay) {}

  open(): void {
    if (!this.overlayRef) {
      const cdkConfig: OverlayConfig = {
        hasBackdrop: true,
        disposeOnNavigation: true,
        scrollStrategy: this.overlay.scrollStrategies.block(),
        positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically()
      };
      this.overlayRef = this.overlay.create(cdkConfig);

      const userMenuPortal = new ComponentPortal(FullSizeLoadingSpinnerComponent);
      this.overlayRef.attach(userMenuPortal);
    }
  }

  close(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
