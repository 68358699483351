import * as tslib_1 from "tslib";
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../utils/injection-tokens";
/**
 * Service für den Zugriff auf den LocalStorage.
 */
export class LocalDatabaseService {
    /**
     * @ignore
     */
    constructor(window) {
        this.window = window;
        this.dmProm = new Promise((resolve, reject) => {
            if (environment.isCordova) {
                document.addEventListener('deviceready', () => this.initDB(resolve, reject), false);
            }
            else {
                this.initDB(resolve, reject);
            }
        });
    }
    /**
     * @ignore
     */
    initDB(resolve, reject) {
        try {
            let db;
            if (this.window.sqlitePlugin) {
                db = this.window.sqlitePlugin.openDatabase({ name: 'cocuun.db' });
            }
            else {
                db = this.window.openDatabase('cocuunDB', '', 'Cocuun Database', 2 * 1024 * 1024);
            }
            console.log(`DB current version: ${db.version}`);
            resolve(db);
        }
        catch (e) {
            reject(e);
        }
    }
    /**
     * Führt eine Aktualisierung der bestehenden Datenbank auf eine neuere Version durch.
     * https://www.w3.org/TR/webdatabase/#introduction
     *
     * @param newVersion neue Versionsnummer
     * @param statement das Statement welches für die Migration ausgeführt werden soll
     */
    upgradeDBVersion(newVersion, statement) {
        return new Promise((resolve, reject) => {
            this.dmProm.then(db => {
                console.log(`DB try update to version: ${newVersion}`);
                if (db.version < newVersion) {
                    // Aktualisierung der DB-Version durchführen.
                    db.changeVersion(db.version, newVersion, tx => {
                        // Update der DB durchführen
                        tx.executeSql(statement);
                    }, err => {
                        console.warn(`DB update failed. Current DB version: ${db.version}`, err);
                        reject(err);
                    }, () => {
                        console.log(`DB updated to version: ${newVersion}`);
                        // nach erfolgreichem Update
                        resolve(newVersion);
                    });
                }
                else {
                    console.log(`DB version already greater than or equal to: ${newVersion}`);
                    // nach bereits erfolgtem Update
                    resolve(newVersion);
                }
            });
        });
    }
    clearDB() {
        return new Promise((resolve, reject) => {
            this.dmProm.then(db => {
                db.changeVersion(db.version, '0', tx => {
                    tx.executeSql('DROP TABLE user');
                }, err => {
                    console.warn('DB clear failed');
                    reject(err);
                }, () => {
                    console.log('DB cleared');
                    resolve();
                });
            });
        });
    }
    /**
     * Erezeugt eine neue SQLTransaction und fuehrt das SQL-Statement mit
     * den angegebenen Bind-Werten aus. Bentzt intern die Methode bulkExecute.
     * Dadurch entsteht zwar minimaler Overhead, es vereinheitlicht aber
     * die Implementierung.
     *
     * @param sqlStatement SQL-Statement das ausgefuehrt werden soll
     * @param bindValues Die Bind-Werte fuer das SQL-Statement
     */
    execute(sqlStatement, bindValues) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.bulkExecute([{ sqlStatement, bindValues }]).then(([resultSet]) => resultSet);
        });
    }
    /**
     * Nimmt ein Array von PreparedStatement Objekten entgegen. Diese bestehen
     * aus einem SQL-Statement und den dazugehoerigen Bind-Werten. Es wird eine
     * neue SQLTransaction geoffnet und in dieser werden alle Statements aufeinmal
     * ausgefuehrt.
     *
     * @param statements Array von PreparedStatement's
     */
    bulkExecute(statements) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.transaction().then(transaction => {
                const promises = statements.map(stmt => this.execSql(transaction, stmt.sqlStatement, stmt.bindValues));
                return Promise.all(promises);
            });
        });
    }
    /**
     * Oeffnet eine ReadOnly SQLTransaction und fuehrt das SQL-Statement mit
     * den angegebenen Bind-Werten aus. Das Ergebnis wird in Form einer
     * SQLResultSetRowList zurueckgegeben.
     *
     * @param sqlStatement SQL-Statement das ausgefuehrt werden soll
     * @param bindValues Die Bind-Werte fuer das SQL-Statement
     */
    select(sqlStatement, bindValues) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.readTransaction().then(tx => this.execSql(tx, sqlStatement, bindValues).then(rs => rs.rows));
        });
    }
    /**
     * Erzeugt eine neue SQLTransaction. Gibt eine Promise zurueck welches
     * mit dem SQLTransaction Objekt resolved oder mit einem SQLError rejected.
     */
    transaction() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.dmProm.then(db => {
                    db.transaction(tx => resolve(tx), error => (reject(error), this.errorHandler(null, error)));
                });
            });
        });
    }
    /**
     * Erzeugt eine neue ReadOnly SQLTransaction. Gibt eine Promise zurueck welches
     * mit dem SQLTransaction Objekt resolved oder mit einem SQLError rejected.
     */
    readTransaction() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.dmProm.then(db => {
                    db.readTransaction(tx => resolve(tx), error => (reject(error), this.errorHandler(null, error)));
                });
            });
        });
    }
    /**
     * Wrappt die SQLTransaction#executeSql Funktion, welche Callback's verwendet,
     * in ein Promise. Zusaetzlich wird noch ein standard Errorhandler aufgerufen.
     *
     * @param transaction Die SQL-Transaction
     * @param sqlStatement Das SQL-Statement das ausgefuehrt werden soll
     * @param bindValues Die Bind-Werte fuer das SQL-Statement
     */
    execSql(transaction, sqlStatement, bindValues) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                transaction.executeSql(sqlStatement, bindValues, (_, result) => resolve(result), (tx, error) => (reject(error), this.errorHandler(tx, error)));
            });
        });
    }
    /**
     * Standard Errorhandler. Bringt aktuell noch keine Funktionalitaet mit sich.
     *
     * @param tx Die SQLTransaction die fehlgeschlagen ist
     * @param error Der aufgetretene Error in form einer SQLError
     */
    errorHandler(tx, error) {
        // Error's werden schon vom ErrorService geloggt
        // console.log(error);
        // https://www.w3.org/TR/webdatabase/
        // If the error callback returns false, then move on to the next statement, if any, or onto the next overall step otherwise.
        // Otherwise, the error callback did not return false, or there was no error callback. Jump to the last step in the overall steps.
        return false;
    }
}
LocalDatabaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalDatabaseService_Factory() { return new LocalDatabaseService(i0.ɵɵinject(i1.WINDOW_REF)); }, token: LocalDatabaseService, providedIn: "root" });
