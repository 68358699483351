import {
  style,
  query,
  animate,
  AnimationStyleMetadata,
  AnimationQueryMetadata,
  AnimationGroupMetadata,
  AnimationTriggerMetadata,
  trigger,
  transition
} from '@angular/animations';

export const ROUTE_TRANSITON = {
  FN: 'cubic-bezier(.35,0,.25,1)',
  DELAY: 50, // Wir warten 50ms vor Beginn der Transition um kurz Zeit zum rendern zu geben
  DURATION: 700
};

const transitionProp = ROUTE_TRANSITON.DURATION + 'ms ' + ROUTE_TRANSITON.DELAY + 'ms ' + ROUTE_TRANSITON.FN;
const transitionPropIn = transitionProp;
// const transitionPropOut = transitionProp;
const animationTriggerStyle = { position: 'relative' };
const animationElementStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
};

// const slideInFromLeft = [
//   style(animationTriggerStyle),
//   query(':enter', style({ transform: 'translateX(-100%)' })),
//   query(':enter, :leave', style(animationElementStyle)),
//   group([
//     query(':leave', animate(transitionPropOut, style({ transform: 'translateX(100%)' }))),
//     query(':enter', animate(transitionPropIn, style({ transform: 'translateX(0)' }))),
//   ])
// ];

// const slideInFromRight = [
//   style(animationTriggerStyle),
//   query(':enter', style({ transform: 'translateX(100%)' })),
//   query(':enter, :leave', style(animationElementStyle)),
//   group([
//     query(':leave', animate(transitionPropOut, style({ transform: 'translateX(-100%)' }))),
//     query(':enter', animate(transitionPropIn, style({ transform: 'translateX(0)' }))),
//   ])
// ];

// Wir verwenden translate3d statt translateX fuer bessere Performance
export const CURTAIN_FROM_LEFT = [
  style(animationTriggerStyle),
  query(':enter', style({ transform: 'translate3d(-100%, 0, 0)', zIndex: 50 }), { optional: true }),
  query(':enter, :leave', style(animationElementStyle)),
  query(':enter', animate(transitionPropIn, style({ transform: 'translate3d(0, 0, 0)' })), { optional: true })
];

export const CURTAIN_FROM_RIGHT = [
  style(animationTriggerStyle),
  query(':enter', style({ transform: 'translate3d(100%, 0, 0)', zIndex: 50 }), { optional: true }),
  query(':enter, :leave', style(animationElementStyle)),
  query(':enter', animate(transitionPropIn, style({ transform: 'translate3d(0, 0, 0)' })), { optional: true })
];

export function createPageAnimation(
  parentToChild: (AnimationStyleMetadata | AnimationQueryMetadata | AnimationGroupMetadata)[],
  childToParent: (AnimationStyleMetadata | AnimationQueryMetadata | AnimationGroupMetadata)[]
): AnimationTriggerMetadata {
  // Anstatt das zu hardcoden sollten wir dynamisch an hand vom routerOutlet
  // ermitteln ob ein parent => child oder child => parent transition stattgefunden
  // hat und das ganze dann als parameter an den animationTrigger uebergeben
  return trigger('routeAnimation', [
    transition('clientoverview => users', parentToChild),
    transition('clientoverview => usergroups', parentToChild),
    transition('clientoverview => userdetail', parentToChild),
    transition('clientoverview => usergroupdetail', parentToChild),
    transition('users => userdetail', parentToChild),
    transition('usergroups => usergroupdetail', parentToChild),

    transition('users => clientoverview', childToParent),
    transition('usergroups => clientoverview', childToParent),
    transition('userdetail => clientoverview', childToParent),
    transition('usergroupdetail => clientoverview', childToParent),
    transition('userdetail => users', childToParent),
    transition('usergroupdetail => usergroups', childToParent)
  ]);
}
