import * as tslib_1 from "tslib";
import { Selector, Action, State, Store } from '@ngxs/store';
import * as userActions from './actions/user.action';
import * as clientActions from './actions/client.action';
import * as groupActions from './actions/group.action';
import { arrayToMap } from '../../utils/array-utils';
import { patchClient, putUser, putUsers, putUsersAndDeleted } from '../../utils/state-utils';
import { DateService } from '../../service/date.service';
export const USER_STATE_NAME = 'user';
let UserState = class UserState {
    constructor(dateService, store) {
        this.dateService = dateService;
        this.store = store;
    }
    /**
     * Gibt die user Daten zurück
     */
    static selectUserState(state) {
        return (id) => {
            return state[id].users;
        };
    }
    /**
     * gibt die deletedUser Daten zurück
     */
    static selectUserStateDeleted(state) {
        return (id) => {
            return state[id].deletedUsers;
        };
    }
    /**
     * gibt einen User zurück
     */
    static selectUser(state) {
        return (userId, clientId) => {
            const userState = this.selectUserState(state)(clientId);
            return userState.data ? userState.data[userId] : null;
        };
    }
    /**
     * Setzt die ClientId
     */
    setClient(ctx, action) {
        const clientId = action.payload;
        ctx.patchState({
            [clientId]: {
                deletedUsers: {
                    data: null,
                    status: 'IDLE'
                },
                users: {
                    data: null,
                    status: 'IDLE'
                }
            }
        });
    }
    /**
     * löscht alle ClientIds
     */
    clearClients(ctx) {
        ctx.setState({});
    }
    /**
     * Setzt den user Status auf 'LOADING' und lädt die user
     */
    loadUsers(ctx, action) {
        const clientId = action.clientId;
        const oldUserData = ctx.getState()[clientId].users;
        ctx.setState(patchClient({ users: Object.assign({}, oldUserData, { status: 'LOADING' }) }, clientId));
    }
    /**
     * Setzt den user Status auf 'FAILURE'
     */
    setUsersLoadFailed(ctx, action) {
        const clientId = action.clientId;
        const oldUserData = ctx.getState()[clientId].users;
        ctx.setState(patchClient({ users: Object.assign({}, oldUserData, { status: 'FAILURE' }) }, clientId));
    }
    /**
     * Setzt die user
     */
    setUsers(ctx, action) {
        const oldUserState = ctx.getState()[action.clientId].users;
        const newUserMap = action.payload;
        const oldUserMap = oldUserState.data ? oldUserState.data : {};
        // Wir patchen die schon geladenen Benutzer nur mit den Daten vom Server,
        // damit ggf. geladen Daten nicht verloren gehen
        const userMap = newUserMap.reduce((map, u) => {
            const oldUser = oldUserMap[u.id];
            const loadedComplete = !!(u.loadedComplete || (oldUser && oldUser.loadedComplete));
            map[u.id] = Object.assign({}, oldUser, u, { loadedComplete });
            return map;
        }, {});
        ctx.setState(patchClient({ users: Object.assign({}, oldUserState, { data: userMap, status: 'SUCCESS' }) }, action.clientId));
    }
    /**
     * Setzt einen user
     *
     * Action wird auch in usergroupState genutzt
     */
    createUser(ctx, { clientId, user }) {
        ctx.setState(putUser(clientId, user));
    }
    /**
     * Löscht user und setzt diese als deletedUser
     *
     * Action wird auch in usergroupState genutzt
     */
    deleteUsers(ctx, { clientId, userIdsToDelete }) {
        // Benutzer aus der User-Map entfernen
        const users = Object.assign({}, ctx.getState()[clientId].users.data);
        const deletedUsers = {};
        const deletedDate = this.dateService.newDate().getTime();
        const groupsToUpdate = {};
        for (const id of userIdsToDelete) {
            if (users[id] && users[id].usergroupIds) {
                for (const groupId of users[id].usergroupIds) {
                    groupsToUpdate[groupId] = groupsToUpdate[groupId] ? [...groupsToUpdate[groupId], id] : [id];
                }
            }
            deletedUsers[id] = Object.assign({}, users[id], { deletedDate });
            delete users[id];
        }
        // Falls die gelöschten Benutzer schon geladen sind, die gerade gelöschten Benutzer hinzufügen
        const allDeletedUsers = Object.assign({}, ctx.getState()[clientId].deletedUsers.data, deletedUsers);
        ctx.setState(putUsersAndDeleted(clientId, users, allDeletedUsers));
        if (Object.keys(groupsToUpdate).length > 0) {
            this.store.dispatch(new groupActions.RemoveUsersFromGroup(clientId, groupsToUpdate, userIdsToDelete));
        }
    }
    /**
     * Patcht einen user
     */
    patchUser(ctx, { clientId, userId, user }) {
        const userMap = ctx.getState()[clientId].users.data;
        let userData = null;
        if (userMap) {
            userData = userMap[userId];
        }
        if (!userData) {
            throw new Error(`user ${userId} does not exist`);
        }
        ctx.setState(putUser(clientId, Object.assign({}, userData, user)));
    }
    /**
     * Patcht mehrere user
     *
     * TODO pruefen ob User vorhanden. Sonst koennen Partial<User> in den Store
     * gespeichert werden. Ggf. neue Action fuer SetUser erstellen.
     */
    patchUsers(ctx, { clientId, users }) {
        const userState = ctx.getState()[clientId].users;
        const changedUsers = {};
        for (const userId in users) {
            if (Object.prototype.hasOwnProperty.call(users, userId)) {
                const oldUser = userState.data ? userState.data[userId] : null;
                changedUsers[userId] = Object.assign({}, oldUser, users[userId]);
            }
        }
        ctx.setState(putUsers(clientId, changedUsers));
    }
    /**
     * Patcht mehrere user
     *
     * Action wird auch in usergroupState genutzt
     */
    patchUsersWithGroups(ctx, { clientId, users, usersToUpdate }) {
        const userMap = ctx.getState()[clientId].users.data;
        const changedUsers = {};
        for (const user of users) {
            changedUsers[user.id] = Object.assign({}, userMap[user.id], user);
        }
        ctx.setState(putUsers(clientId, changedUsers));
    }
    /**
     * Setzt deletedUser Status auf 'LOADING' und lädt die deletedUser
     */
    loadDeletedUsers(ctx, action) {
        const clientId = action.clientId;
        const oldUserData = ctx.getState()[clientId].deletedUsers;
        ctx.setState(patchClient({ deletedUsers: Object.assign({}, oldUserData, { status: 'LOADING' }) }, action.clientId));
    }
    /**
     * Definiert, was bei der Aktion 'SetDeletedUsers' durchgeführt werden soll.
     * @param ctx StateContext
     * @param action die durchzuführende Aktion, hier SetDeletedUsers
     */
    setDeletedUsers(ctx, action) {
        const deletedUserMap = arrayToMap(action.payload);
        const oldUserData = ctx.getState()[action.clientId].deletedUsers;
        ctx.setState(patchClient({
            deletedUsers: Object.assign({}, oldUserData, { data: deletedUserMap, status: 'SUCCESS' })
        }, action.clientId));
    }
    /**
     * Setzt deletedUser Status auf 'FAILURE'
     */
    loadDeletedUsersFailed(ctx, action) {
        const clientId = action.clientId;
        const oldDeletedUserData = ctx.getState()[clientId].deletedUsers;
        ctx.setState(patchClient({ deletedUsers: Object.assign({}, oldDeletedUserData, { status: 'FAILURE' }) }, clientId));
    }
    /**
     * patch User
     *
     * Action wird auch in usergroupState genutzt
     */
    patchGroupsWithUsers(ctx, { clientId, userGroups, usergroupsToUpdate }) {
        const userMap = Object.assign({}, ctx.getState()[clientId].users.data);
        for (const groupId in usergroupsToUpdate) {
            if (Object.prototype.hasOwnProperty.call(usergroupsToUpdate, groupId)) {
                const usergroupId = parseInt(groupId, 10);
                const groupToUpdate = usergroupsToUpdate[groupId];
                // Hinzugefügte Benutzer updaten
                if (groupToUpdate.userIdsToAdd) {
                    for (const userId of groupToUpdate.userIdsToAdd) {
                        const user = userMap[userId];
                        if (user && user.loadedComplete) {
                            const usergroupIds = user.usergroupIds ? [...user.usergroupIds, usergroupId] : [usergroupId];
                            userMap[userId] = Object.assign({}, user, { usergroupIds, lastChange: -1 });
                        }
                        else if (user) {
                            userMap[userId] = Object.assign({}, user, { lastChange: -1 });
                        }
                        else {
                            throw new Error(`user with id ${userId} does not exist`);
                        }
                    }
                }
                // Entfernte Benutzer updaten
                if (groupToUpdate.userIdsToRemove) {
                    for (const userId of groupToUpdate.userIdsToRemove) {
                        const user = userMap[userId];
                        if (user && user.usergroupIds) {
                            const usergroupIds = user.usergroupIds.filter(uId => uId !== usergroupId);
                            userMap[userId] = Object.assign({}, user, { usergroupIds, lastChange: -1 });
                        }
                        else if (user) {
                            userMap[userId] = Object.assign({}, user, { lastChange: -1 });
                        }
                        else {
                            throw new Error(`user with id ${userId} does not exist`);
                        }
                    }
                }
            }
        }
        ctx.setState(putUsers(clientId, userMap));
    }
    /**
     * patcht User
     *
     * Action wird auch in usergroupState genutzt
     */
    createGroup(ctx, { clientId, usergroup }) {
        // Benutzer aktualisieren, die der Gruppe hinzugefügt worden sind
        const userMap = Object.assign({}, ctx.getState()[clientId].users.data);
        for (const userId of usergroup.userIds) {
            const user = userMap[userId];
            if (user && user.loadedComplete) {
                const usergroupIds = user.usergroupIds ? [...user.usergroupIds, usergroup.id] : [usergroup.id];
                userMap[userId] = Object.assign({}, user, { usergroupIds, lastChange: -1 });
            }
            else if (user) {
                userMap[userId] = Object.assign({}, user, { lastChange: -1 });
            }
            else {
                throw new Error(`user with id ${userId} does not exist`);
            }
        }
        ctx.setState(putUsers(clientId, userMap));
    }
    /**
     * Löscht user und setzt diese als deletedUser
     */
    deleteGroupsWithUsers(ctx, { clientId, groupIdsToDelete, deletedUserIds }) {
        const userMap = Object.assign({}, ctx.getState()[clientId].users.data);
        const deletedUsers = Object.assign({}, ctx.getState()[clientId].deletedUsers.data);
        const groupsToUpdate = {};
        // Gelöschte Benutzer updaten
        deletedUserIds
            .map((id) => userMap[id])
            // nur user loeschen die exitieren
            .filter((user) => !!user)
            .forEach((user) => {
            if (user.usergroupIds) {
                for (const groupId of user.usergroupIds) {
                    groupsToUpdate[groupId] = groupsToUpdate[groupId] ? [...groupsToUpdate[groupId], user.id] : [user.id];
                }
            }
            deletedUsers[user.id] = Object.assign({}, user);
            delete userMap[user.id];
        });
        ctx.setState(putUsersAndDeleted(clientId, userMap, deletedUsers));
        if (Object.keys(groupsToUpdate).length > 0) {
            this.store.dispatch(new groupActions.RemoveUsersFromGroup(clientId, groupsToUpdate, deletedUserIds));
        }
    }
    /**
     * Entfernt Gruppen aus Nutzern. Wird beim Löschen von Gruppen genutzt
     *
     * Action wird auch in userState genutzt
     */
    removeGroupsFromUsers(ctx, { clientId, userIds, groupIdsToRemove }) {
        const userMap = Object.assign({}, ctx.getState()[clientId].users.data);
        const groupIdsToRemoveSet = new Set(groupIdsToRemove);
        /**
         * Bei den Benutzer die usergroupIds aktualisieren
         * und lastChange auf -1 setzten, damit er beim
         * nächsten Abgleich neu vom Server geladen wird
         */
        for (const userId of userIds) {
            if (userMap[userId]) {
                const user = userMap[userId];
                if (user.usergroupIds) {
                    const usergroupIds = user.usergroupIds.filter(gId => !groupIdsToRemoveSet.has(gId));
                    userMap[userId] = Object.assign({}, user, { usergroupIds, lastChange: -1 });
                }
                else {
                    userMap[userId] = Object.assign({}, user, { lastChange: -1 });
                }
            }
        }
        ctx.setState(putUsers(clientId, userMap));
    }
};
tslib_1.__decorate([
    Action(clientActions.SetClient),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, clientActions.SetClient]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "setClient", null);
tslib_1.__decorate([
    Action(clientActions.ClearClients),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "clearClients", null);
tslib_1.__decorate([
    Action(userActions.LoadUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.LoadUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "loadUsers", null);
tslib_1.__decorate([
    Action(userActions.SetUsersLoadFailed),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.SetUsersLoadFailed]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "setUsersLoadFailed", null);
tslib_1.__decorate([
    Action(userActions.SetUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.SetUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "setUsers", null);
tslib_1.__decorate([
    Action(userActions.CreateUser),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.CreateUser]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "createUser", null);
tslib_1.__decorate([
    Action(userActions.DeleteUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.DeleteUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "deleteUsers", null);
tslib_1.__decorate([
    Action(userActions.PatchUser),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.PatchUser]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "patchUser", null);
tslib_1.__decorate([
    Action(userActions.PatchUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.PatchUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "patchUsers", null);
tslib_1.__decorate([
    Action(userActions.PatchUsersWithGroups),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.PatchUsersWithGroups]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "patchUsersWithGroups", null);
tslib_1.__decorate([
    Action(userActions.LoadDeletedUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.LoadDeletedUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "loadDeletedUsers", null);
tslib_1.__decorate([
    Action(userActions.SetDeletedUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.SetDeletedUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "setDeletedUsers", null);
tslib_1.__decorate([
    Action(userActions.SetDeleteUsersLoadFailed),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.SetDeleteUsersLoadFailed]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "loadDeletedUsersFailed", null);
tslib_1.__decorate([
    Action(groupActions.PatchGroupsWithUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.PatchGroupsWithUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "patchGroupsWithUsers", null);
tslib_1.__decorate([
    Action(groupActions.CreateGroup),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.CreateGroup]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "createGroup", null);
tslib_1.__decorate([
    Action(groupActions.DeleteGroupsWithUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.DeleteGroupsWithUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "deleteGroupsWithUsers", null);
tslib_1.__decorate([
    Action(userActions.RemoveGroupsFromUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.RemoveGroupsFromUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UserState.prototype, "removeGroupsFromUsers", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Function)
], UserState, "selectUserState", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Function)
], UserState, "selectUserStateDeleted", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Function)
], UserState, "selectUser", null);
UserState = tslib_1.__decorate([
    State({
        name: USER_STATE_NAME
    }),
    tslib_1.__metadata("design:paramtypes", [DateService, Store])
], UserState);
export { UserState };
