import { Inject, Injectable } from '@angular/core';
import { WINDOW_REF } from '../utils/injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(@Inject(WINDOW_REF) private window: Window) {}

  /**
   * Check browser compatibility for permissions.
   */
  private supportsPermission(): boolean {
    return !!(this.window.navigator as any).permissions;
  }

  /**
   * Query permission.
   */
  async queryPermission(name: string): Promise<boolean> {
    if (!this.supportsPermission()) {
      return true;
    }

    const result = await (this.window.navigator as any).permissions.query({ name });

    return result.state === 'granted' || result.state === 'prompt';
  }
}
