import * as i0 from "@angular/core";
/**
 * Bietet Funktionen zur Generierung der Cocuun-Id
 */
export class CocuunIdService {
    constructor() {
        this.BLOCK_SIZE = 3;
        this.PZKEY = '21';
    }
    /**
     * Die SSO-ID des übergebenen Users als formatierte
     * Couun-ID zurückliefern ("xxx xxx")
     *
     * @param srcId Die SsoId des Benutzers
     * @param blockCount die mindest Anzahl an Bloecken
     */
    formatSsoID(srcId, blockCount) {
        if (srcId < 0) {
            return '';
        }
        let id = '';
        let rest = null;
        const blockDiv = 1000;
        let i = 0;
        do {
            i++;
            rest = srcId % blockDiv;
            id = this.padWithZero(rest, this.BLOCK_SIZE) + ' ' + id;
            srcId -= rest;
            srcId /= blockDiv;
        } while (i < blockCount || srcId > 0);
        return id.trim();
    }
    /**
     * Berechnung der Prüfziffer zur Cocuun-ID anhand der SSO-ID des Benutzers.
     */
    calcCheckDigit(ssoid) {
        if (ssoid < 0) {
            return '';
        }
        const formatted = this.formatSsoID(ssoid, 3).replace(new RegExp(' ', 'g'), '');
        const checkSum = parseInt(this.PZKEY + formatted, 10) % 99;
        return this.padWithZero(checkSum, this.BLOCK_SIZE);
    }
    padWithZero(n, digits) {
        let str = Math.floor(Math.abs(isNaN(n) ? 0 : n)).toString();
        while (str.length < digits) {
            str = '0' + str;
        }
        return str;
    }
}
CocuunIdService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CocuunIdService_Factory() { return new CocuunIdService(); }, token: CocuunIdService, providedIn: "root" });
