/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "../../dialog/dlg-header.component.ngfactory";
import * as i9 from "../../dialog/dlg-header.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "@angular/common";
import * as i12 from "./confirm-dial.component";
var styles_ConfirmDialComponent = [];
var RenderType_ConfirmDialComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmDialComponent, data: {} });
export { RenderType_ConfirmDialComponent as RenderType_ConfirmDialComponent };
function View_ConfirmDialComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["cdkFocusInitial", ""], ["mat-button", ""], ["testid", "btnCancelConDlg"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).dialogRef.close(i0.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(2, 606208, null, 0, i5.MatDialogClose, [[2, i5.MatDialogRef], i0.ElementRef, i5.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(3, 0, [" ", " "]))], function (_ck, _v) { var currVal_4 = false; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i0.ɵnov(_v, 2).ariaLabel || null); var currVal_3 = i0.ɵnov(_v, 2).type; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.data.cancelBtnLbl; _ck(_v, 3, 0, currVal_5); }); }
export function View_ConfirmDialComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["fxLayout", "column"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i6.DefaultLayoutDirective, [i0.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "ca-dlg-header", [], null, null, null, i8.View_DlgHeaderComponent_0, i8.RenderType_DlgHeaderComponent)), i0.ɵdid(3, 49152, null, 0, i9.DlgHeaderComponent, [i5.MatDialog, i10.TranslateService], { headerTitle: [0, "headerTitle"], hideInfoButton: [1, "hideInfoButton"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "mg-4-top mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i5.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 8, "div", [["class", "mat-dialog-actions"], ["fxLayoutAlign", "end center"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(9, 671744, null, 0, i6.DefaultLayoutAlignDirective, [i0.ElementRef, i7.StyleUtils, [2, i6.LayoutAlignStyleBuilder], i7.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i0.ɵdid(10, 16384, null, 0, i5.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 3, "button", [["mat-button", ""], ["testid", "btnConfirmConDlg"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).dialogRef.close(i0.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(12, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(13, 606208, null, 0, i5.MatDialogClose, [[2, i5.MatDialogRef], i0.ElementRef, i5.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(14, 0, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialComponent_1)), i0.ɵdid(16, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.title; var currVal_2 = true; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = "end center"; _ck(_v, 9, 0, currVal_4); var currVal_9 = true; _ck(_v, 13, 0, currVal_9); var currVal_11 = _co.data.cancelBtnLbl; _ck(_v, 16, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.data.body; _ck(_v, 7, 0, currVal_3); var currVal_5 = (i0.ɵnov(_v, 12).disabled || null); var currVal_6 = (i0.ɵnov(_v, 12)._animationMode === "NoopAnimations"); var currVal_7 = (i0.ɵnov(_v, 13).ariaLabel || null); var currVal_8 = i0.ɵnov(_v, 13).type; _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = _co.data.confirmBtnLbl; _ck(_v, 14, 0, currVal_10); }); }
export function View_ConfirmDialComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ca-confirm-dial", [], null, null, null, View_ConfirmDialComponent_0, RenderType_ConfirmDialComponent)), i0.ɵdid(1, 49152, null, 0, i12.ConfirmDialComponent, [i5.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmDialComponentNgFactory = i0.ɵccf("ca-confirm-dial", i12.ConfirmDialComponent, View_ConfirmDialComponent_Host_0, {}, {}, []);
export { ConfirmDialComponentNgFactory as ConfirmDialComponentNgFactory };
