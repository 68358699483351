import { ErrorHandler, Injector } from '@angular/core';
import { SessionService } from './session.service';
import { HttpService } from './http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { EnvironmentService } from './environment.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./http.service";
import * as i3 from "./session.service";
import * as i4 from "./environment.service";
export class ErrorService {
    constructor(injector, httpClient, httpService, sessionService, evnService) {
        this.injector = injector;
        this.httpClient = httpClient;
        this.httpService = httpService;
        this.sessionService = sessionService;
        this.evnService = evnService;
    }
    handleError(e) {
        // Log complete object which may be wrapped
        console.error('%O', e);
        // Unwrap error if it's an unhandled promise rejection
        if (e.promise && e.rejection) {
            // Promise rejection wrapped by zone.js
            e = e.rejection;
        }
        // Log unwrapped error object
        console.error(e);
        // Folgende Checks machen eigentlich keinen Sinn, aber je nach Fehlersituation (z.B. bei Initialisierung einer Component)
        // schlägt hier die Constructor - Injection fehl
        if (null == this.httpService) {
            this.httpService = this.injector.get(HttpService);
        }
        if (null == this.sessionService) {
            this.sessionService = this.injector.get(SessionService);
        }
        const session = this.sessionService.get();
        if (null == session) {
            return;
        }
        let report = '\n######### Beginn Fehlerbericht #########\n';
        report += 'Email: ' + session.email + '\n';
        report += 'Route: ' + this.injector.get(Router).url + '\n';
        report += 'Version: ' + this.httpService.clientVersion + '\n';
        report += 'Cordova: ' + this.evnService.isCordova + '\n';
        report += 'Electron: ' + this.evnService.isElectron + '\n';
        report += 'Timestamp: ' + new Date() + '\n';
        report += 'UserAgent: ' + navigator.userAgent + '\n';
        report += 'Exception Msg: ' + e.message + '\n';
        report += 'Exception Stack: ' + e.stack + '\n';
        if (e.inner) {
            report += 'InnerName: ' + e.inner.name + '\n';
            report += 'InnerMessage: ' + e.inner.message + '\n';
            report += 'InnerStack: ' + e.inner.stack + '\n';
        }
        report += '######### Ende Fehlerbericht #########\n';
        const headers = new HttpHeaders(Object.assign({ version: this.httpService.clientVersion.toString() }, environment.additionalHeaders));
        const url = this.httpService.resolveSSOUrl('rest/v1/reporting/error');
        this.httpClient.post(url, report, { headers }).subscribe(() => {
            console.log('Fehlerbericht gesendet');
        }, () => {
            console.error('Fehler beim Senden des Fehlerberichts', report);
        });
    }
}
ErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpService), i0.ɵɵinject(i3.SessionService), i0.ɵɵinject(i4.EnvironmentService)); }, token: ErrorService, providedIn: "root" });
