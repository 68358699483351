import { InjectionToken } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { RAWLANG } from 'src/assets/i18n/lang';
import { environment } from '../environments/environment';
import { LanguageService } from './core/service/language.service';
import { LocalDatabaseService } from './core/service/local-database.service';
import { PromptUpdateService } from './core/service/prompt-update.service';
import { RequestBundler } from './core/shared/RequestBundler';
import { InMemoryUsercacheService } from './module/user/service/in-memory-usercache.service';
import { PersistentUsercacheService } from './module/user/service/persistent-usercache.service';
import { EnvironmentService } from './core/service/environment.service';
import { AndroidPermissionService } from './core/service/plugins/permission-android.service';
import { PermissionServiceStub } from './core/service/plugins/permission-stub.service';
const DevTools = environment.production ? [] : [NgxsReduxDevtoolsPluginModule.forRoot()];
export const TRACK_BY_ID = new InjectionToken('trackBy.id');
export const TRACK_BY_ID_FN = (_, item) => item && item.id;
export const PERMISSION = new InjectionToken('permission');
const ɵ0 = (window) => {
    try {
        if (environment.persistCaches && window.openDatabase) {
            return new PersistentUsercacheService(new LocalDatabaseService(window));
        }
        return new InMemoryUsercacheService();
    }
    catch (e) {
        return new InMemoryUsercacheService();
    }
}, ɵ1 = (envService, window) => {
    if (environment.isAndroid) {
        return new AndroidPermissionService(envService, window);
    }
    return new PermissionServiceStub();
}, ɵ2 = (langService) => () => langService.init(), ɵ3 = window, ɵ4 = RAWLANG, ɵ5 = (fn) => new RequestBundler(fn), ɵ6 = Object.assign({}, new MatDialogConfig(), { autoFocus: false });
export class AppModule {
    /**
     * Eine Injection könnte hier auch reichen, wenn der initialize code in den Konstruktor wandern würde.
     * Aber zur verdeutlichung wird hier die initialize Funktion aufgerufen.
     */
    constructor(updateService) {
        this.updateService = updateService;
        this.updateService.initialize();
    }
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
