import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
/** UserCache-Implementierung ohne Persistenz */
export class InMemoryUsercacheService {
    constructor() {
        this.cache = {};
    }
    toCacheKey(clientId, userId) {
        return clientId + '-' + userId;
    }
    cacheKeyOfUser(user) {
        return this.toCacheKey(user.clientId, user.id);
    }
    findByKeys(clientId, ids) {
        return of(ids.map(id => this.cache[this.toCacheKey(clientId, id)]).filter(entry => !!entry));
    }
    findUnchangedByKeys(clientId, keys) {
        return of(keys
            .map(key => {
            const found = this.cache[this.toCacheKey(clientId, key.id)];
            if (null != found && key.lastChange === found.lastChange) {
                return found;
            }
            return null;
        })
            .filter(entry => !!entry));
    }
    putUser(user) {
        return this.putUsers([user]).pipe(map(([u]) => u));
    }
    putUsers(users) {
        return of(users).pipe(tap(u => u.forEach(user => (this.cache[this.cacheKeyOfUser(user)] = user))));
    }
    patchUser(clientId, user) {
        return this.patchUsers(clientId, [user]).pipe(map(([u]) => u));
    }
    patchUsers(clientId, users) {
        return of(users).pipe(map(u => {
            return u.map(user => {
                const patchedUser = Object.assign({}, this.cache[this.cacheKeyOfUser(user)], user);
                this.cache[this.cacheKeyOfUser(user)] = patchedUser;
                return patchedUser;
            });
        }));
    }
    deleteOther(clientId, userIds) {
        return of(userIds).pipe(map(uIds => {
            const cacheKeys = uIds.map(id => this.toCacheKey(clientId, id));
            Object.keys(this.cache).forEach(key => {
                if (!cacheKeys.find(cacheKey => key === cacheKey)) {
                    delete this.cache[key];
                }
            });
        }));
    }
    deleteUsers(clientId, userIds) {
        return of(userIds).pipe(map(uIds => {
            uIds
                .map(id => this.toCacheKey(clientId, id))
                .forEach(cacheKey => {
                if (this.cache[cacheKey]) {
                    delete this.cache[cacheKey];
                }
            });
        }));
    }
    clearAll() {
        return of({}).pipe(map(() => {
            this.cache = {};
        }));
    }
}
InMemoryUsercacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InMemoryUsercacheService_Factory() { return new InMemoryUsercacheService(); }, token: InMemoryUsercacheService, providedIn: "root" });
