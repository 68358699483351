import { enableProdMode } from '@angular/core';
import 'hammerjs';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
const onDeviceReady = () => {
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .catch(err => console.error(err));
};
const ɵ0 = onDeviceReady;
if (environment.production) {
    enableProdMode();
}
if (environment.isCordova) {
    document.addEventListener('deviceready', onDeviceReady, false);
}
else {
    onDeviceReady();
}
export { ɵ0 };
