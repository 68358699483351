<div
  [ngClass]="hover ? 'hover' : ''"
  fxLayout="row"
  *ngIf="(customerEmail$ | async) && customerName$ | async as name"
  [matMenuTriggerFor]="menu"
  testid="itmUserProfile"
>
  <div
    fxFlex="100"
    fxLayout="row"
    fxLayoutAlign="start center"
    (mouseover)="hover = true"
    (mouseleave)="hover = false"
    class="pad-3 ellipsis"
  >
    <div class="mg-3-right" testid="imgUserAvatar">
      <ng-container *ngIf="!hover && (ssoCustomer$ | async).avatar as avatar; else showIcon">
        <img fxFlex class="member-img" [src]="avatar" />
      </ng-container>
      <ng-template #showIcon>
        <div [ngClass]="hover ? 'logout-icon' : 'placeholder-icon'" fxLayoutAlign="center center">
          <mat-icon [svgIcon]="hover ? 'mdi:power_settings_new' : 'mdi:person'"></mat-icon>
        </div>
      </ng-template>
    </div>
    <div fxLayout="column" style="overflow: hidden">
      <span class="ellipsis" testid="lblUserName">{{ name }}</span>
      <span class="subtext" testid="lblUserMail">{{ customerEmail$ | async }}</span>
      <span class="subtext ellipsis" testid="lblUserRight">({{ customerRights$ | async | translate }})</span>
    </div>
  </div>

  <mat-menu id="profile-menu" #menu="matMenu">
    <button mat-menu-item testid="btnLogout" (click)="logout()">
      <mat-icon svgIcon="mdi:power_settings_new"></mat-icon>{{ 'BACK.LOGOUT' | translate }}
    </button>
  </mat-menu>
</div>
