import { Selector, Action, StateContext, State, Store } from '@ngxs/store';
import { EntityStoreState } from './application.state';
import { UserGroup } from 'src/app/entity/usergroup';

import * as groupActions from './actions/group.action';
import * as clientActions from './actions/client.action';
import * as userActions from './actions/user.action';
import { GenericMap } from '../../utils/utility-types';
import { patchClient, putGroup, putGroups } from '../../utils/state-utils';
import { DateService } from '../../service/date.service';

export interface UsergroupStateModel {
  [cId: number]: UserGroupModel;
}

interface UserGroupModel {
  groups: EntityStoreState<UserGroup>;
}

export const USERGROUP_STATE_NAME = 'usergroup';

@State<UsergroupStateModel>({
  name: USERGROUP_STATE_NAME
})
export class UsergroupState {
  constructor(private store: Store, private dateService: DateService) {}

  /**
   * gibt die group Daten zurück
   */
  @Selector()
  static selectGroupState(state: UsergroupStateModel): (id: number) => EntityStoreState<UserGroup> {
    return (id: number) => {
      return state[id].groups;
    };
  }

  /**
   * gibt eine Gruppe zurück
   */
  @Selector()
  static selectGroup(state: UsergroupStateModel): (groupId: number, clientId: number) => UserGroup {
    return (groupId: number, clientId: number) => {
      const groupMap = this.selectGroupState(state)(clientId).data;
      return groupMap ? groupMap[groupId] : null;
    };
  }

  /**
   * Gibt durch die Id bestimmte gruppen zurück
   */
  @Selector()
  static selectGroupsById(state: UsergroupStateModel): (groupIds: number[], clientId: number) => UserGroup[] {
    return (groupIds: number[], clientId: number) => {
      const groupState = this.selectGroupState(state)(clientId);
      if (!groupState.data) {
        return null;
      }

      return groupIds
        .map((id: number): UserGroup => groupState.data[id])
        .filter((group: UserGroup): boolean => !!group);
    };
  }

  /**
   * Setzt die ClientId
   */
  @Action(clientActions.SetClient)
  setClient(ctx: StateContext<UsergroupStateModel>, action: clientActions.SetClient): void {
    const clientId = action.payload;
    ctx.patchState({
      [clientId]: {
        groups: {
          data: null,
          status: 'IDLE'
        }
      }
    });
  }

  /**
   * löscht alle ClientIds
   */
  @Action(clientActions.ClearClients)
  clearClients(ctx: StateContext<UsergroupStateModel>): void {
    ctx.setState({});
  }

  /**
   * Setzt den group Status auf 'LOADING' und lädt die groups
   */
  @Action(groupActions.LoadGroups)
  loadGroups(ctx: StateContext<UsergroupStateModel>, action: groupActions.LoadGroups): void {
    const clientId = action.clientId;
    const oldGroupState = ctx.getState()[clientId].groups;
    ctx.setState(
      patchClient<UserGroupModel, UsergroupStateModel>({ groups: { ...oldGroupState, status: 'LOADING' } }, clientId)
    );
  }

  /**
   * Setzt die groups
   */
  @Action(groupActions.SetGroups)
  setGroups(ctx: StateContext<UsergroupStateModel>, action: groupActions.SetGroups): void {
    const oldGroupState = ctx.getState()[action.clientId].groups;
    const newGroupMap = action.payload;
    const oldGroupMap = oldGroupState.data;

    // Wir patchen die schon geladenen Gruppen nur mit den Daten vom Server,
    // damit ggf. geladen Daten wie userIds etc. nicht verloren gehen
    const groupMap = newGroupMap.reduce((map, g) => {
      const newGroup = { ...(oldGroupMap && oldGroupMap[g.id]), ...g };
      map[g.id] = newGroup;
      return map;
    }, {});

    ctx.setState(
      patchClient<UserGroupModel, UsergroupStateModel>(
        { groups: { ...oldGroupState, data: groupMap, status: 'SUCCESS' } },
        action.clientId
      )
    );
  }

  /**
   * Setzt den group Status auf 'FAILURE'
   */
  @Action(groupActions.SetGroupsLoadFailed)
  setGroupsLoadFailed(ctx: StateContext<UsergroupStateModel>, action: groupActions.SetGroupsLoadFailed): void {
    const clientId = action.clientId;
    const oldGroupData = ctx.getState()[clientId].groups;
    ctx.setState(
      patchClient<UserGroupModel, UsergroupStateModel>({ groups: { ...oldGroupData, status: 'FAILURE' } }, clientId)
    );
  }

  /**
   * Upsert waere ggf. ein bessere Name da hier nicht nur ein Patch gemacht wird
   * sondern auch ein insert wenn die Gruppe noch nicht da ist. Put ist eher unpassend
   * da wenn die Gruppe schon da ist, diese aktualisiert wird, aber nicht ersetzt.
   */
  @Action(groupActions.PatchGroup)
  patchGroup(ctx: StateContext<UsergroupStateModel>, action: groupActions.PatchGroup): void {
    const oldGroupState = ctx.getState()[action.clientId].groups;
    const oldGroup = oldGroupState.data && oldGroupState.data[action.groupId];
    ctx.setState(putGroup(action.clientId, { ...oldGroup, ...action.payload }));
  }

  /**
   * patch mehrere Gruppen
   *
   * Action wird auch in userState genutzt
   */
  @Action(groupActions.PatchGroupsWithUsers)
  patchGroupsWithUsers(
    ctx: StateContext<UsergroupStateModel>,
    { clientId, userGroups, usergroupsToUpdate }: groupActions.PatchGroupsWithUsers
  ): void {
    const oldGroupMap = ctx.getState()[clientId].groups.data;
    const changedGroups: GenericMap<UserGroup> = {};

    // Gruppen aktualisieren
    for (const group of userGroups) {
      const oldGroup = oldGroupMap[group.id];
      changedGroups[group.id] = { ...oldGroup, ...group };
    }

    ctx.setState(putGroups(clientId, changedGroups));
  }

  /**
   * patcht mehrere Gruppen
   */
  @Action(groupActions.PatchGroups)
  patchGroups(ctx: StateContext<UsergroupStateModel>, { clientId, payload }: groupActions.PatchGroups): void {
    const groups: GenericMap<UserGroup> = { ...ctx.getState()[clientId].groups.data };
    const changedGroups: GenericMap<UserGroup> = {};
    for (const groupId in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, groupId)) {
        const oldGroup = groups[groupId];
        changedGroups[groupId] = { ...oldGroup, ...payload[groupId] };
      }
    }
    ctx.setState(putGroups(clientId, changedGroups));
  }

  /**
   * Setzt nach dem erstellen eine Gruppe
   *
   * Action wird auch in userState genutzt
   */
  @Action(groupActions.CreateGroup)
  createGroup(ctx: StateContext<UsergroupStateModel>, { clientId, usergroup }: groupActions.CreateGroup): void {
    ctx.setState(putGroup(clientId, usergroup));
  }

  /**
   * Löscht Gruppen und entfernt Ids der zu löschenden User aus anderen Gruppen
   * Deaktivierte Gruppen werden vom Backend zurückgesendet und hier neu gesetzt
   *
   * Action wird auch in userState genutzt
   */
  @Action(groupActions.DeleteGroupsWithUsers)
  deleteGroupsWithUsers(
    ctx: StateContext<UsergroupStateModel>,
    { clientId, groupIdsToDelete, deletedUserIds }: groupActions.DeleteGroupsWithUsers
  ): void {
    const groups: GenericMap<UserGroup> = { ...ctx.getState()[clientId].groups.data };
    let userIdsToUpdate: number[] = [];

    // Gruppen werden gelöscht
    groupIdsToDelete.forEach(groupId => {
      if (groups[groupId] && groups[groupId].userIds) {
        userIdsToUpdate = userIdsToUpdate.concat(groups[groupId].userIds);
      }
      delete groups[groupId];
    });

    ctx.setState(
      patchClient<UserGroupModel, UsergroupStateModel>({ groups: { data: groups, status: 'SUCCESS' } }, clientId)
    );

    if (userIdsToUpdate.length > 0) {
      // Duplikate entfernen, sowie Benutzer, die sowieso gelöscht werden
      const userIdsToUpdateSet: Set<number> = new Set(userIdsToUpdate);
      deletedUserIds.forEach(id => userIdsToUpdateSet.delete(id));
      userIdsToUpdate = Array.from(userIdsToUpdateSet);
      this.store.dispatch(new userActions.RemoveGroupsFromUsers(clientId, userIdsToUpdate, groupIdsToDelete));
    }
  }

  /**
   * Ordnet einen neuen Benutzer den Gruppen zu
   *
   * Action wird auch in userState genutzt
   */
  @Action(userActions.CreateUser)
  createUser(
    ctx: StateContext<UsergroupStateModel>,
    { clientId, user, customerInfoMap }: userActions.CreateUser
  ): void {
    if (!Array.isArray(user.usergroupIds)) {
      return;
    }

    const groupMap: GenericMap<UserGroup> = { ...ctx.getState()[clientId].groups.data };

    const groups: GenericMap<UserGroup> = user.usergroupIds
      .map((id: number): UserGroup => groupMap[id])
      .filter((group: UserGroup): boolean => !!group)
      .map(
        (group: UserGroup): UserGroup => {
          const lastChange: number = this.dateService.newDate().getTime();
          if (group.userIds) {
            const userIds = [...group.userIds, user.id];
            return {
              ...group,
              userIds,
              memberCount: userIds.length,
              lastChange,
              customerInfoMap: {
                [user.id]: customerInfoMap[group.id]
              }
            };
          }

          return { ...group, memberCount: group.memberCount + 1, lastChange };
        }
      )
      .reduce((acc: GenericMap<UserGroup>, cur: UserGroup): GenericMap<UserGroup> => {
        acc[cur.id] = cur;
        return acc;
      }, groupMap);

    ctx.setState(putGroups(clientId, groups));
  }

  /**
   * patcht Groups
   *
   * Action wird auch in userState genutzt
   */
  @Action(userActions.PatchUsersWithGroups)
  patchUsersWithGroups(
    ctx: StateContext<UsergroupStateModel>,
    { clientId, users, usersToUpdate, customerInfoMap }: userActions.PatchUsersWithGroups
  ): void {
    const groupMap: GenericMap<UserGroup> = { ...ctx.getState()[clientId].groups.data };

    for (const userIdStr in usersToUpdate) {
      if (usersToUpdate.hasOwnProperty(userIdStr)) {
        const userId = parseInt(userIdStr, 10);

        // Benutzer zu neuen Gruppen hinzufügen
        const addGroupIds = usersToUpdate[userIdStr].addGroupIds;
        if (addGroupIds && addGroupIds.length > 0) {
          for (const groupId of addGroupIds) {
            if (groupMap[groupId]) {
              let group = { ...groupMap[groupId] };
              const customerInfoMapCopy = { ...group.customerInfoMap };
              const lastChange: number = this.dateService.newDate().getTime();
              if (group.userIds) {
                const userIds = [...group.userIds, userId];
                group = { ...group, userIds, memberCount: userIds.length, lastChange };
              } else {
                group = { ...group, memberCount: group.memberCount + 1, lastChange };
              }
              if (group && group.customerInfoMap) {
                customerInfoMapCopy[userId] = customerInfoMap[groupId];
              }
              groupMap[groupId] = { ...group, customerInfoMap: customerInfoMapCopy };
            }
          }
        }

        // Benutzer aus Gruppen entfernen
        const removeGroupIds = usersToUpdate[userIdStr].removeGroupIds;
        if (removeGroupIds && removeGroupIds.length > 0) {
          for (const groupId of removeGroupIds) {
            if (groupMap[groupId]) {
              let group = { ...groupMap[groupId] };
              const customerInfoMapCopy = { ...group.customerInfoMap };
              const lastChange: number = this.dateService.newDate().getTime();
              if (group.userIds) {
                const userIds = group.userIds.filter(uId => uId !== userId);
                group = { ...group, userIds, memberCount: userIds.length, lastChange };
              } else {
                group = { ...group, memberCount: group.memberCount - 1, lastChange };
              }
              if (group.customerInfoMap && group.customerInfoMap[userId]) {
                delete customerInfoMapCopy[userId];
              }
              groupMap[groupId] = { ...group, customerInfoMap: customerInfoMapCopy };
            }
          }
        }
      }
    }

    ctx.setState(putGroups(clientId, groupMap));
  }

  /**
   * Entfernt Nutzer aus Gruppen. Wird beim Löschen von Benutzern genutzt
   *
   * Action wird auch in userState genutzt
   */
  @Action(groupActions.RemoveUsersFromGroup)
  removeUsersFromGroup(
    ctx: StateContext<UsergroupStateModel>,
    { clientId, groupsToUpdate, userIdsToRemove }: groupActions.RemoveUsersFromGroup
  ): void {
    // Bei den Gruppen die Mitgliederliste aktualisieren.
    const groupMap = { ...ctx.getState()[clientId].groups.data };
    const userIdsToRemoveSet: Set<number> = new Set(userIdsToRemove);
    for (const groupId of Object.keys(groupsToUpdate)) {
      if (groupMap[groupId]) {
        const group = { ...groupMap[groupId] };
        const customerInfoMapCopy = { ...group.customerInfoMap };
        const lastChange: number = this.dateService.newDate().getTime();
        // Wenn Gruppendetaildaten da sind, werden die userids aktualisiert
        if (group.userIds) {
          group.userIds = group.userIds.filter(uId => !userIdsToRemoveSet.has(uId));
          for (const userId of userIdsToRemove) {
            if (group.customerInfoMap && group.customerInfoMap[userId]) {
              delete customerInfoMapCopy[userId];
            }
          }
        }
        // aktualisiere memberCount
        groupMap[groupId] = {
          ...group,
          memberCount: group.memberCount - groupsToUpdate[groupId].length,
          lastChange,
          customerInfoMap: customerInfoMapCopy
        };
      }
    }
    ctx.setState(putGroups(clientId, groupMap));
  }
}
