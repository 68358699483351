import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { AlertDialComponent } from 'src/app/common-ui/modal/alert-dial/alert-dial.component';
import { AppStorageService } from '../statemanagement/app-storage.service';
import { HttpService } from './http.service';
import { LockService } from './lock.service';
import { SessionService } from './session.service';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "./session.service";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "../statemanagement/app-storage.service";
import * as i7 from "./lock.service";
export class AuthService {
    constructor(httpService, sessionService, dialog, translate, router, appStorage, lockService) {
        this.httpService = httpService;
        this.sessionService = sessionService;
        this.dialog = dialog;
        this.translate = translate;
        this.router = router;
        this.appStorage = appStorage;
        this.lockService = lockService;
    }
    /**
     * Fuehrt einen Login durch. Dazu wird zuerst am SSO ein Token abgerufen und
     * die Logindaten geprueft. Danach werden mit dem Token die Identities vom SSO
     * abgerufen. Fuer jede Identity werden dann die Benutzerdaten am Mandantensystem
     * abgerufen.
     *
     * @param email die Email des Benutzers
     * @param password das Password des Benutzers
     * @param persist gibt an ob man der Nutzer eingeloggt beliebn will
     * @returns Liste aller Benutzer mit Administrationsrechten
     */
    login(email, password, persist) {
        return this.httpService.login(email, password).pipe(tap((loginResp) => this.sessionService.initSession(loginResp, email, password, persist)), map((loginResp) => loginResp.identities), 
        // check if identites are available
        map((identities) => {
            if (!identities.length) {
                throw new Error('no identities received');
            }
            return identities;
        }), 
        // create list of requests for customers
        map((identities) => identities.map((identity) => this.httpService.getCore(identity, 'rest/v1/users/self').pipe(
        // needed to sort by client
        map((customer) => {
            identity.client.customer = customer;
            return identity;
        })))), 
        // wait for all requests
        flatMap((identities$) => forkJoin(identities$)), 
        // filter admin & userManager & groupManager customers
        map((identities) => identities.filter((identity) => identity.client.customer.admin ||
            identity.client.customer.userManager ||
            identity.client.customer.groupManager)), map((identities) => {
            if (!identities.length) {
                // Zeigt einen Dialog, wenn das Konto keine Administratoren-Rechte besitzt
                this.dialog.open(AlertDialComponent, {
                    data: {
                        title: this.translate.instant('ERR.LOGIN_DENIED'),
                        body: this.translate.instant('ERR.NO_ADMIN_RIGHTS'),
                        btnLbl: this.translate.instant('DIALOG.OK')
                    },
                    autoFocus: false
                });
                throw new Error('no identities with admin rights found');
            }
            // set identites in session
            this.sessionService.setIdentities(identities);
            return identities;
        }), 
        // sort and map to customers
        map((identities) => identities
            .sort((a, b) => a.client.name.localeCompare(b.client.name))
            .map((identity) => identity.client.customer)));
    }
    /**
     * Loggt den User aus, in dem erst die Session zurückgesetzt wird
     * und auf die Startseite navigiert wird.
     * Danach werden alle aktiven subscribtions in den Storage services gecleart.
     */
    logout() {
        this.lockService.deactivateTouchIDVerification().then(_ => {
            this.httpService.logout();
            this.sessionService.clear();
            this.router.navigate(['']).then(() => {
                this.appStorage.clearClients();
            });
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpService), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.MatDialog), i0.ɵɵinject(i4.TranslateService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.AppStorageService), i0.ɵɵinject(i7.LockService)); }, token: AuthService, providedIn: "root" });
