import * as tslib_1 from "tslib";
import { defer, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LocalDatabaseService } from 'src/app/core/service/local-database.service';
import { chunk, arrayToMap } from 'src/app/core/utils/array-utils';
import * as i0 from "@angular/core";
import * as i1 from "../../../core/service/local-database.service";
/** UserCache-Implementierung mit Ablage in der SQLite/Websql-Variante */
export class PersistentUsercacheService {
    /**
     * Konstruktor zum Erstellen eines PersistentUsercacheService.
     * @param dbService LocalDatabaseService
     */
    constructor(dbService) {
        this.dbService = dbService;
        this.BLOCK_SIZE = 512; // klappt bis 998 dann crash (getestet chrome 76)
        this.dbSchema = new Map([
            ['id', { type: 'integer' }],
            ['clientId', { type: 'integer' }],
            ['ssoid', { type: 'integer' }],
            ['cocuunId', { type: '' }],
            ['firstname', { type: '' }],
            ['lastname', { type: '' }],
            ['email', { type: '' }],
            ['additionalInformation', { type: '' }],
            ['joindate', { type: 'integer' }],
            ['deletedDate', { type: 'integer' }],
            ['lastChange', { type: 'integer' }],
            ['lastEdit', { type: 'integer' }],
            ['freeInvite', { type: 'integer' }],
            ['admin', { type: 'integer' }],
            ['herausgeber', { type: 'integer' }],
            ['intern', { type: 'integer' }],
            ['inviteToken', { type: 'integer' }],
            ['invited', { type: 'integer' }],
            ['provisional', { type: 'integer' }],
            ['publicVisible', { type: 'integer' }],
            ['groupChatCreator', { type: 'integer' }],
            ['groupManager', { type: 'integer' }],
            ['userManager', { type: 'integer' }],
            ['creatorId', { type: 'integer' }],
            ['ssoFirstname', { type: '' }],
            ['ssoLastname', { type: '' }],
            ['ssoEmail', { type: '' }],
            ['avatar', { type: '' }],
            // eigentlich ueberfluessig da nur user im detail view in die
            // in den cache geschrieben werden und diese haben dann eine Avatar property
            ['hasAvatar', { type: 'integer' }],
            ['usergroupIds', { type: '' }],
            // immer true weil user im minView nicht in den cache geschrieben werden
            ['loadedComplete', { type: 'integer' }]
        ]);
        let sqlStatement = '';
        for (const property of this.dbSchema.entries()) {
            sqlStatement += property[0] + ' ' + property[1].type + ',';
        }
        // Tabelle aufbauen, falls noch nicht vorhanden
        this.dbInit = this.dbService
            .execute('create table if not exists user (' + sqlStatement + 'PRIMARY KEY(clientId, id) )')
            .then(_ => this.upgradeDB());
    }
    /**
     * Update-Methode für Datenmodellanpassungen
     */
    upgradeDB() {
        // 1.0 ist bereits der aktuelle Stand.
        this.dbService
            .upgradeDBVersion('2.0', 'ALTER TABLE user ADD COLUMN version integer')
            .then(_ => this.dbSchema.set('version', { type: 'integer' }))
            .then(_ => this.dbService.upgradeDBVersion('3.0', 'ALTER TABLE user ADD COLUMN lastEditedBy'))
            .then(_ => this.dbSchema.set('lastEditedBy', { type: '' }))
            .then(_ => console.log('DB up-to-date'));
    }
    /**
     * Einen Block an Benutzers zu einem Array von Userkeys laden.
     *
     * @param ids Array von UserIds zur Identitizierung der zu ladenden Usern
     */
    findByKeys(clientId, ids) {
        // Execute IN Queries in Chunks to prevent "SQLERROR too many SQL variables"
        const prom = this.dbInit.then(() => Promise.all(
        // Divide ids Array into chunks of BLOCK_SIZE
        chunk(ids, this.BLOCK_SIZE).map(idChunk => {
            // Execute SQL Statement once for every idChunk
            const mask = Array(idChunk.length)
                .fill('?')
                .join();
            const queryStr = 'SELECT * FROM user WHERE clientId = ? AND id IN (' + mask + ')';
            return this.dbService.select(queryStr, [clientId].concat(idChunk));
        })));
        // convert promise to observable and concat resultSets
        return from(prom).pipe(map(resultSets => {
            const users = [];
            for (const rs of resultSets) {
                for (let i = 0; i < rs.length; i++) {
                    const user = this.fromSQLiteTypes(rs.item(i));
                    users.push(user);
                }
            }
            return users;
        }));
    }
    /**
     * Einen Block an Benutzers zu einem Array von Userkeys laden. Es werden nur die
     * Nutzer zurueckgegeben dessen lastChange Datum gleich geblieben ist. Der Rest hat
     * sich geaendert und wird nicht zurueckgegeben, damit er frisch vom Server gezogen wird.
     *
     * @param keys Array von UserKeys zur Identitizierung der zu ladenden Usern
     */
    findUnchangedByKeys(clientId, keys) {
        const ids = keys.map(key => key.id);
        return this.findByKeys(clientId, ids).pipe(map(users => {
            const userMap = {};
            users.forEach(u => (userMap[u.id] = u));
            return keys.filter(k => userMap[k.id] && k.lastChange === userMap[k.id].lastChange).map(k => userMap[k.id]);
        }));
    }
    /**
     * Wrapper fuer putUsers um einen einzelnen User in den Cache zu
     * speichern. So muss sich nicht der Caller immer darum kuemmern den
     * User in ein Array zu wrappen und wieder auszulesen.
     *
     * @param user Der in den Cache gespeichert werden soll
     */
    putUser(user) {
        return this.putUsers([user]).pipe(map(([u]) => u));
    }
    /**
     * Gibt eine Observable zurueck welches bei einer Subscription
     * die uebergebenen User in den Browsercache schreibt. Das Schreiben
     * der User findet in einer Transation statt.
     *
     * @param users Array mit User die in den Cache gespeichert werden sollen
     */
    putUsers(users) {
        return defer(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.dbInit
                .then(() => {
                const statements = users.map(user => {
                    const props = this.checkProperties(Object.keys(user));
                    const cols = props.join(',');
                    const mask = Array(props.length)
                        .fill('?')
                        .join();
                    const bindValues = props.map(prop => this.toSQLiteType(user[prop]));
                    const sqlStatement = 'REPLACE INTO user (' + cols + ') VALUES (' + mask + ')';
                    return { sqlStatement, bindValues };
                });
                return this.dbService.bulkExecute(statements);
            })
                .then(_ => {
                return users;
            });
        }));
    }
    /**
     * Filtert properties aus, die nicht benötigt werden
     *
     * @param properties enthält die properties eines Benutzers
     */
    checkProperties(properties) {
        return properties.filter(p => this.dbSchema.has(p));
    }
    /**
     * Führt patchUsers mit nur einem User aus
     *
     * @param user Der in den Cache gespeichert werden soll
     */
    patchUser(clientId, user) {
        return this.patchUsers(clientId, [user]).pipe(map(([u]) => u));
    }
    /**
     * Gibt eine Observable zurueck welches bei einer Subscription
     * die uebergebenen mit denen im Browsercache merged.
     *
     * @param users Array mit User die in den Cache gepatched werden sollen
     */
    patchUsers(clientId, users) {
        return this.findByKeys(clientId, users.map(user => user.id)).pipe(switchMap(cacheUsers => {
            const cacheUsersMap = arrayToMap(cacheUsers);
            return this.putUsers(users.map(user => (Object.assign({}, cacheUsersMap[user.id], user))));
        }));
    }
    /**
     * Löscht alle anderen Nutzer aus dem Cache
     *
     * @param clientId Id des Clients, indem die User sind
     * @param userIds Ids der User, die nicht gelöscht werden sollen
     */
    deleteOther(clientId, userIds) {
        return defer(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.dbInit.then(() => {
                const mask = Array(userIds.length)
                    .fill('?')
                    .join();
                this.dbService.execute('DELETE FROM user WHERE clientId = ? AND id NOT IN (' + mask + ')', [clientId].concat(userIds));
            });
        }));
    }
    /**
     * Löscht alle Nutzer die im Array userIds enthalten sind aus der 'user'-Tabelle.
     *
     * @param clientId Id des Clients, indem die User sind
     * @param userIds Ids der User, die nicht gelöscht werden sollen
     */
    deleteUsers(clientId, userIds) {
        return defer(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.dbInit.then(() => {
                chunk(userIds, this.BLOCK_SIZE).forEach(idChunk => {
                    // Execute SQL Statement once for every idChunk
                    const mask = Array(idChunk.length)
                        .fill('?')
                        .join();
                    this.dbService.execute('DELETE FROM user WHERE clientId = ? AND id IN (' + mask + ')', [clientId].concat(idChunk));
                });
            });
        }));
    }
    clearAll() {
        return defer(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.dbService.clearDB();
        }));
    }
    /**
     * SQLite unterstützt z.B. kein bool, daher zu einem integer umformen.
     */
    toSQLiteType(input) {
        if (typeof input === 'boolean') {
            return input ? 1 : 0;
        }
        return input;
    }
    /**
     * Boolean werden als Integer in der SQLite DB abgelegt. Daher muessen die
     * Integer beim auslesen wieder zu einem Boolean gecastet werden.
     */
    fromSQLiteTypes(input) {
        input.invited = !!input.invited;
        input.admin = !!input.admin;
        input.freeInvite = !!input.freeInvite;
        input.herausgeber = !!input.herausgeber;
        input.intern = !!input.intern;
        input.invited = !!input.invited;
        input.provisional = !!input.provisional;
        input.publicVisible = !!input.publicVisible;
        input.groupChatCreator = !!input.groupChatCreator;
        input.hasAvatar = !!input.hasAvatar;
        input.usergroupIds = input.usergroupIds ? input.usergroupIds.split(',').map(Number) : [];
        input.loadedComplete = !!input.loadedComplete;
        input.groupManager = !!input.groupManager;
        input.userManager = !!input.userManager;
        return input;
    }
}
PersistentUsercacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PersistentUsercacheService_Factory() { return new PersistentUsercacheService(i0.ɵɵinject(i1.LocalDatabaseService)); }, token: PersistentUsercacheService, providedIn: "root" });
