import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FullSizeLoadingSpinnerComponent } from 'src/app/common-ui/full-size-loading-spinner/full-size-loading-spinner.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
export class LoadingCircleService {
    constructor(overlay) {
        this.overlay = overlay;
        this.overlayRef = null;
    }
    open() {
        if (!this.overlayRef) {
            const cdkConfig = {
                hasBackdrop: true,
                disposeOnNavigation: true,
                scrollStrategy: this.overlay.scrollStrategies.block(),
                positionStrategy: this.overlay
                    .position()
                    .global()
                    .centerHorizontally()
                    .centerVertically()
            };
            this.overlayRef = this.overlay.create(cdkConfig);
            const userMenuPortal = new ComponentPortal(FullSizeLoadingSpinnerComponent);
            this.overlayRef.attach(userMenuPortal);
        }
    }
    close() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }
}
LoadingCircleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingCircleService_Factory() { return new LoadingCircleService(i0.ɵɵinject(i1.Overlay)); }, token: LoadingCircleService, providedIn: "root" });
