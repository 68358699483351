<div fxLayout="column">
  <ca-dlg-header [headerTitle]="data.title" [hideInfoButton]="true"></ca-dlg-header>

  <div class="mg-4-top" mat-dialog-content testid="txtDlgContent">
    <p>
      {{ data.body }}
    </p>
    <div *ngIf="data.error">{{ 'ERROR.PRECOLON' | translate }} {{ data.error }}</div>
  </div>
</div>
