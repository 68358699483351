/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../dialog/dlg-header.component.ngfactory";
import * as i5 from "../../dialog/dlg-header.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/common";
import * as i8 from "./alert-dial.component";
var styles_AlertDialComponent = [];
var RenderType_AlertDialComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertDialComponent, data: {} });
export { RenderType_AlertDialComponent as RenderType_AlertDialComponent };
function View_AlertDialComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " ", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("ERROR.PRECOLON")); var currVal_1 = _co.data.error; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_AlertDialComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["fxLayout", "column"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i0.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "ca-dlg-header", [], null, null, null, i4.View_DlgHeaderComponent_0, i4.RenderType_DlgHeaderComponent)), i0.ɵdid(3, 49152, null, 0, i5.DlgHeaderComponent, [i6.MatDialog, i1.TranslateService], { headerTitle: [0, "headerTitle"], hideInfoButton: [1, "hideInfoButton"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "mg-4-top mat-dialog-content"], ["mat-dialog-content", ""], ["testid", "txtDlgContent"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i6.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertDialComponent_1)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.title; var currVal_2 = true; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = _co.data.error; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.data.body; _ck(_v, 7, 0, currVal_3); }); }
export function View_AlertDialComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ca-alert-dial", [], null, null, null, View_AlertDialComponent_0, RenderType_AlertDialComponent)), i0.ɵdid(1, 49152, null, 0, i8.AlertDialComponent, [i6.MAT_DIALOG_DATA], null, null)], null, null); }
var AlertDialComponentNgFactory = i0.ɵccf("ca-alert-dial", i8.AlertDialComponent, View_AlertDialComponent_Host_0, {}, {}, []);
export { AlertDialComponentNgFactory as AlertDialComponentNgFactory };
