/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-size-loading-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./full-size-loading-spinner.component";
var styles_FullSizeLoadingSpinnerComponent = [i0.styles];
var RenderType_FullSizeLoadingSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullSizeLoadingSpinnerComponent, data: {} });
export { RenderType_FullSizeLoadingSpinnerComponent as RenderType_FullSizeLoadingSpinnerComponent };
export function View_FullSizeLoadingSpinnerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["color", "accent"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"] }, null)], function (_ck, _v) { var currVal_3 = "accent"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_FullSizeLoadingSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ca-full-size-loading-spinner", [], null, null, null, View_FullSizeLoadingSpinnerComponent_0, RenderType_FullSizeLoadingSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i7.FullSizeLoadingSpinnerComponent, [], null, null)], null, null); }
var FullSizeLoadingSpinnerComponentNgFactory = i1.ɵccf("ca-full-size-loading-spinner", i7.FullSizeLoadingSpinnerComponent, View_FullSizeLoadingSpinnerComponent_Host_0, {}, {}, []);
export { FullSizeLoadingSpinnerComponentNgFactory as FullSizeLoadingSpinnerComponentNgFactory };
