import { ChangeDetectionStrategy, Component, EventEmitter, Output, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * Komponente zur Passworteingabe
 */
@Component({
  selector: 'ca-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordComponent implements OnInit, OnDestroy {
  /** Kennzeichen, ob das Passwort in Textform angezeigt wird oder mit Punkte */
  showPw: boolean;

  /** Das Passwort */
  @Input() pwd = '';
  /** Wird ausgelöst, wenn sich der Inhalt des Passworteingabfeldes verändert wurde */
  @Output() pwdChange: EventEmitter<string> = new EventEmitter<string>();
  /** Wird ausgelöst, wenn die Eingabe mit ENTER bestätigt wurde */
  @Output() keyupEnter: EventEmitter<string> = new EventEmitter<string>();

  passwordForm: FormGroup = this.fb.group({ password: [this.pwd, [Validators.required]] });

  sub: Subscription;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.sub = this.passwordForm.controls.password.valueChanges.subscribe(value => this.pwdChange.emit(value));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
