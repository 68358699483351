import { AppStorageService } from '../statemanagement/app-storage.service';
import { Base64Service } from './base64.service';
import { EnvironmentService } from './environment.service';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./base64.service";
import * as i2 from "./local-storage.service";
import * as i3 from "../statemanagement/app-storage.service";
import * as i4 from "./environment.service";
/**
 * Handelt die Session, des angemeldeten Benutzes
 */
export class SessionService {
    constructor(base64Service, localStorage, appStorage, envService) {
        this.base64Service = base64Service;
        this.localStorage = localStorage;
        this.appStorage = appStorage;
        this.envService = envService;
        /** Feldname für die Session im Localstorage */
        this.SESSION_COOKIE_KEY = 'SESSION';
        /** Letzter Wert aus dem session$ Observable vom AppStorageService */
        this.lastSessionVal = null;
        // Laden der Session
        const session = this.restore();
        // Global verfügbar machen
        this.lastSessionVal = session;
        if (null !== session) {
            this.appStorage.setSession(session);
        }
    }
    /**
     * Setzt im Store eine Session, wenn eine im LocalStorage vorhanden war,
     * und persistiert neue/veränderte Sessions, wenn diese persistiert werden sollen
     */
    initialize() {
        this.appStorage.setSession(this.lastSessionVal);
        this.appStorage.selectSession().subscribe(session => {
            this.lastSessionVal = session;
            if (session && session.clientId && session.persist) {
                this.persist();
            }
        });
    }
    /**
     * Gibt den Letzten Wert der Session zurück
     * @deprecated nicht mehr verwenden, stattdessen @link AppStorageService#selectSession verwenden
     */
    get() {
        return this.lastSessionVal;
    }
    /**
     * Erstellt eine neue Session und schreibt diese in den Store
     *
     * @param loginResp Antwort des Login-Requests
     * @param email Email des angemeldeten Benutzers
     * @param password Passwort des Angemeldeten Benutzers
     * @param persist Gibt an, ob die Session persistiert werden soll
     */
    initSession(loginResp, email, password, persist) {
        const session = this.create();
        session.token = loginResp.token;
        session.email = email;
        session.password = password;
        session.customer = loginResp.customer;
        session.persist = persist;
        this.appStorage.setSession(session);
    }
    /**
     * Fügt die Identities des angemeldeten Benutzes zu der Session im Store hinzu
     *
     * @param identities Identities des Benutzers
     */
    setIdentities(identities) {
        const identitiesMap = {};
        const externalClientIds = identities.map((identity) => {
            identitiesMap[identity.client.externalId] = identity;
            return identity.client.externalId;
        });
        this.appStorage.patchSession({ externalClientIds, identitiesMap });
    }
    /**
     * Fügt die ID des Mandanten, für den man sich Anmeldet, zur Session hinzu
     *
     * @param clientId ID des Mandanten
     */
    activateSession(clientId) {
        this.appStorage.patchSession({ clientId });
    }
    /**
     * Setzt ein neues Token bei der Session
     *
     * @param token Token, der neu gesetzt werden soll
     */
    updateToken(token) {
        this.appStorage.patchSession({ token });
    }
    /**
     * Gibt die Identity mit der entsprechenden externalId zurück
     *
     * @param externalId ExternalId des Mandanten zu der Identity, die man haben möchte
     */
    getIdentityByExtId(externalId) {
        return this.lastSessionVal.identitiesMap[externalId];
    }
    /**
     * Löscht die Session aus dem Store und aus dem LocalStorage
     */
    clear() {
        this.appStorage.setSession(null);
        this.delete();
    }
    delete() {
        this.localStorage.remove(this.SESSION_COOKIE_KEY);
    }
    /**
     * Das Speichern der Session soll nur dann erlaubt werden, wenn es sich nicht um ein Produktivsystem handelt,
     * oder wenn es die Anwendung unter Cordova ausgeführt wird.
     */
    canPersistSession() {
        const env = this.envService.getEnvironment();
        return !env.production || env.isCordova;
    }
    /**
     * Erstellt ein leeres Session-Objekt
     */
    create() {
        return {
            token: null,
            email: null,
            password: null,
            customer: null,
            clientId: null,
            externalClientIds: [],
            identitiesMap: {},
            persist: false
        };
    }
    /**
     * Gibt zurück, ob eine Session im Localstorage vorhanden ist
     */
    hasPersistedSession() {
        if (!this.canPersistSession()) {
            throw new Error('It is not allowed to persist the current session!');
        }
        return this.localStorage.has(this.SESSION_COOKIE_KEY);
    }
    /**
     * Verschlüsselt und persistert die aktuelle Session im Localstorage
     */
    persist() {
        if (!this.canPersistSession()) {
            throw new Error('It is not allowed to persist the current session!');
        }
        if (!this.envService.isCordova) {
            const jsonStr = JSON.stringify(this.lastSessionVal);
            const base64 = this.base64Service.encode(jsonStr, false);
            this.localStorage.set(this.SESSION_COOKIE_KEY, base64);
        }
    }
    /**
     * Holt die persistierte Session aus dem Localstorage und entschlüsselt diese
     */
    restore() {
        if (this.canPersistSession() && this.hasPersistedSession() && !this.envService.isCordova) {
            const base64 = this.localStorage.get(this.SESSION_COOKIE_KEY);
            const jsonStr = this.base64Service.decode(base64, false);
            return JSON.parse(jsonStr);
        }
        return null;
    }
}
SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.Base64Service), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.AppStorageService), i0.ɵɵinject(i4.EnvironmentService)); }, token: SessionService, providedIn: "root" });
