/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-dial.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "../../dialog/dlg-header.component.ngfactory";
import * as i7 from "../../dialog/dlg-header.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./info-dial.component";
var styles_InfoDialComponent = [i0.styles];
var RenderType_InfoDialComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_InfoDialComponent, data: {} });
export { RenderType_InfoDialComponent as RenderType_InfoDialComponent };
export function View_InfoDialComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ca-dlg-header", [], null, null, null, i6.View_DlgHeaderComponent_0, i6.RenderType_DlgHeaderComponent)), i1.ɵdid(4, 49152, null, 0, i7.DlgHeaderComponent, [i8.MatDialog, i9.TranslateService], { headerTitle: [0, "headerTitle"], hideInfoButton: [1, "hideInfoButton"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i8.MatDialogContent, [], null, null), i1.ɵppd(7, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.title; var currVal_2 = true; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.data.content)); _ck(_v, 5, 0, currVal_3); }); }
export function View_InfoDialComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ca-info-dial", [], null, null, null, View_InfoDialComponent_0, RenderType_InfoDialComponent)), i1.ɵdid(1, 49152, null, 0, i10.InfoDialComponent, [i8.MAT_DIALOG_DATA], null, null)], null, null); }
var InfoDialComponentNgFactory = i1.ɵccf("ca-info-dial", i10.InfoDialComponent, View_InfoDialComponent_Host_0, {}, {}, []);
export { InfoDialComponentNgFactory as InfoDialComponentNgFactory };
