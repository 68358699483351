import { Inject, Injectable } from '@angular/core';
import { WINDOW_REF } from '../utils/injection-tokens';
import { environment } from 'src/environments/environment';
import { CAWindow } from '../utils/window-utils';

/**
 * Service für den Zugriff auf den LocalStorage.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalDatabaseService {
  private dmProm: Promise<Database>;

  /**
   * @ignore
   */
  constructor(@Inject(WINDOW_REF) private window: CAWindow) {
    this.dmProm = new Promise((resolve, reject) => {
      if (environment.isCordova) {
        document.addEventListener('deviceready', () => this.initDB(resolve, reject), false);
      } else {
        this.initDB(resolve, reject);
      }
    });
  }

  /**
   * @ignore
   */
  private initDB(resolve: (value: Database) => void, reject: (reason?: any) => void): void {
    try {
      let db: Database;
      if (this.window.sqlitePlugin) {
        db = this.window.sqlitePlugin.openDatabase({ name: 'cocuun.db' });
      } else {
        db = this.window.openDatabase('cocuunDB', '', 'Cocuun Database', 2 * 1024 * 1024);
      }

      console.log(`DB current version: ${db.version}`);
      resolve(db);
    } catch (e) {
      reject(e);
    }
  }

  /**
   * Führt eine Aktualisierung der bestehenden Datenbank auf eine neuere Version durch.
   * https://www.w3.org/TR/webdatabase/#introduction
   *
   * @param newVersion neue Versionsnummer
   * @param statement das Statement welches für die Migration ausgeführt werden soll
   */
  upgradeDBVersion(newVersion: string, statement: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.dmProm.then(db => {
        console.log(`DB try update to version: ${newVersion}`);
        if (db.version < newVersion) {
          // Aktualisierung der DB-Version durchführen.
          db.changeVersion(
            db.version,
            newVersion,
            tx => {
              // Update der DB durchführen
              tx.executeSql(statement);
            },
            err => {
              console.warn(`DB update failed. Current DB version: ${db.version}`, err);
              reject(err);
            },
            () => {
              console.log(`DB updated to version: ${newVersion}`);
              // nach erfolgreichem Update
              resolve(newVersion);
            }
          );
        } else {
          console.log(`DB version already greater than or equal to: ${newVersion}`);
          // nach bereits erfolgtem Update
          resolve(newVersion);
        }
      });
    });
  }

  clearDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.dmProm.then(db => {
        db.changeVersion(
          db.version,
          '0',
          tx => {
            tx.executeSql('DROP TABLE user');
          },
          err => {
            console.warn('DB clear failed');
            reject(err);
          },
          () => {
            console.log('DB cleared');
            resolve();
          }
        );
      });
    });
  }

  /**
   * Erezeugt eine neue SQLTransaction und fuehrt das SQL-Statement mit
   * den angegebenen Bind-Werten aus. Bentzt intern die Methode bulkExecute.
   * Dadurch entsteht zwar minimaler Overhead, es vereinheitlicht aber
   * die Implementierung.
   *
   * @param sqlStatement SQL-Statement das ausgefuehrt werden soll
   * @param bindValues Die Bind-Werte fuer das SQL-Statement
   */
  async execute(sqlStatement: string, bindValues?: any[]): Promise<SQLResultSet> {
    return this.bulkExecute([{ sqlStatement, bindValues }]).then(([resultSet]) => resultSet);
  }

  /**
   * Nimmt ein Array von PreparedStatement Objekten entgegen. Diese bestehen
   * aus einem SQL-Statement und den dazugehoerigen Bind-Werten. Es wird eine
   * neue SQLTransaction geoffnet und in dieser werden alle Statements aufeinmal
   * ausgefuehrt.
   *
   * @param statements Array von PreparedStatement's
   */
  async bulkExecute(statements: PreparedStatement[]): Promise<SQLResultSet[]> {
    return this.transaction().then(transaction => {
      const promises = statements.map(stmt => this.execSql(transaction, stmt.sqlStatement, stmt.bindValues));
      return Promise.all(promises);
    });
  }

  /**
   * Oeffnet eine ReadOnly SQLTransaction und fuehrt das SQL-Statement mit
   * den angegebenen Bind-Werten aus. Das Ergebnis wird in Form einer
   * SQLResultSetRowList zurueckgegeben.
   *
   * @param sqlStatement SQL-Statement das ausgefuehrt werden soll
   * @param bindValues Die Bind-Werte fuer das SQL-Statement
   */
  async select(sqlStatement: string, bindValues?: any[]): Promise<SQLResultSetRowList> {
    return this.readTransaction().then(tx => this.execSql(tx, sqlStatement, bindValues).then(rs => rs.rows));
  }

  /**
   * Erzeugt eine neue SQLTransaction. Gibt eine Promise zurueck welches
   * mit dem SQLTransaction Objekt resolved oder mit einem SQLError rejected.
   */
  private async transaction(): Promise<SQLTransaction> {
    return new Promise((resolve, reject) => {
      this.dmProm.then(db => {
        db.transaction(tx => resolve(tx), error => (reject(error), this.errorHandler(null, error)));
      });
    });
  }

  /**
   * Erzeugt eine neue ReadOnly SQLTransaction. Gibt eine Promise zurueck welches
   * mit dem SQLTransaction Objekt resolved oder mit einem SQLError rejected.
   */
  private async readTransaction(): Promise<SQLTransaction> {
    return new Promise((resolve, reject) => {
      this.dmProm.then(db => {
        db.readTransaction(tx => resolve(tx), error => (reject(error), this.errorHandler(null, error)));
      });
    });
  }

  /**
   * Wrappt die SQLTransaction#executeSql Funktion, welche Callback's verwendet,
   * in ein Promise. Zusaetzlich wird noch ein standard Errorhandler aufgerufen.
   *
   * @param transaction Die SQL-Transaction
   * @param sqlStatement Das SQL-Statement das ausgefuehrt werden soll
   * @param bindValues Die Bind-Werte fuer das SQL-Statement
   */
  private async execSql(transaction: SQLTransaction, sqlStatement: string, bindValues: any[]): Promise<SQLResultSet> {
    return new Promise<SQLResultSet>((resolve, reject) => {
      transaction.executeSql(
        sqlStatement,
        bindValues,
        (_, result) => resolve(result),
        (tx, error) => (reject(error), this.errorHandler(tx, error))
      );
    });
  }

  /**
   * Standard Errorhandler. Bringt aktuell noch keine Funktionalitaet mit sich.
   *
   * @param tx Die SQLTransaction die fehlgeschlagen ist
   * @param error Der aufgetretene Error in form einer SQLError
   */
  private errorHandler(tx: SQLTransaction, error: SQLError): boolean {
    // Error's werden schon vom ErrorService geloggt
    // console.log(error);

    // https://www.w3.org/TR/webdatabase/
    // If the error callback returns false, then move on to the next statement, if any, or onto the next overall step otherwise.
    // Otherwise, the error callback did not return false, or there was no error callback. Jump to the last step in the overall steps.
    return false;
  }
}

interface PreparedStatement {
  sqlStatement: string;
  bindValues: any[];
}
