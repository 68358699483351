import * as tslib_1 from "tslib";
import { Selector, Action, State } from '@ngxs/store';
import * as clientActions from './actions/client.action';
import * as clientInfoActions from './actions/clientInformation.action';
import { UserState } from './user.state';
import { UsergroupState } from './usergroup.state';
export const CLIENT_STATE_NAME = 'client';
let ClientState = class ClientState {
    constructor() { }
    /**
     * gibt den bvwClient zurück
     */
    static selectBvwClientState(state) {
        return state.bvwClient;
    }
    /**
     * gibt die Client Statistiken zurück
     */
    static selectClientInfo(state) {
        return state.clientInfo;
    }
    /**
     * entfernt die ClientStatistiken und den BvwClient
     */
    clearClients(ctx) {
        ctx.setState({
            bvwClient: {
                data: null,
                status: 'IDLE'
            },
            clientInfo: null
        });
    }
    /**
     * Setzt den bvwClient Status auf 'LOADING' und lädt den bvwClient
     */
    loadBvwClient(ctx, action) {
        const oldState = ctx.getState().bvwClient;
        ctx.patchState({ bvwClient: Object.assign({}, oldState, { status: 'LOADING' }) });
    }
    /**
     * Setzt den bvwClient Status auf 'FAILURE'
     */
    setBvwClientLoadFailed(ctx, action) {
        const oldState = ctx.getState().bvwClient;
        ctx.patchState({ bvwClient: Object.assign({}, oldState, { status: 'FAILURE' }) });
    }
    /**
     * Setzt den bvwClient
     */
    setBvwClient(ctx, action) {
        ctx.patchState({ bvwClient: { data: action.payload, status: 'SUCCESS' } });
    }
    /**
     * Setzt die Client Statistiken
     */
    setClientStatistics(ctx, action) {
        ctx.patchState({ clientInfo: action.payload });
    }
};
tslib_1.__decorate([
    Action(clientActions.ClearClients),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], ClientState.prototype, "clearClients", null);
tslib_1.__decorate([
    Action(clientActions.LoadBvwClient),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, clientActions.LoadBvwClient]),
    tslib_1.__metadata("design:returntype", void 0)
], ClientState.prototype, "loadBvwClient", null);
tslib_1.__decorate([
    Action(clientActions.SetBvwClientLoadFailed),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, clientActions.SetBvwClientLoadFailed]),
    tslib_1.__metadata("design:returntype", void 0)
], ClientState.prototype, "setBvwClientLoadFailed", null);
tslib_1.__decorate([
    Action(clientActions.SetBvwClient),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, clientActions.SetBvwClient]),
    tslib_1.__metadata("design:returntype", void 0)
], ClientState.prototype, "setBvwClient", null);
tslib_1.__decorate([
    Action(clientInfoActions.SetClientStatistics),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, clientInfoActions.SetClientStatistics]),
    tslib_1.__metadata("design:returntype", void 0)
], ClientState.prototype, "setClientStatistics", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Object)
], ClientState, "selectBvwClientState", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Object)
], ClientState, "selectClientInfo", null);
ClientState = tslib_1.__decorate([
    State({
        name: CLIENT_STATE_NAME,
        defaults: {
            bvwClient: {
                data: null,
                status: 'IDLE'
            },
            clientInfo: null
        },
        children: [UsergroupState, UserState]
    }),
    tslib_1.__metadata("design:paramtypes", [])
], ClientState);
export { ClientState };
