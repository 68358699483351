import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { DialogComponent } from './dialog.component';
import { DlgStepperButtonsComponent } from './dlg-stepper-button.component';
import { DlgHeaderComponent } from './dlg-header.component';

@NgModule({
  declarations: [DialogComponent, DlgStepperButtonsComponent, DlgHeaderComponent],
  imports: [CommonModule, MaterialModule, TranslateModule.forChild()],
  exports: [DialogComponent, DlgStepperButtonsComponent, DlgHeaderComponent]
})
export class DialogModule {}
