import { Router } from '@angular/router';
import { AppStorageService } from '../statemanagement/app-storage.service';
import { map, tap, filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../statemanagement/app-storage.service";
/**
 * Verhindert das Einsteigen auf einer anderen Seite als der Login-Seite, wenn keine Session vorhanden ist
 */
export class SessionCheckGuard {
    constructor(router, appStorage) {
        this.router = router;
        this.appStorage = appStorage;
    }
    /**
     * Navigiert auf die Loginseite, wenn keine Session da ist
     */
    canActivate() {
        return this.appStorage.selectSession().pipe(
        // Undefined ist der Anfangszustand
        filter(session => session !== undefined), 
        // Nur wenn eine Session da ist koennen wir die Anwendung betreten
        map(session => !!session), tap(canActivate => {
            // Wenn keine Session aktiv ist
            if (!canActivate) {
                // Redirecten wir auf die LoginSeite
                this.router.navigate(['/']);
            }
        }));
    }
}
SessionCheckGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionCheckGuard_Factory() { return new SessionCheckGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppStorageService)); }, token: SessionCheckGuard, providedIn: "root" });
