import { IEntity } from 'src/app/entity/entity';
import { GenericMap } from './utility-types';

/**
 * Transformiert eine GenericMap zu einem Array
 * @param genMap Map die transformiert werden soll
 * @param predicate Optional: Prädikat-Funktion
 */
export function mapToArray<T>(genMap: GenericMap<T>, predicate?: (val: T) => boolean): T[] {
  if (genMap === null) {
    return null;
  }

  const tempArr: T[] = [];
  for (const key in genMap) {
    if (genMap.hasOwnProperty(key) && (!predicate || predicate(genMap[key]))) {
      tempArr.push(genMap[key]);
    }
  }
  return tempArr;
}

/**
 * Transformiert ein Array zu einer GenericMap
 * @param arr Array das transformiert werder soll
 */
export function arrayToMap<T extends IEntity>(arr: T[]): GenericMap<T> {
  if (arr === null) {
    return null;
  }

  const map = {};
  arr.forEach(obj => {
    map[obj.id] = obj;
  });
  return map;
}

/**
 * Divides an array into chunks of a given size.
 * @param arr Array to divide into chunks
 * @param chunkSize Size of a single chunk
 */
export function chunk<T>(arr: T[], chunkSize: number): T[][] {
  const chunks = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
}

/**
 * Vergleicht zwei Arrays miteinander. Dabei werden alle Element aus dem
 * ersten Array mit allen Elementen aus dem zweiten Array mittels ===
 * verglichen.
 *
 * @param arr1 Das erste Array
 * @param arr2 Das zweite Array
 */
export function arraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1 === arr2) {
    return true;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

/**
 * Vergleicht zwei Arrays miteinander. Dabei werden zunächst alle Elemente aus den übergebenen Arrays
 * sortiert und erst danach der Vergleich durchgeführt.
 *
 * @param arr1 Das erste Array
 * @param arr2 Das zweite Array
 */
export function sortedArraysEqual<T extends number | string>(arr1: T[], arr2: T[]): boolean {
  // Weil das Array im Strict Mode eingefroren ist, muss man vor dem Sortieren das Array mittels slice() kopiert werden.
  const arrSorted1 = arr1.slice().sort();
  const arrSorted2 = arr2.slice().sort();
  return arraysEqual<T>(arrSorted1, arrSorted2);
}

/**
 * The flatMap function first maps each element of the given array using
 * a mapping function, then flattens the result into a new array.
 *
 * @param arr Source Array
 * @param mapper Mapping Function
 */
export function flatMap<T, R>(arr: T[], mapper: (e: T) => R[]): R[] {
  return arr.reduce((acc, val) => acc.concat(mapper(val)), []);
}

/**
 * Ermöglicht es mehrere Elemente einem bestehenden Array hinzuzufügen.
 *
 * @param arr1 das bestehende Array
 * @param arr2 das Array mit den neu hinzuzufügenden Elementen
 */
export function appendItemsToArray<T>(arr1: T[], arr2: T[]): void {
  arr2.forEach(val => arr1.push(val));
}

/**
 * Errechnet die Differenz zwischen 2 Arrays, d.h.
 * Gibt die Elemente zurück die in Array 1 sind aber nicht in Array 2
 * und Duplikate werden Ignoriert. Beide Arrays müssen den gleichen Typ haben.
 *
 * @param arr1 Das erste Array
 * @param arr2 Das zweite Array
 */
export function getDifference<T>(arr1: T[], arr2: T[]): T[] {
  const set: Set<T> = new Set(arr2);
  const diff: Set<T> = new Set();
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < arr1.length; i++) {
    if (!set.has(arr1[i])) {
      diff.add(arr1[i]);
    }
  }
  return Array.from(diff);
}

/**
 * Fügt ein Array aus Arrays zu einem Array zusammen und fügt HeaderObjekte zwischendrin ein
 *
 * @param entries Array aus Arrays, die zusammengefügt werden sollen
 * @param headers Array mit Überschriften der Header. Wenn nichts mitgegeben wird, dann werden keine Header eingefügt
 */
export function flatHeaderMap<T>(entries: T[][], headers?: string[]): T[] {
  let flatUsersFiltered: T[] = [];
  // Wenn keine Elemente in der Liste sind, sollen keine Header angezeigt werden
  if (!entries.find(entry => entry.length > 0)) {
    return [];
  }
  for (let i = 0; i < entries.length; i++) {
    if (headers && headers[i]) {
      const headerObj = {
        id: i,
        name: headers[i],
        amount: entries[i].length,
        isHeader: true
      };
      flatUsersFiltered.push(headerObj as any);
    }
    flatUsersFiltered = flatUsersFiltered.concat(entries[i]);
  }
  return flatUsersFiltered;
}
