import { Injectable } from '@angular/core';
import { Subject, Observable, TimeoutError, of, NEVER, merge } from 'rxjs';
import { filter, timeout, catchError, takeUntil, map, first, debounceTime } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';
import { ROUTE_TRANSITON } from '../utils/animation-utils';

/**
 * Service welcher zur Ueberwachung der Animationen in der Anwendung dient. Dies kann hilfreich sein
 * wenn man z.B. mit dem Ausfuehren von Logik warten moechte bis alle Animationen abgelaufen sind.
 * Den Ursprung hat der Service in dem Problem das die Initialisierungslogik von Komponenten erst dann
 * ausgefuehrt werden sollte wenn die Seitenanimation durch ist um diese nicht zu behindert. Der Service
 * wurde direkt noch etwas allgemeiner gestaltet und kann beliebig viele Animation-Events tracken.
 * Dazu wird beim Aufzeichnen eines Start-Events eine ID generiert die beim Aufzeichnen eines Done-Events
 * uebergeben werden muss um eine Paritaet zwischen den Events herzustellen. So koennen wir nun ueberwachen
 * ob noch Animationen ausstehen und Observables erstellen die emitten wenn alle Animationen durchgelaufen sind.
 */
@Injectable({
  providedIn: 'root'
})
export class AnimationTrackerService {
  private UUID = 1;
  private PENDING_IDS = new Set<number>();
  private TIMEOUT_DEFAULT = ROUTE_TRANSITON.DELAY + ROUTE_TRANSITON.DURATION + 150;

  private animationStart = new Subject<AnimationEvent>();
  private animationDone = new Subject<AnimationEvent>();

  readonly animationStart$: Observable<AnimationEvent>;
  readonly animationDone$: Observable<AnimationEvent>;

  readonly animationCount$: Observable<number>;

  constructor() {
    this.animationStart$ = this.animationStart.asObservable();
    this.animationDone$ = this.animationDone.asObservable();

    this.animationCount$ = merge(this.animationStart$, this.animationDone$).pipe(map(_ => this.PENDING_IDS.size));
  }

  /**
   * Uebergibt ein AnimationEvent an den AnimationTracker. Geliefert wird
   * eine einzigartige ID die benoetigt wird um dem Tracker zu informieren
   * wenn diese Animation beendet ist.
   *
   * @param event Das AnimationStart-Event
   * @returns Die ID die der Tracker der Animation zugewiesen hat
   */
  captureStartEvent(event: AnimationEvent): number {
    const uuid = ++this.UUID;
    this.PENDING_IDS.add(uuid);
    this.animationStart.next(event);
    return uuid;
  }

  /**
   * Markiert die Animation mit der uebergebenen ID als erledigt.
   * Zudem muss das entsprechende AnimationEvent uebergeben werden.
   * Es wird true geliefert wenn die Animation mit der ID entfernt
   * werden konnte und false wenn sie bereits entfernt wurde.
   *
   * @param id Die ID die dieser Service der Animation zugewiesen hat
   * @param event Das AnimationDone-Event
   * @returns true/false je nachdem ob die Animation noch vorhanden war
   */
  captureDoneEvent(id: number, event: AnimationEvent): boolean {
    const bRemoved = this.PENDING_IDS.delete(id);
    this.animationDone.next(event);
    return bRemoved;
  }

  /**
   * Erzeugt ein Observable welches emittet sobald alle Animationen die wir tracken
   * fertig sind. Zudem kann ein Notifier Observable mitgegeben werden. Wenn dieser
   * Notifier emittet wird das retunierte Observable completed. Zusaetzlich kann der
   * default Timeout ueberschrieben werden nach dem das Observable completed wird.
   * Der Timeout resultiert nicht in einem Error, sondern in einem normalen Emit.
   *
   * @param takeUntilNotifier Ein Observables dessen erster Emit dazu fuehrt das das retunierte Observable completed
   * @param timeoutMs Ein Timeout in Millisekunden der angibt wie lange wir maximal auf ein Done Event warten
   * @returns Observable welches emittet wenn alle Animation durchgelaufen sind
   */
  onFinished(
    takeUntilNotifier: Observable<any> = NEVER,
    timeoutMs: number = this.TIMEOUT_DEFAULT
  ): Observable<void | TimeoutError> {
    return this.animationCount$.pipe(
      debounceTime(200),
      filter(cnt => cnt === 0),
      map(_ => {}),
      first(),
      takeUntil(takeUntilNotifier),
      timeout(timeoutMs),
      catchError(err => of(err))
    );
  }
}
