<div fxLayout="column" fxFlexFill>
  <div id="loginPageContainer" fxFlex class="pad-4-vert">
    <mat-card id="loginFrame" fxFlex="none" fxLayout="column">
      <div id="heading" class="pad-3">
        {{ 'LOGIN.REGISTRATION' | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="none center">
        <div class="error-msg mg-3-left" fxFlex testid="lblWrongLogin">{{ loginErrMsg | async | translate }}</div>
        <ca-language [smallVariant]="true"></ca-language>
      </div>
      <form [formGroup]="loginForm" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="none center">
          <mat-icon svgIcon="mdi:mail_outline" class="prefix-icon"></mat-icon>
          <mat-form-field fxFlex>
            <input
              testid="edtEmail"
              formControlName="email"
              type="email"
              matInput
              [placeholder]="'LOGIN.EMAIL' | translate"
              autofocus
              spellcheck="false"
            />
            <mat-error>{{ getErrorMessageEmail() | translate }}</mat-error>
          </mat-form-field>
        </div>
        <ca-password (pwdChange)="pwd$.next($event)" (keyupEnter)="login$.next()"> </ca-password>
      </form>
      <!-- <div fxLayout="row" fxLayoutAlign="none center" class="pad-3-vert pad-2-left">
        <mat-checkbox [(ngModel)]="persistLogin" (keyup.enter)="persistLogin = !persistLogin">
          <span class="pad-2-left">Eingeloggt bleiben</span>
        </mat-checkbox>
      </div> -->
      <button
        testid="btnLogin"
        class="btn-row"
        mat-raised-button
        color="accent"
        [disabled]="!isFormFilled() || (loginInProg$ | async) || loginForm.invalid"
        (click)="login$.next()"
      >
        {{ 'LOGIN.LOGIN' | translate }}
      </button>
      <button id="forgotPwdButton" class="btn-row" mat-button color="primary" (click)="forgotPwd()">
        {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
      </button>
      <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <button (click)="openAbout($event)" class="btn-row btn-info" mat-button color="primary">
          {{ 'LOGIN.ABOUT' | translate }}
        </button>
        <button (click)="openTos($event)" class="btn-row btn-info" mat-button color="primary">
          {{ 'LOGIN.TERMS_OF_USE' | translate }}
        </button>
        <button (click)="openPrivacy($event)" class="btn-row btn-info" mat-button color="primary">
          {{ 'LOGIN.PRIVACY' | translate }}
        </button>
      </div>
    </mat-card>
  </div>
</div>
