import * as tslib_1 from "tslib";
import { PermissionService } from './permission.service';
import * as i0 from "@angular/core";
import * as i1 from "../utils/injection-tokens";
import * as i2 from "./permission.service";
export class ClipboardService {
    constructor(window, permissionService) {
        this.window = window;
        this.permissionService = permissionService;
    }
    copy(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const hasPermission = yield this.permissionService.queryPermission('clipboard-write');
            if (hasPermission) {
                yield this.window.navigator.clipboard.writeText(data);
            }
        });
    }
}
ClipboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClipboardService_Factory() { return new ClipboardService(i0.ɵɵinject(i1.WINDOW_REF), i0.ɵɵinject(i2.PermissionService)); }, token: ClipboardService, providedIn: "root" });
