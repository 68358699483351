import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GenericMap } from 'src/app/core/utils/utility-types';
import { Session } from '../../service/session.service';

import * as headerActions from './actions/header.action';
import * as sessionActions from './actions/session.action';
import { ClientState } from './client.state';

export type StorageStatus = 'IDLE' | 'LOADING' | 'SUCCESS' | 'FAILURE';

export interface EntityStoreState<T> {
  data: GenericMap<T>;
  status: StorageStatus;
}

export interface ApplicationStateModel {
  session?: Session;
  header: HeaderParams;
}

export interface HeaderParams {
  /** Den Zurück-Button ausblenden? */
  hideBack?: boolean;
  /** Der Text des Zurück-Buttons */
  backText?: string;
  /** Das Icon des Zurück-Buttons */
  backIcon?: string;
  /** Die Funktion, die der Zurück-Button auslösen soll */
  backFn?: () => void;
  /** Soll der Home-Button angezeigt werden? */
  showHomeBtn?: boolean;
  /** Soll der Settings-Button angezeigt werden? */
  showSettingsBtn?: boolean;
  /** Text der Überschrift? */
  headline?: string;
  /** Überschrift übersetzen? */
  translateHeadline?: boolean;
  /** Das User-Profil anzeigen? */
  showUserProfile?: boolean;
  /** Titel des Info-Dialogs */
  infoTextTitle?: string;
  /** Inhalt des Info-Dialogs */
  infoTextContent?: string;
}

export const DEFAULT_HEADER: HeaderParams = {
  hideBack: true,
  backText: null,
  backIcon: null,
  backFn: null,
  showHomeBtn: false,
  showSettingsBtn: false,
  headline: '',
  translateHeadline: false,
  showUserProfile: false
};

export const APPLICATION_STATE_NAME = 'application';

/**
 * Application State
 * Hier wird ueber die State annotation dem Store mittgeteilt wie
 * das State Slice mit dem Namen application aussehen soll. Zudem befinden
 * sich in dieser Klasse alle Selectoren & die Reducer Funktionen die mit
 * Action annotiert sind.
 */
@State<ApplicationStateModel>({
  name: APPLICATION_STATE_NAME,
  defaults: {
    session: undefined,
    header: {
      ...DEFAULT_HEADER
    }
  },
  children: [ClientState]
})
export class ApplicationState {
  constructor() {}

  /**
   * Gibt die aktuelle Session zurück
   */
  @Selector()
  static selectSession(state: ApplicationStateModel): Session {
    return state.session;
  }

  /**
   * Gibt die aktuellen HeaderParams zurück
   */
  @Selector()
  static selectHeaderParams(state: ApplicationStateModel): HeaderParams {
    return state.header;
  }

  /**
   * Setzt die aktuelle Session
   */
  @Action(sessionActions.SetSession)
  setSession(ctx: StateContext<ApplicationStateModel>, action: sessionActions.SetSession): void {
    ctx.patchState({ session: action.payload });
  }

  /**
   * patcht die aktuelle Session
   */
  @Action(sessionActions.PatchSession)
  patchSession(ctx: StateContext<ApplicationStateModel>, action: sessionActions.PatchSession): void {
    ctx.patchState({
      session: {
        ...ctx.getState().session,
        ...action.payload
      }
    });
  }

  /**
   * patcht die HeaderParams
   */
  @Action(headerActions.PatchHeaderParams)
  setHeaderParams(ctx: StateContext<ApplicationStateModel>, action: headerActions.PatchHeaderParams): void {
    ctx.patchState({
      header: {
        ...ctx.getState().header,
        ...action.payload
      }
    });
  }
}
