import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/entity/user';
import { CustomerInfo } from 'src/app/entity/usergroup';
import { HighlightSearchPipe } from '../pipes/highlight-search.pipe';
import { AppStorageService } from '../statemanagement/app-storage.service';
import { escapeHtml } from '../utils/string-utils';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private appStorage: AppStorageService,
    private translate: TranslateService,
    private hightLightSearch: HighlightSearchPipe
  ) {}

  /**
   * Ermittelt den vollständigen Namen des Benutzers.
   *
   * @param user Benutzer, dessen vollständiger Name ermittelt werden soll
   * @param reverse Gibt an, ob "{Nachname}, {Vorname}" oder "{Vorname} {Nachname}" zurückgegeben wird, für true gilt ersteres
   * @returns "{Nachname}, {Vorname}" oder "{Vorname} {Nachname}" des Benutzers
   */
  getFullName(user: User, reverse?: boolean): string {
    let fullname = user.firstname + ' ' + user.lastname;

    if (reverse) {
      fullname = user.lastname + ', ' + user.firstname;
    }
    return fullname;
  }

  getSubTitle(user: User, customerInfo: CustomerInfo): Observable<string> {
    return this.appStorage.canManageUsersOrGroups().pipe(
      map(isAdmin => {
        let subtitle = '';

        // Wenn noch keine Cocuun-ID vorhanden ist
        if (user.ssoid < 0) {
          if (user.invited) {
            // ... weil der Benutzer die Einladung noch nicht angenommen hat
            subtitle = this.translate.instant('USER_DETAIL.INVITED');
          } else {
            // ... weil der Benutzer noch nicht eingeladen wurde
            subtitle = this.translate.instant('USER_DETAIL.NOT_INVITED');
          }
        }

        if (subtitle !== '') {
          if (customerInfo && (customerInfo.infoAdmin || customerInfo.infoAll)) {
            // Wenn eingeloggter Benutzer ein Administrator ist, wird die Admin-Zusatzinfo angehängt
            if (customerInfo.infoAdmin && isAdmin) {
              subtitle += ' - ' + customerInfo.infoAdmin;
              // Wenn die Gruppen-Zusatzinfo existiert, wird die ebenfalls angehängt
              if (customerInfo.infoAll) {
                subtitle += ', ' + customerInfo.infoAll;
              }
              // Ansonsten wird nur die Gruppen-Zusatzinfo angehängt, falls eine existiert
            } else if (customerInfo.infoAll) {
              subtitle += ' - ' + customerInfo.infoAll;
              // Wenn die obigen Zusatzinfos nicht existieren, wird die Standart-Zusatzinfo angehängt
            }
          } else if (user.additionalInformation) {
            subtitle += ' - ' + user.additionalInformation;
          }
        } else {
          if (customerInfo && (customerInfo.infoAdmin || customerInfo.infoAll)) {
            if (customerInfo.infoAdmin && isAdmin) {
              subtitle = customerInfo.infoAdmin;
              if (customerInfo.infoAll) {
                subtitle += ', ' + customerInfo.infoAll;
              }
            } else if (customerInfo.infoAll) {
              subtitle = customerInfo.infoAll;
            }
          } else if (user.additionalInformation) {
            subtitle = user.additionalInformation;
          }
        }
        return subtitle;
      })
    );
  }

  getSubTitleHighlited(
    user: User,
    customerInfo: CustomerInfo,
    highlightSearch: string,
    customSubtitle?: string
  ): Observable<string> {
    return this.appStorage.canManageUsersOrGroups().pipe(
      map(isAdmin => {
        if (null != customSubtitle) {
          return customSubtitle;
        }

        let subtitle = '';

        if (user.ssoid > 0) {
          // Variante 1: Cocuun-ID, wenn Einladung angenommen
          subtitle = this.getCocuunId(user);
        } else if (user.inviteToken) {
          // Variante 2: Einladungscode, wenn Einladung noch nicht angenommen
          subtitle = this.translate.instant('EXTEND_INFO.INVITE_CODE', {
            code: user.inviteToken
          });
        } else {
          // Variante 3: nicht eingeladen
          subtitle = this.translate.instant('USER_DETAIL.NOT_INVITED');
        }

        if (customerInfo && (customerInfo.infoAdmin || customerInfo.infoAll)) {
          // Wenn eingeloggter Benutzer ein Administrator ist, wird die Admin-Zusatzinfo angehängt
          if (customerInfo.infoAdmin && isAdmin) {
            subtitle += ' - ' + escapeHtml(customerInfo.infoAdmin);
            // Wenn die Gruppen-Zusatzinfo existiert, wird die ebenfalls angehängt
            if (customerInfo.infoAll) {
              subtitle += ', ' + escapeHtml(customerInfo.infoAll);
            }
            // Ansonsten wird nur die Gruppen-Zusatzinfo angehängt, falls eine existiert
          } else if (customerInfo.infoAll) {
            subtitle += ' - ' + escapeHtml(customerInfo.infoAll);
            // Wenn die obigen Zusatzinfos nicht existieren, wird die Standart-Zusatzinfo angehängt
          }
        } else if (user.additionalInformation) {
          subtitle += ' - ' + escapeHtml(user.additionalInformation);
        }
        return this.hightLightSearch.transform(subtitle, highlightSearch);
      })
    );
  }

  /**
   * Liefert die formatierte Cocuun-ID zum Benutzer
   */
  private getCocuunId(user: User): string {
    return this.translate.instant('EXTEND_INFO.COCUUN_ID', {
      id: user.cocuunId
    });
  }
}
