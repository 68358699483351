import * as i0 from "@angular/core";
/**
 * Service zum Zugriff auf den LocalStorage.
 */
export class LocalStorageService {
    /**
     * @ignore
     */
    constructor() {
        this.nativeLS = window.localStorage;
    }
    /**
     * Liefert einen Wert zum übergebenen Schlüssel.
     * @param key Schlüssel zum Eintrag
     */
    get(key) {
        return JSON.parse(this.nativeLS.getItem(key));
    }
    /**
     * Liefert einen Wert zum übergebenen Schlüssel, oder falls kein Wert vorhanden ist,
     * den übergebenen Default-Wert.
     * @param key Schlüssel zum Eintrag
     * @param defaultVal Fallback-Wert
     */
    getOrDefault(key, defaultVal) {
        const value = this.get(key);
        return null == value ? defaultVal : value;
    }
    /**
     * Entfernt einen Eintrag zum übergebenen Schlüssel.
     * @param key Schlüssel zum Eintrag
     */
    remove(key) {
        this.nativeLS.removeItem(key);
    }
    /**
     * Hinterlegt einen Key-Value Wertpaar im lokalen Store.
     * @param key Schlüssel zum Eintrag
     * @param value Wert zum Schlüssel
     */
    set(key, value) {
        this.nativeLS.setItem(key, JSON.stringify(value));
    }
    /**
     * Prüft, ob der übergebenen Schlüssel als Schlüssel im lokalen Store vorhanden ist.
     */
    has(key) {
        return key in this.nativeLS;
    }
}
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
