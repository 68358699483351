import { Inject, Injectable } from '@angular/core';
import { WINDOW_REF } from '../../utils/injection-tokens';
import { CAWindow } from '../../utils/window-utils';
import { EnvironmentService } from '../environment.service';
import { Permission } from './permission';

/**
 * Service, welcher Berechtigungen von den Cordova Endgeräten abfragt.
 */
@Injectable({
  providedIn: 'root'
})
export class AndroidPermissionService implements Permission {
  /** Parameter für Leserechte */
  private READ_EXTERNAL_STORAGE = 'android.permission.READ_EXTERNAL_STORAGE';
  /** Fehlernachricht */
  private ERR_MSG = 'permission filesystem read denied';

  /**
   * Permissionservice-Konstruktor zur Berechtigungsanfrage am Android Cordova-Systemen.
   * @param envService EnvironmentService
   * @param window CAWindow
   */
  constructor(private envService: EnvironmentService, @Inject(WINDOW_REF) private window: CAWindow) {}

  /**
   * Prüft, ob Berechtigungen (READ_EXTERNAL_STORAGE) für den Lesezugriff auf den Speicher des Gerätes erteilt wurden
   * und fordert diese ggf. neu an.
   */
  verifyFilesystemRead(): Promise<void> {
    if (this.isActive()) {
      return this.has(this.READ_EXTERNAL_STORAGE, this.ERR_MSG);
    }
    return Promise.reject(this.ERR_MSG);

    // return this.has(this.isActive() ? this.READ_EXTERNAL_STORAGE : null);
  }

  private isActive(): boolean {
    return this.envService.isAndroid && null != this.window.plugins.Permission;
  }

  private has(permission: string, msg: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.window.plugins.Permission.has(
        permission,
        permResults => {
          permResults[permission] ? resolve() : resolve(this.request(permission, msg));
        },
        err => {
          console.error(msg, err);
          reject();
        }
      );
    });
  }

  private request(permission: string, msg: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.window.plugins.Permission.request(
        permission,
        permResults => {
          if (permResults[permission]) {
            resolve();
          } else {
            console.log('permission', permission, permResults[permission]);
            reject(new Error(msg));
          }
        },
        err => {
          console.error(msg, err);
          reject(err);
        }
      );
    });
  }
}
