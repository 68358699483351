import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

/**
 * Komponente zur Darstellung des "Zurück"-knopfes im Header
 */
@Component({
  selector: 'ca-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackButtonComponent {
  /**
   * Text, der im Button angezeigt wird. Wird im Template übersetzt.
   * Wenn nichts angegeben wird, wird stattdessen 'BACK.DEFAULT' in den Button geschrieben
   */
  @Input() text: string;
  /** Icon, dass neben dem Text. Wenn nichts angegeben ist, wird stattdessen 'chevron_left' genommen */
  @Input() icon: string;
  /** Zur Steuerung, ob die Zurück-Schaltfläche deaktiviert dargestellet werden soll. */
  @Input() disabled: boolean;
  /** Event wird Emittet, wenn auf den Knopf gedrückt wird */
  @Output() back = new EventEmitter<MouseEvent>();
}
