import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { filter, map, first, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { SetClient, ClearClients } from './state/actions/client.action';
import { PatchHeaderParams } from './state/actions/header.action';
import { PatchSession, SetSession } from './state/actions/session.action';
import { ApplicationState } from './state/application.state';
import { hasChanges } from '../utils/object-utils';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
export class AppStorageService {
    constructor(store, actions$) {
        this.store = store;
        this.actions$ = actions$;
        // TODO eigentlich koennte man sich setClient sparen
        // und diese immer onDemand anlegen
        this.actions$
            .pipe(ofActionSuccessful(SetSession, PatchSession), filter(action => !!action.payload), map(action => action.payload.clientId), filter(clientId => !!clientId))
            .subscribe(clientId => {
            this.setClient(clientId);
        });
    }
    selectSession() {
        return this.store.select(ApplicationState.selectSession);
    }
    selectHeaderParams() {
        return this.store.select(ApplicationState.selectHeaderParams);
    }
    setSession(session) {
        this.store.dispatch(new SetSession(session));
    }
    patchSession(session) {
        this.store.dispatch(new PatchSession(session));
    }
    setClient(clientId) {
        this.store.dispatch(new SetClient(clientId));
    }
    clearClients() {
        this.store.dispatch(new ClearClients());
    }
    setHeaderParams(params) {
        this.selectHeaderParams()
            .pipe(first(), filter((oldParams) => hasChanges(oldParams, params)), switchMap(() => this.store.dispatch(new PatchHeaderParams(params))))
            .subscribe();
    }
    /** Gibt an, ob der angemeldete Benutzer ein Administrator ist
     * @return Observable<boolean>
     */
    isAdmin() {
        return this.selectSession().pipe(map((session) => {
            if (!session) {
                return null;
            }
            return session.identitiesMap[session.clientId].client.customer.admin;
        }));
    }
    /** Gibt zurück, ob der angemeldete Benutzer das Recht admin oder userManager besitzt
     * @return Observable<boolean>
     */
    canManageUser() {
        return this.selectSession().pipe(map(session => {
            if (!session) {
                return null;
            }
            return (session.identitiesMap[session.clientId].client.customer.admin ||
                session.identitiesMap[session.clientId].client.customer.userManager);
        }), distinctUntilChanged());
    }
    /** Gibt an, ob der angemeldete Benutzer die Rechte admin, userManager oder freeInvite besitzt
     * @return Observable<boolean>
     */
    canManageUserOrInvite() {
        return this.selectSession().pipe(map((session) => {
            if (!session) {
                return null;
            }
            const customer = session.identitiesMap[session.clientId].client.customer;
            return customer.admin || customer.userManager || customer.freeInvite;
        }), distinctUntilChanged());
    }
    /** Gibt zurück, ob der angemeldete Benutzer das Recht admin oder groupManager besitzt
     * @return Observable<boolean>
     */
    canManageGroups() {
        return this.selectSession().pipe(map((session) => {
            if (!session) {
                return null;
            }
            const customer = session.identitiesMap[session.clientId].client.customer;
            return customer.admin || customer.groupManager;
        }), distinctUntilChanged());
    }
    /** Gibt zurück, ob der angemeldete Benutzer das Recht admin, userManager oder groupManager besitzt
     * @return Observable<boolean>
     */
    canManageUsersOrGroups() {
        return this.selectSession().pipe(map((session) => {
            if (!session) {
                return null;
            }
            const customer = session.identitiesMap[session.clientId].client.customer;
            return customer.admin || customer.userManager || customer.groupManager;
        }), distinctUntilChanged());
    }
    /**
     * Gibt die ID des angemeldeten Benutzers zurück
     * oder null, wenn keine Session existiert
     * @return Observable<number>
     */
    currentLoggedInUserId() {
        return this.selectSession().pipe(map((session) => {
            if (!session) {
                return null;
            }
            const customer = session.identitiesMap[session.clientId].client.customer;
            return customer.id;
        }), distinctUntilChanged());
    }
}
AppStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppStorageService_Factory() { return new AppStorageService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i1.Actions)); }, token: AppStorageService, providedIn: "root" });
