import * as tslib_1 from "tslib";
import { Selector, Action, State, Store } from '@ngxs/store';
import * as groupActions from './actions/group.action';
import * as clientActions from './actions/client.action';
import * as userActions from './actions/user.action';
import { patchClient, putGroup, putGroups } from '../../utils/state-utils';
import { DateService } from '../../service/date.service';
export const USERGROUP_STATE_NAME = 'usergroup';
let UsergroupState = class UsergroupState {
    constructor(store, dateService) {
        this.store = store;
        this.dateService = dateService;
    }
    /**
     * gibt die group Daten zurück
     */
    static selectGroupState(state) {
        return (id) => {
            return state[id].groups;
        };
    }
    /**
     * gibt eine Gruppe zurück
     */
    static selectGroup(state) {
        return (groupId, clientId) => {
            const groupMap = this.selectGroupState(state)(clientId).data;
            return groupMap ? groupMap[groupId] : null;
        };
    }
    /**
     * Gibt durch die Id bestimmte gruppen zurück
     */
    static selectGroupsById(state) {
        return (groupIds, clientId) => {
            const groupState = this.selectGroupState(state)(clientId);
            if (!groupState.data) {
                return null;
            }
            return groupIds
                .map((id) => groupState.data[id])
                .filter((group) => !!group);
        };
    }
    /**
     * Setzt die ClientId
     */
    setClient(ctx, action) {
        const clientId = action.payload;
        ctx.patchState({
            [clientId]: {
                groups: {
                    data: null,
                    status: 'IDLE'
                }
            }
        });
    }
    /**
     * löscht alle ClientIds
     */
    clearClients(ctx) {
        ctx.setState({});
    }
    /**
     * Setzt den group Status auf 'LOADING' und lädt die groups
     */
    loadGroups(ctx, action) {
        const clientId = action.clientId;
        const oldGroupState = ctx.getState()[clientId].groups;
        ctx.setState(patchClient({ groups: Object.assign({}, oldGroupState, { status: 'LOADING' }) }, clientId));
    }
    /**
     * Setzt die groups
     */
    setGroups(ctx, action) {
        const oldGroupState = ctx.getState()[action.clientId].groups;
        const newGroupMap = action.payload;
        const oldGroupMap = oldGroupState.data;
        // Wir patchen die schon geladenen Gruppen nur mit den Daten vom Server,
        // damit ggf. geladen Daten wie userIds etc. nicht verloren gehen
        const groupMap = newGroupMap.reduce((map, g) => {
            const newGroup = Object.assign({}, (oldGroupMap && oldGroupMap[g.id]), g);
            map[g.id] = newGroup;
            return map;
        }, {});
        ctx.setState(patchClient({ groups: Object.assign({}, oldGroupState, { data: groupMap, status: 'SUCCESS' }) }, action.clientId));
    }
    /**
     * Setzt den group Status auf 'FAILURE'
     */
    setGroupsLoadFailed(ctx, action) {
        const clientId = action.clientId;
        const oldGroupData = ctx.getState()[clientId].groups;
        ctx.setState(patchClient({ groups: Object.assign({}, oldGroupData, { status: 'FAILURE' }) }, clientId));
    }
    /**
     * Upsert waere ggf. ein bessere Name da hier nicht nur ein Patch gemacht wird
     * sondern auch ein insert wenn die Gruppe noch nicht da ist. Put ist eher unpassend
     * da wenn die Gruppe schon da ist, diese aktualisiert wird, aber nicht ersetzt.
     */
    patchGroup(ctx, action) {
        const oldGroupState = ctx.getState()[action.clientId].groups;
        const oldGroup = oldGroupState.data && oldGroupState.data[action.groupId];
        ctx.setState(putGroup(action.clientId, Object.assign({}, oldGroup, action.payload)));
    }
    /**
     * patch mehrere Gruppen
     *
     * Action wird auch in userState genutzt
     */
    patchGroupsWithUsers(ctx, { clientId, userGroups, usergroupsToUpdate }) {
        const oldGroupMap = ctx.getState()[clientId].groups.data;
        const changedGroups = {};
        // Gruppen aktualisieren
        for (const group of userGroups) {
            const oldGroup = oldGroupMap[group.id];
            changedGroups[group.id] = Object.assign({}, oldGroup, group);
        }
        ctx.setState(putGroups(clientId, changedGroups));
    }
    /**
     * patcht mehrere Gruppen
     */
    patchGroups(ctx, { clientId, payload }) {
        const groups = Object.assign({}, ctx.getState()[clientId].groups.data);
        const changedGroups = {};
        for (const groupId in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, groupId)) {
                const oldGroup = groups[groupId];
                changedGroups[groupId] = Object.assign({}, oldGroup, payload[groupId]);
            }
        }
        ctx.setState(putGroups(clientId, changedGroups));
    }
    /**
     * Setzt nach dem erstellen eine Gruppe
     *
     * Action wird auch in userState genutzt
     */
    createGroup(ctx, { clientId, usergroup }) {
        ctx.setState(putGroup(clientId, usergroup));
    }
    /**
     * Löscht Gruppen und entfernt Ids der zu löschenden User aus anderen Gruppen
     * Deaktivierte Gruppen werden vom Backend zurückgesendet und hier neu gesetzt
     *
     * Action wird auch in userState genutzt
     */
    deleteGroupsWithUsers(ctx, { clientId, groupIdsToDelete, deletedUserIds }) {
        const groups = Object.assign({}, ctx.getState()[clientId].groups.data);
        let userIdsToUpdate = [];
        // Gruppen werden gelöscht
        groupIdsToDelete.forEach(groupId => {
            if (groups[groupId] && groups[groupId].userIds) {
                userIdsToUpdate = userIdsToUpdate.concat(groups[groupId].userIds);
            }
            delete groups[groupId];
        });
        ctx.setState(patchClient({ groups: { data: groups, status: 'SUCCESS' } }, clientId));
        if (userIdsToUpdate.length > 0) {
            // Duplikate entfernen, sowie Benutzer, die sowieso gelöscht werden
            const userIdsToUpdateSet = new Set(userIdsToUpdate);
            deletedUserIds.forEach(id => userIdsToUpdateSet.delete(id));
            userIdsToUpdate = Array.from(userIdsToUpdateSet);
            this.store.dispatch(new userActions.RemoveGroupsFromUsers(clientId, userIdsToUpdate, groupIdsToDelete));
        }
    }
    /**
     * Ordnet einen neuen Benutzer den Gruppen zu
     *
     * Action wird auch in userState genutzt
     */
    createUser(ctx, { clientId, user, customerInfoMap }) {
        if (!Array.isArray(user.usergroupIds)) {
            return;
        }
        const groupMap = Object.assign({}, ctx.getState()[clientId].groups.data);
        const groups = user.usergroupIds
            .map((id) => groupMap[id])
            .filter((group) => !!group)
            .map((group) => {
            const lastChange = this.dateService.newDate().getTime();
            if (group.userIds) {
                const userIds = [...group.userIds, user.id];
                return Object.assign({}, group, { userIds, memberCount: userIds.length, lastChange, customerInfoMap: {
                        [user.id]: customerInfoMap[group.id]
                    } });
            }
            return Object.assign({}, group, { memberCount: group.memberCount + 1, lastChange });
        })
            .reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, groupMap);
        ctx.setState(putGroups(clientId, groups));
    }
    /**
     * patcht Groups
     *
     * Action wird auch in userState genutzt
     */
    patchUsersWithGroups(ctx, { clientId, users, usersToUpdate, customerInfoMap }) {
        const groupMap = Object.assign({}, ctx.getState()[clientId].groups.data);
        for (const userIdStr in usersToUpdate) {
            if (usersToUpdate.hasOwnProperty(userIdStr)) {
                const userId = parseInt(userIdStr, 10);
                // Benutzer zu neuen Gruppen hinzufügen
                const addGroupIds = usersToUpdate[userIdStr].addGroupIds;
                if (addGroupIds && addGroupIds.length > 0) {
                    for (const groupId of addGroupIds) {
                        if (groupMap[groupId]) {
                            let group = Object.assign({}, groupMap[groupId]);
                            const customerInfoMapCopy = Object.assign({}, group.customerInfoMap);
                            const lastChange = this.dateService.newDate().getTime();
                            if (group.userIds) {
                                const userIds = [...group.userIds, userId];
                                group = Object.assign({}, group, { userIds, memberCount: userIds.length, lastChange });
                            }
                            else {
                                group = Object.assign({}, group, { memberCount: group.memberCount + 1, lastChange });
                            }
                            if (group && group.customerInfoMap) {
                                customerInfoMapCopy[userId] = customerInfoMap[groupId];
                            }
                            groupMap[groupId] = Object.assign({}, group, { customerInfoMap: customerInfoMapCopy });
                        }
                    }
                }
                // Benutzer aus Gruppen entfernen
                const removeGroupIds = usersToUpdate[userIdStr].removeGroupIds;
                if (removeGroupIds && removeGroupIds.length > 0) {
                    for (const groupId of removeGroupIds) {
                        if (groupMap[groupId]) {
                            let group = Object.assign({}, groupMap[groupId]);
                            const customerInfoMapCopy = Object.assign({}, group.customerInfoMap);
                            const lastChange = this.dateService.newDate().getTime();
                            if (group.userIds) {
                                const userIds = group.userIds.filter(uId => uId !== userId);
                                group = Object.assign({}, group, { userIds, memberCount: userIds.length, lastChange });
                            }
                            else {
                                group = Object.assign({}, group, { memberCount: group.memberCount - 1, lastChange });
                            }
                            if (group.customerInfoMap && group.customerInfoMap[userId]) {
                                delete customerInfoMapCopy[userId];
                            }
                            groupMap[groupId] = Object.assign({}, group, { customerInfoMap: customerInfoMapCopy });
                        }
                    }
                }
            }
        }
        ctx.setState(putGroups(clientId, groupMap));
    }
    /**
     * Entfernt Nutzer aus Gruppen. Wird beim Löschen von Benutzern genutzt
     *
     * Action wird auch in userState genutzt
     */
    removeUsersFromGroup(ctx, { clientId, groupsToUpdate, userIdsToRemove }) {
        // Bei den Gruppen die Mitgliederliste aktualisieren.
        const groupMap = Object.assign({}, ctx.getState()[clientId].groups.data);
        const userIdsToRemoveSet = new Set(userIdsToRemove);
        for (const groupId of Object.keys(groupsToUpdate)) {
            if (groupMap[groupId]) {
                const group = Object.assign({}, groupMap[groupId]);
                const customerInfoMapCopy = Object.assign({}, group.customerInfoMap);
                const lastChange = this.dateService.newDate().getTime();
                // Wenn Gruppendetaildaten da sind, werden die userids aktualisiert
                if (group.userIds) {
                    group.userIds = group.userIds.filter(uId => !userIdsToRemoveSet.has(uId));
                    for (const userId of userIdsToRemove) {
                        if (group.customerInfoMap && group.customerInfoMap[userId]) {
                            delete customerInfoMapCopy[userId];
                        }
                    }
                }
                // aktualisiere memberCount
                groupMap[groupId] = Object.assign({}, group, { memberCount: group.memberCount - groupsToUpdate[groupId].length, lastChange, customerInfoMap: customerInfoMapCopy });
            }
        }
        ctx.setState(putGroups(clientId, groupMap));
    }
};
tslib_1.__decorate([
    Action(clientActions.SetClient),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, clientActions.SetClient]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "setClient", null);
tslib_1.__decorate([
    Action(clientActions.ClearClients),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "clearClients", null);
tslib_1.__decorate([
    Action(groupActions.LoadGroups),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.LoadGroups]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "loadGroups", null);
tslib_1.__decorate([
    Action(groupActions.SetGroups),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.SetGroups]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "setGroups", null);
tslib_1.__decorate([
    Action(groupActions.SetGroupsLoadFailed),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.SetGroupsLoadFailed]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "setGroupsLoadFailed", null);
tslib_1.__decorate([
    Action(groupActions.PatchGroup),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.PatchGroup]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "patchGroup", null);
tslib_1.__decorate([
    Action(groupActions.PatchGroupsWithUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.PatchGroupsWithUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "patchGroupsWithUsers", null);
tslib_1.__decorate([
    Action(groupActions.PatchGroups),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.PatchGroups]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "patchGroups", null);
tslib_1.__decorate([
    Action(groupActions.CreateGroup),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.CreateGroup]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "createGroup", null);
tslib_1.__decorate([
    Action(groupActions.DeleteGroupsWithUsers),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.DeleteGroupsWithUsers]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "deleteGroupsWithUsers", null);
tslib_1.__decorate([
    Action(userActions.CreateUser),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.CreateUser]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "createUser", null);
tslib_1.__decorate([
    Action(userActions.PatchUsersWithGroups),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, userActions.PatchUsersWithGroups]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "patchUsersWithGroups", null);
tslib_1.__decorate([
    Action(groupActions.RemoveUsersFromGroup),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, groupActions.RemoveUsersFromGroup]),
    tslib_1.__metadata("design:returntype", void 0)
], UsergroupState.prototype, "removeUsersFromGroup", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Function)
], UsergroupState, "selectGroupState", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Function)
], UsergroupState, "selectGroup", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", Function)
], UsergroupState, "selectGroupsById", null);
UsergroupState = tslib_1.__decorate([
    State({
        name: USERGROUP_STATE_NAME
    }),
    tslib_1.__metadata("design:paramtypes", [Store, DateService])
], UsergroupState);
export { UsergroupState };
