import { Inject, Injectable } from '@angular/core';
import { WINDOW_REF } from '../utils/injection-tokens';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  constructor(@Inject(WINDOW_REF) private window: Window, private permissionService: PermissionService) {}

  async copy(data: string): Promise<void> {
    const hasPermission = await this.permissionService.queryPermission('clipboard-write');

    if (hasPermission) {
      await this.window.navigator.clipboard.writeText(data);
    }
  }
}
