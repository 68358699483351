import { first } from 'rxjs/operators';
import { AppStorageService } from '../statemanagement/app-storage.service';
import { EnvironmentService } from './environment.service';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./environment.service";
import * as i2 from "./local-storage.service";
import * as i3 from "../statemanagement/app-storage.service";
import * as i4 from "../utils/injection-tokens";
/**
 * Ermöglicht die Einbindung der Authentifizierung des Benutzers per Fingerabdruck (TouchID) oder FaceID mit Hilfe
 * des Cordova Plugins von https://github.com/sjhoeksma/cordova-plugin-keychain-touch-id
 */
export class LockService {
    constructor(envService, storageService, appStorage, window) {
        this.envService = envService;
        this.storageService = storageService;
        this.appStorage = appStorage;
        this.window = window;
        /** Name des Eintrags für den Schlüsselbund */
        this.TOUCH_KEY_NAME = 'CocuunAdminAppAuthKey';
        /** COOKIE-Name des Lock-Eintrags der hinterlegt, ob der Login gespeichert werden soll oder nicht. */
        this.LOCK_COOKIE_NAME = 'useTouchIdLogin';
        /** Kennzeichnet, ob TouchID zur Verfügung steht */
        this.touchIDAvailable = false;
        if (this.touchIDPluginRegistered()) {
            this.checkTouchIDAvailable().then(() => (this.touchIDAvailable = true), () => (this.touchIDAvailable = false));
        }
    }
    /**
     * Stellt fest, ob das TouchID Plugin verfügbar ist und somit verwendet werden kann.
     */
    checkTouchIDAvailable() {
        return new Promise((resolve, reject) => {
            /** Prüft, ob die TouchID-Funktion unter Cordova verfügbar ist um somit eine Anmeldung per Fingerprint zu ermöglichen. */
            this.window.plugins.touchid.isAvailable((type) => {
                console.log('Touch-ID service available. Type:', type);
                resolve();
            }, (errMsg) => {
                console.log('TOUCH-ID service is not available on this device.', errMsg);
                reject();
            });
        });
    }
    /**
     * Ist das TouchID Plugin am Window registriert.
     * Dies kann nur im Fall von Cordova der Fall sein!
     */
    touchIDPluginRegistered() {
        return this.envService.isCordova && !!this.window.plugins && !!this.window.plugins.touchid;
    }
    /**
     * Bestimmt, ob die TouchID Funktion unter Cordova verfügbar ist.
     */
    isTouchIDAvailable() {
        return this.touchIDPluginRegistered() && this.touchIDAvailable;
    }
    /**
     * TouchID-Authentifizierung angefordert. Hierbei wird der zur Authentifizierung erforderliche
     * Schlüssel im Schlüsselbund abgelegt. Hierzu wird initial einmal der Fingerabdruck beim SAVE vom Benutzer angefragt.
     */
    activateTouchIDVerification(currentSession) {
        return new Promise((resolve, reject) => {
            if (this.isTouchIDAvailable()) {
                this.window.plugins.touchid.has(this.TOUCH_KEY_NAME, () => resolve(), () => {
                    // Key noch nicht vorhanden. Beim ersten Mal, dann neu Anfordern und Speichern
                    this.window.plugins.touchid.save(this.TOUCH_KEY_NAME, JSON.stringify(currentSession), () => {
                        this.storageService.set(this.LOCK_COOKIE_NAME, true);
                        resolve();
                    }, () => reject());
                });
            }
            else {
                resolve();
            }
        });
    }
    /**
     * Entfernt den hinterlegten TouchID-Key aus dem Schlüsselbund.
     */
    deactivateTouchIDVerification() {
        return new Promise((resolve, reject) => {
            if (this.isTouchIDAvailable()) {
                this.window.plugins.touchid.has(this.TOUCH_KEY_NAME, () => {
                    this.window.plugins.touchid.delete(this.TOUCH_KEY_NAME, () => {
                        this.storageService.remove(this.LOCK_COOKIE_NAME);
                        resolve();
                    }, () => reject());
                }, () => {
                    this.storageService.remove(this.LOCK_COOKIE_NAME);
                    resolve();
                });
            }
            else {
                resolve();
            }
        });
    }
    /**
     * Fordert die Authentifizierung über TouchID (Fingerabdruckscan) oder FaceID (Apple) an.
     */
    verifyTouchID(title) {
        return new Promise((resolve, reject) => {
            if (this.touchIDPluginRegistered() && this.storageService.getOrDefault(this.LOCK_COOKIE_NAME, false)) {
                this.window.plugins.touchid.verify(this.TOUCH_KEY_NAME, title, (password) => {
                    console.log('Service (TouchID / FaceID) verified account.');
                    const session = JSON.parse(password);
                    resolve(session);
                }, (errorCode) => {
                    console.log('Error during service (TouchID / FaceID) verification. ErrCode:', errorCode);
                    // # Errorcode #
                    // Android "Cancelled"
                    // IOS -2 (Abbrechen), -3 (Passwort)
                    reject();
                });
            }
            else {
                this.appStorage
                    .selectSession()
                    .pipe(first())
                    .subscribe(session => {
                    !this.envService.production ? resolve(session) : reject();
                });
            }
        });
    }
}
LockService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LockService_Factory() { return new LockService(i0.ɵɵinject(i1.EnvironmentService), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.AppStorageService), i0.ɵɵinject(i4.WINDOW_REF)); }, token: LockService, providedIn: "root" });
