import { AppEnvironment } from 'src/app/core/service/environment.service';

export const environment: AppEnvironment = {
  name: 'prod',
  baseUrl: 'https://www.cocuun.de',
  basePath: '/life',
  production: true,
  persistCaches: true,
  isCordova: false,
  isAndroid: false,
  isIOS: false,
  isElectron: false,
  additionalHeaders: {}
};
