import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Subject } from 'rxjs';
import { catchError, flatMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';
import { EnvironmentService } from './environment.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./session.service";
import * as i3 from "./environment.service";
import * as i4 from "@angular/router";
import * as i5 from "../utils/injection-tokens";
export class HttpService {
    constructor(httpClient, sessionService, envService, router, window) {
        this.httpClient = httpClient;
        this.sessionService = sessionService;
        this.envService = envService;
        this.router = router;
        this.window = window;
        /** Standard-TIMEOUT beträgt jetzt 60 Minuten */
        this.DEFAULT_TIMEOUT = `${60 * 60 * 1000}`;
        /** Extralanger TIMEOUT für Löschaktionen */
        this.LONG_TIMEOUT = this.DEFAULT_TIMEOUT;
        this.clientVersion = 32;
        this.logout$ = new Subject();
    }
    get(url, addHeaders, tryRelog = true) {
        const headers = this.buildHeaders(addHeaders);
        return this.httpClient.get(url, { headers, reportProgress: true }).pipe(takeUntil(this.logout$), this.handleErrorOperator(() => this.get(url, addHeaders, false), tryRelog));
    }
    post(url, body, addHeaders, tryRelog = true) {
        const headers = this.buildHeaders(addHeaders);
        return this.httpClient.post(url, body, { headers, reportProgress: true }).pipe(takeUntil(this.logout$), this.handleErrorOperator(() => this.post(url, body, addHeaders, false), tryRelog));
    }
    put(url, body, addHeaders, tryRelog = true) {
        const headers = this.buildHeaders(addHeaders);
        return this.httpClient.put(url, body, { headers, reportProgress: true }).pipe(takeUntil(this.logout$), this.handleErrorOperator(() => this.put(url, body, addHeaders, false), tryRelog));
    }
    delete(url, addHeaders, tryRelog = true) {
        const headers = this.buildHeaders(addHeaders);
        return this.httpClient.delete(url, { headers, reportProgress: true }).pipe(takeUntil(this.logout$), this.handleErrorOperator(() => this.delete(url, addHeaders, false), tryRelog));
    }
    getSSO(url, addHeaders) {
        return this.get(this.resolveSSOUrl(url), addHeaders);
    }
    getCore(mdIdentifier, url, addHeaders) {
        const identity = this.resolveToIdentity(mdIdentifier);
        return this.get(this.resolveCoreUrl(identity, url), this.buildCoreHeaders(identity, addHeaders));
    }
    postCore(mdIdentifier, url, body, addHeaders) {
        const identity = this.resolveToIdentity(mdIdentifier);
        return this.post(this.resolveCoreUrl(identity, url), body, this.buildCoreHeaders(identity, addHeaders));
    }
    putCore(mdIdentifier, url, body, addHeaders) {
        const identity = this.resolveToIdentity(mdIdentifier);
        return this.put(this.resolveCoreUrl(identity, url), body, this.buildCoreHeaders(identity, addHeaders));
    }
    deleteCore(mdIdentifier, url, addHeaders) {
        const identity = this.resolveToIdentity(mdIdentifier);
        return this.delete(this.resolveCoreUrl(identity, url), this.buildCoreHeaders(identity, addHeaders));
    }
    login(email, password) {
        const url = this.resolveSSOUrl('rest/auth/login');
        const headers = new HttpHeaders(Object.assign({ version: this.clientVersion.toString(), MBAuthorization: 'Basic ' + btoa(email) + ':' + btoa(password) }, environment.additionalHeaders));
        return this.httpClient.post(url, null, { headers });
    }
    getSSOWithoutAuth(url) {
        const headers = new HttpHeaders(Object.assign({ version: this.clientVersion.toString() }, environment.additionalHeaders));
        return this.httpClient.get(this.resolveSSOUrl(url), { headers });
    }
    resolveSSOUrl(path) {
        let url = null;
        if (this.envService.isWeb) {
            const baseUrl = this.window.location.protocol + '//' + this.window.location.hostname;
            const port = environment.ssoPort !== undefined ? ':' + environment.ssoPort : '';
            url = baseUrl + port + '/';
        }
        else {
            url = environment.baseUrl + '/';
        }
        if (environment.name === 'abnahme') {
            url += 'abnahme-';
        }
        return url + 'sso/' + path;
    }
    resolveCoreUrl(identity, path) {
        const client = identity.client;
        const protocol = this.envService.isWeb
            ? this.window.location.protocol
            : this.envService.isElectron && environment.name === 'dev'
                ? 'http:'
                : 'https:';
        let baseURL = protocol + '//' + client.host;
        if (null != client.port && 80 !== client.port && 443 !== client.port) {
            baseURL += ':' + client.port;
        }
        if (environment.name === 'abnahme') {
            baseURL += '/abnahme-';
        }
        else {
            baseURL += '/';
        }
        baseURL += client.endpoint + '/';
        return baseURL + path;
    }
    /**
     * Erzeugt einen ErrorHandler der bei einem Fehlgeschlagenen HttpRequest
     * die Fehlermeldung loggt und wenn das SessionToken abgelaufen ist, versucht
     * ein neues zu besorgen und den Request zu wiederholen. Schlaegt der Refresh des
     * Sessiontokens fehl mit einem 400 oder 401, wird der Benutzer ausgeloggt.
     */
    handleErrorOperator(retryFn, tryRelog) {
        return catchError((error) => {
            if (error.error instanceof ErrorEvent) {
                console.error('An client-side or network error occurred: ', error.error.message);
            }
            else if (error.status === 0) {
                console.error('Request failed, we are offline.');
            }
            else if (error.error && (error.error.appErrorCode === 38 || error.error.appErrorCode === 39)) {
                const email = this.sessionService.get().email;
                const password = this.sessionService.get().password;
                return tryRelog
                    ? this.login(email, password).pipe(catchError((loginError) => {
                        if (loginError.error && (loginError.error.errorcode === 400 || loginError.error.errorcode === 401)) {
                            this.sessionService.clear();
                            this.router.navigate(['/']);
                        }
                        return throwError(loginError);
                    }), flatMap(resp => {
                        this.sessionService.updateToken(resp.token);
                        return retryFn();
                    }))
                    : throwError(error);
            }
            else if (error.name === 'TimeoutError') {
                // Request hat zu lange gebraucht und wurde abgebrochen
                console.error('Request timed out');
            }
            else {
                console.error(`The backend returned an unsuccessful response code ${error.status}, ` +
                    `The response body may contain clues as to what went wrong: ${JSON.stringify(error.error)}`);
            }
            return throwError(error);
        });
    }
    resolveToIdentity(identity) {
        return typeof identity === 'number' ? this.sessionService.getIdentityByExtId(identity) : identity;
    }
    buildCoreHeaders(identity, addHeaders) {
        return Object.assign({ MBClientID: identity.client.externalId.toString() }, addHeaders);
    }
    buildHeaders(addHeaders) {
        return new HttpHeaders(Object.assign({ version: this.clientVersion.toString(), MBAuthorization: this.sessionService.get().token }, environment.additionalHeaders, addHeaders));
    }
    logout() {
        this.logout$.next();
    }
}
HttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.EnvironmentService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.WINDOW_REF)); }, token: HttpService, providedIn: "root" });
