import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../utils/injection-tokens";
export class LanguageService {
    constructor(localStorageService, translate, rawLang) {
        this.localStorageService = localStorageService;
        this.translate = translate;
        this.rawLang = rawLang;
        this.cookieKey = 'cc_lang_id';
        this.all = [
            {
                id: 1,
                name: 'DE',
                code: 'de',
                culture: 'de_DE',
                icon: 'flags:de',
                fullName: 'COMMON.LANGUAGE.DE'
            },
            {
                id: 2,
                name: 'EN',
                code: 'en',
                culture: 'en_US',
                icon: 'flags:gb',
                fullName: 'COMMON.LANGUAGE.EN'
            }
        ];
    }
    init() {
        const id = this.localStorageService.get(this.cookieKey);
        this.selLang = this.all.find(lang => lang.id === id) || this.all[0];
        this.initTransformedLangs();
        this.translate.setDefaultLang('de');
        this.translate.use(this.selLang.code);
    }
    get languages() {
        return this.all;
    }
    get selectedLang() {
        return this.selLang;
    }
    changeLang(language) {
        this.localStorageService.set(this.cookieKey, language.id);
        this.translate.use(language.code);
        this.selLang = language;
    }
    initTransformedLangs() {
        // Verfügbare Sprachen initialisieren
        this.all.forEach(lang => this.translate.setTranslation(lang.code, {}));
        // Eigene Defintion parsen und in den Translate-Service schreiben
        this.analyseLangObject(null, this.rawLang);
    }
    analyseLangObject(path, obj) {
        // Für jeden Key...
        Object.keys(obj).forEach(key => {
            const localpath = path === null ? key : path + '.' + key;
            const value = obj[key];
            if (this.isLangKey(value)) {
                // Ist eine konkrete {de:..en:..}-Definition, pro Sprache hinterlegen
                this.all.forEach(lang => {
                    this.translate.set(localpath, value[lang.code], lang.code);
                });
            }
            else {
                // Rekursiv weitersuchen
                this.analyseLangObject(localpath, value);
            }
        });
    }
    /** Stellt fest/rät zur Laufzeit, ob das übergeben Objekt das Lang-Key-Interface unterstützt */
    isLangKey(obj) {
        return this.all.filter(lang => null == obj[lang.code]).length === 0 && Object.keys(obj).length === this.all.length;
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.RAWLANG_TOKEN)); }, token: LanguageService, providedIn: "root" });
