import * as i0 from "@angular/core";
/**
 * Service-Stub, welcher die Implementierung für allgemeine Platformen enthält die nicht ANDROID sind.
 */
export class PermissionServiceStub {
    constructor() { }
    verifyFilesystemRead() {
        return Promise.resolve();
    }
}
PermissionServiceStub.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionServiceStub_Factory() { return new PermissionServiceStub(); }, token: PermissionServiceStub, providedIn: "root" });
