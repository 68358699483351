/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mail-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "../../dialog/dlg-header.component.ngfactory";
import * as i7 from "../../dialog/dlg-header.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "./mail-preview.component";
import * as i17 from "../../../core/service/clipboard.service";
var styles_MailPreviewComponent = [i0.styles];
var RenderType_MailPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MailPreviewComponent, data: {} });
export { RenderType_MailPreviewComponent as RenderType_MailPreviewComponent };
export function View_MailPreviewComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "ca-dlg-header", [], null, null, null, i6.View_DlgHeaderComponent_0, i6.RenderType_DlgHeaderComponent)), i1.ɵdid(4, 49152, null, 0, i7.DlgHeaderComponent, [i8.MatDialog, i9.TranslateService], { headerTitle: [0, "headerTitle"], hideInfoButton: [1, "hideInfoButton"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "button", [["class", "dlg-copy-button"], ["mat-icon-button", ""], ["testid", "btnDlgShowInfo"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "mdi:content_copy"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "mail-body mat-dialog-content"], ["mat-dialog-content", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i8.MatDialogContent, [], null, null), i1.ɵppd(11, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.subject; var currVal_2 = true; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_7 = "mdi:content_copy"; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (i1.ɵnov(_v, 6).disabled || null); var currVal_4 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 8).inline; var currVal_6 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), _co.data.body)); _ck(_v, 9, 0, currVal_8); }); }
export function View_MailPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ca-mail-preview", [], null, null, null, View_MailPreviewComponent_0, RenderType_MailPreviewComponent)), i1.ɵdid(1, 49152, null, 0, i16.MailPreviewComponent, [i8.MAT_DIALOG_DATA, i17.ClipboardService], null, null)], null, null); }
var MailPreviewComponentNgFactory = i1.ɵccf("ca-mail-preview", i16.MailPreviewComponent, View_MailPreviewComponent_Host_0, {}, {}, []);
export { MailPreviewComponentNgFactory as MailPreviewComponentNgFactory };
