/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./identity-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./identity-select.component";
var styles_IdentitySelectComponent = [i0.styles];
var RenderType_IdentitySelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IdentitySelectComponent, data: {} });
export { RenderType_IdentitySelectComponent as RenderType_IdentitySelectComponent };
function View_IdentitySelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-list-item", [["class", "mat-list-item"], ["tabindex", "0"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openIdentity(_co.data.identityMap[_v.context.$implicit]) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.openIdentity(_co.data.identityMap[_v.context.$implicit]) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(603979776, 2, { _avatar: 0 }), i1.ɵqud(603979776, 3, { _icon: 0 }), (_l()(), i1.ɵted(5, 2, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.data.identityMap[_v.context.$implicit].client.name; _ck(_v, 5, 0, currVal_2); }); }
export function View_IdentitySelectComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(2, null, [" ", "\n"])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i2.View_MatNavList_0, i2.RenderType_MatNavList)), i1.ɵdid(5, 704512, null, 0, i3.MatNavList, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_IdentitySelectComponent_1)), i1.ɵdid(7, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _co.data.clientIds; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("LOGIN.CLIENT_ADMIN_QUESTION")); _ck(_v, 2, 0, currVal_1); }); }
export function View_IdentitySelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ca-identity-select", [], null, null, null, View_IdentitySelectComponent_0, RenderType_IdentitySelectComponent)), i1.ɵdid(1, 49152, null, 0, i7.IdentitySelectComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], null, null); }
var IdentitySelectComponentNgFactory = i1.ɵccf("ca-identity-select", i7.IdentitySelectComponent, View_IdentitySelectComponent_Host_0, {}, {}, []);
export { IdentitySelectComponentNgFactory as IdentitySelectComponentNgFactory };
