import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { AlertDialComponent } from 'src/app/common-ui/modal/alert-dial/alert-dial.component';
import { ConfirmDialComponent } from 'src/app/common-ui/modal/confirm-dial/confirm-dial.component';
import { ImprintDialComponent } from 'src/app/common-ui/modal/imprint-dial/imprint-dial.component';
import { InfoDialComponent } from 'src/app/common-ui/modal/info-dial/info-dial.component';
import { AuthService } from 'src/app/core/service/auth.service';
import { DialogService } from 'src/app/core/service/dialog.service';
import { HttpService } from 'src/app/core/service/http.service';
import { LoadingCircleService } from 'src/app/core/service/loading-circle.service';
import { LocalStorageService } from 'src/app/core/service/local-storage.service';
import { LockService } from 'src/app/core/service/lock.service';
import { Identity, SessionService } from 'src/app/core/service/session.service';
import { AppStorageService } from 'src/app/core/statemanagement/app-storage.service';
import { DEFAULT_HEADER } from 'src/app/core/statemanagement/state/application.state';
import { WINDOW_REF } from 'src/app/core/utils/injection-tokens';
import { environment } from 'src/environments/environment';
import { IdentitySelectArgs, IdentitySelectComponent } from '../../component/identity-select/identity-select.component';
import { NavigationService } from 'src/app/core/service/navigation.service';
import { STR_IGNORE_CASE_COMPARATOR } from 'src/app/core/shared/SortUtils';

export interface TosTopData {
  acceptUntil: number;
  content: string;
  date: number;
  id: number;
  revision: number;
  type: string;
}

@Component({
  selector: 'ca-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageComponent implements OnInit, OnDestroy {
  /**
   * Deaktiviert den Anmelde-Button, wenn die Anfrage noch läuft
   */
  loginInProg$ = new BehaviorSubject<boolean>(false);
  loginErrMsg = new BehaviorSubject<string>(null);
  /** Emittet, wenn auf den Login-Button gedrückt wird oder Enter im Passwortfeld gedrückt wird */
  login$ = new Subject<void>();
  private sub = new Subscription();

  private GET_PARAM = '?forgotPwd';

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.email]),
    password: new FormControl('')
  });

  pwd$ = new Subject<string>();

  constructor(
    private dialog: MatDialog,
    private sessionService: SessionService,
    private storageService: LocalStorageService,
    private lockService: LockService,
    private translate: TranslateService,
    private authService: AuthService,
    private httpService: HttpService,
    private loadingCircleService: LoadingCircleService,
    private appStorage: AppStorageService,
    private dialogService: DialogService,
    private navService: NavigationService,
    @Inject(WINDOW_REF) private window: Window // @Inject(NAVIGATOR_REF) private navigator: Navigator
  ) {
    this.appStorage.setHeaderParams({
      ...DEFAULT_HEADER,
      infoTextTitle: 'LOGIN.LOGIN',
      infoTextContent: 'INFOTEXT.LOGIN'
    });
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    // setzt das eingegebene Passwort am FormControl
    this.pwd$.subscribe(pass => this.loginForm.controls.password.setValue(pass));

    this.sub.add(this.loginForm.valueChanges.subscribe(() => this.loginErrMsg.next(null)));

    this.login$
      .pipe(
        withLatestFrom(this.loginInProg$),
        filter(([_, loginInProg]) => !loginInProg && this.isFormFilled() && this.loginForm.valid)
      )
      .subscribe(() => this.login());
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.loginErrMsg.complete();
    this.pwd$.complete();
    this.sub.unsubscribe();
  }

  /**
   * Gibt einen Fehlertext zur Email-Eingabe zurück
   */
  getErrorMessageEmail(): string {
    if (this.loginForm.controls.email.hasError('email')) {
      return 'ERROR.INVALID_EMAIL';
    }
    return null;
  }

  /**
   * Pürft, ob die benötigten Felder des login's gefüllt sind.
   */
  isFormFilled(): boolean {
    return this.loginForm.controls.email.value && this.loginForm.controls.password.value;
  }

  /**
   * Führt den Login durch
   */
  private login(): void {
    this.loginErrMsg.next(null);
    this.loginInProg$.next(true);
    const email = this.loginForm.controls.email.value;
    const password = this.loginForm.controls.password.value;
    // Fuer Entwicklung bleiben wir eingeloggen
    const persistLogin =
      !environment.production || this.storageService.getOrDefault(this.lockService.LOCK_COOKIE_NAME, false);
    this.authService
      .login(email, password, persistLogin)
      .pipe(withLatestFrom(this.appStorage.selectSession()))
      .subscribe(
        ([_, session]) => {
          this.loginInProg$.next(false);
          const clientIds = session.externalClientIds;
          if (clientIds.length > 1) {
            // Mandanten Alphabetisch sortieren
            const sortedClientIds = [...clientIds].sort((a, b) =>
              STR_IGNORE_CASE_COMPARATOR(session.identitiesMap[a].client.name, session.identitiesMap[b].client.name)
            );
            const data: IdentitySelectArgs = {
              clientIds: sortedClientIds,
              identityMap: session.identitiesMap
            };
            this.dialog
              .open(IdentitySelectComponent, { data, autoFocus: false })
              .afterClosed()
              .subscribe((identity: Identity) => {
                if (identity) {
                  const clientId = identity.client.externalId;
                  this.sessionService.activateSession(clientId);
                  this.navService.openDefaultFirstRoute(true);
                }
              });
          } else {
            this.sessionService.activateSession(clientIds[0]);
            this.navService.openDefaultFirstRoute(true);
          }
        },
        error => {
          console.warn(error);
          this.loginErrMsg.next('ERR.LOGIN_FAILED');
          this.loginInProg$.next(false);

          // Zeigt einen Dialog, wenn das Konto temporaer gesperrt ist
          if (error.status === 400) {
            this.dialog.open(AlertDialComponent, {
              data: {
                title: this.translate.instant('ERR.LOGIN_DENIED'),
                body: this.translate.instant('ERR.TEMPORARY_BANNED'),
                btnLbl: this.translate.instant('DIALOG.OK')
              },
              autoFocus: false
            });
          }

          // Infodialog, falls TOS oder TOP noch nicht angenommen wurde
          else if (error.error && error.error.appErrorCode === 86) {
            this.dialog.open(AlertDialComponent, {
              data: {
                title: this.translate.instant('ERR.LOGIN_DENIED'),
                body: this.translate.instant('ERR.NO_TOS_TOP'),
                btnLbl: this.translate.instant('DIALOG.OK')
              },
              autoFocus: false
            });
          }
        }
      );
  }

  /**
   * Öffnet einen Dialog zur Passwortänderung.
   */
  forgotPwd(): void {
    this.dialog
      .open(ConfirmDialComponent, {
        data: {
          title: this.translate.instant('LOGIN.FORGOT_PASSWORD'),
          body: this.translate.instant('RESET_PWD_DIALOG'),
          confirmBtnLbl: this.translate.instant('DIALOG.YES'),
          cancelBtnLbl: this.translate.instant('DIALOG.NO')
        }
      })
      .afterClosed()
      .pipe(filter(d => d))
      .subscribe(() => {
        let mail = this.loginForm.controls.email.value;
        if (null !== mail && mail.length > 0) {
          mail = '=' + btoa(mail);
        }
        this.window.location.assign(environment.baseUrl + environment.basePath + this.GET_PARAM + mail);
      });
  }

  /**
   * Öffent einen Dialog mit dem Impressum
   */
  openAbout(ev: Event): void {
    this.dialog.open(ImprintDialComponent);
  }

  /**
   * Öffenet einen Dialog mit den AGBs
   */
  openTos(ev: Event): void {
    this.openTosTopDialog('./rest/v1/info/tos');
  }

  /**
   * Öffenet einen Dialog mit den Datenschutz-Bestimmungen
   */
  openPrivacy(ev: Event): void {
    this.openTosTopDialog('./rest/v1/info/top');
  }

  private openTosTopDialog(url: string): void {
    this.loadingCircleService.open();
    this.httpService.getSSOWithoutAuth<TosTopData>(url).subscribe(
      response => {
        this.loadingCircleService.close();
        this.dialog.open(InfoDialComponent, {
          data: {
            title: this.translate.instant(
              response.type === 'TermsOfPrivacy' ? 'LOGIN.PRIVACY_TITLE' : 'LOGIN.TERMS_OF_USE_TITLE'
            ),
            content: response.content
          }
        });
      },
      error => {
        this.loadingCircleService.close();
        this.dialogService.displayError(error);
      }
    );
  }
}
