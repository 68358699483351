import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, OperatorFunction, throwError, Subject } from 'rxjs';
import { catchError, flatMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerSSO, Identity, SessionService } from './session.service';
import { WINDOW_REF } from '../utils/injection-tokens';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  /** Standard-TIMEOUT beträgt jetzt 60 Minuten */
  DEFAULT_TIMEOUT = `${60 * 60 * 1000}`;
  /** Extralanger TIMEOUT für Löschaktionen */
  LONG_TIMEOUT = this.DEFAULT_TIMEOUT;

  clientVersion = 32;
  private logout$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private envService: EnvironmentService,
    private router: Router,
    @Inject(WINDOW_REF) private window: Window
  ) {}

  get<T>(url: string, addHeaders?: HeadersMap, tryRelog = true): Observable<T> {
    const headers = this.buildHeaders(addHeaders);
    return this.httpClient.get<T>(url, { headers, reportProgress: true }).pipe(
      takeUntil(this.logout$),
      this.handleErrorOperator<T>(() => this.get<T>(url, addHeaders, false), tryRelog)
    );
  }

  post<T>(url: string, body: any, addHeaders?: HeadersMap, tryRelog = true): Observable<T> {
    const headers = this.buildHeaders(addHeaders);
    return this.httpClient.post<T>(url, body, { headers, reportProgress: true }).pipe(
      takeUntil(this.logout$),
      this.handleErrorOperator<T>(() => this.post<T>(url, body, addHeaders, false), tryRelog)
    );
  }

  put<T>(url: string, body: any, addHeaders?: HeadersMap, tryRelog = true): Observable<T> {
    const headers = this.buildHeaders(addHeaders);
    return this.httpClient.put<T>(url, body, { headers, reportProgress: true }).pipe(
      takeUntil(this.logout$),
      this.handleErrorOperator<T>(() => this.put<T>(url, body, addHeaders, false), tryRelog)
    );
  }

  delete<T>(url: string, addHeaders?: HeadersMap, tryRelog = true): Observable<T> {
    const headers = this.buildHeaders(addHeaders);
    return this.httpClient.delete<T>(url, { headers, reportProgress: true }).pipe(
      takeUntil(this.logout$),
      this.handleErrorOperator<T>(() => this.delete<T>(url, addHeaders, false), tryRelog)
    );
  }

  getSSO<T>(url: string, addHeaders?: HeadersMap): Observable<T> {
    return this.get<T>(this.resolveSSOUrl(url), addHeaders);
  }

  getCore<T>(mdIdentifier: number | Identity, url: string, addHeaders?: HeadersMap): Observable<T> {
    const identity = this.resolveToIdentity(mdIdentifier);
    return this.get<T>(this.resolveCoreUrl(identity, url), this.buildCoreHeaders(identity, addHeaders));
  }

  postCore<T>(mdIdentifier: number | Identity, url: string, body: any, addHeaders?: HeadersMap): Observable<T> {
    const identity = this.resolveToIdentity(mdIdentifier);
    return this.post<T>(this.resolveCoreUrl(identity, url), body, this.buildCoreHeaders(identity, addHeaders));
  }

  putCore<T>(mdIdentifier: number | Identity, url: string, body: any, addHeaders?: HeadersMap): Observable<T> {
    const identity = this.resolveToIdentity(mdIdentifier);
    return this.put<T>(this.resolveCoreUrl(identity, url), body, this.buildCoreHeaders(identity, addHeaders));
  }

  deleteCore<T>(mdIdentifier: number | Identity, url: string, addHeaders?: HeadersMap): Observable<T> {
    const identity = this.resolveToIdentity(mdIdentifier);
    return this.delete<T>(this.resolveCoreUrl(identity, url), this.buildCoreHeaders(identity, addHeaders));
  }

  login(email: string, password: string): Observable<LoginResponse> {
    const url = this.resolveSSOUrl('rest/auth/login');
    const headers = new HttpHeaders({
      version: this.clientVersion.toString(),
      MBAuthorization: 'Basic ' + btoa(email) + ':' + btoa(password),
      ...environment.additionalHeaders
    });
    return this.httpClient.post<LoginResponse>(url, null, { headers });
  }

  getSSOWithoutAuth<T>(url: string): Observable<T> {
    const headers = new HttpHeaders({
      version: this.clientVersion.toString(),
      ...environment.additionalHeaders
    });
    return this.httpClient.get<T>(this.resolveSSOUrl(url), { headers });
  }

  resolveSSOUrl(path: string): string {
    let url = null;
    if (this.envService.isWeb) {
      const baseUrl = this.window.location.protocol + '//' + this.window.location.hostname;
      const port = environment.ssoPort !== undefined ? ':' + environment.ssoPort : '';
      url = baseUrl + port + '/';
    } else {
      url = environment.baseUrl + '/';
    }

    if (environment.name === 'abnahme') {
      url += 'abnahme-';
    }

    return url + 'sso/' + path;
  }

  resolveCoreUrl(identity: Identity, path: string): string {
    const client = identity.client;
    const protocol = this.envService.isWeb
      ? this.window.location.protocol
      : this.envService.isElectron && environment.name === 'dev'
      ? 'http:'
      : 'https:';
    let baseURL = protocol + '//' + client.host;
    if (null != client.port && 80 !== client.port && 443 !== client.port) {
      baseURL += ':' + client.port;
    }
    if (environment.name === 'abnahme') {
      baseURL += '/abnahme-';
    } else {
      baseURL += '/';
    }
    baseURL += client.endpoint + '/';
    return baseURL + path;
  }

  /**
   * Erzeugt einen ErrorHandler der bei einem Fehlgeschlagenen HttpRequest
   * die Fehlermeldung loggt und wenn das SessionToken abgelaufen ist, versucht
   * ein neues zu besorgen und den Request zu wiederholen. Schlaegt der Refresh des
   * Sessiontokens fehl mit einem 400 oder 401, wird der Benutzer ausgeloggt.
   */
  private handleErrorOperator<T>(retryFn: () => Observable<T>, tryRelog: boolean): OperatorFunction<T, T> {
    return catchError<T, Observable<T>>((error: HttpErrorResponse) => {
      if (error.error instanceof ErrorEvent) {
        console.error('An client-side or network error occurred: ', error.error.message);
      } else if (error.status === 0) {
        console.error('Request failed, we are offline.');
      } else if (error.error && (error.error.appErrorCode === 38 || error.error.appErrorCode === 39)) {
        const email = this.sessionService.get().email;
        const password = this.sessionService.get().password;
        return tryRelog
          ? this.login(email, password).pipe(
              catchError((loginError: HttpErrorResponse) => {
                if (loginError.error && (loginError.error.errorcode === 400 || loginError.error.errorcode === 401)) {
                  this.sessionService.clear();
                  this.router.navigate(['/']);
                }
                return throwError(loginError);
              }),
              flatMap(resp => {
                this.sessionService.updateToken(resp.token);
                return retryFn();
              })
            )
          : throwError(error);
      } else if ((error.name as string) === 'TimeoutError') {
        // Request hat zu lange gebraucht und wurde abgebrochen
        console.error('Request timed out');
      } else {
        console.error(
          `The backend returned an unsuccessful response code ${error.status}, ` +
            `The response body may contain clues as to what went wrong: ${JSON.stringify(error.error)}`
        );
      }
      return throwError(error);
    });
  }

  private resolveToIdentity(identity: number | Identity): Identity {
    return typeof identity === 'number' ? this.sessionService.getIdentityByExtId(identity) : identity;
  }

  private buildCoreHeaders(identity: Identity, addHeaders?: HeadersMap): HeadersMap {
    return {
      MBClientID: identity.client.externalId.toString(),
      ...addHeaders
    };
  }

  private buildHeaders(addHeaders?: HeadersMap): HttpHeaders {
    return new HttpHeaders({
      version: this.clientVersion.toString(),
      MBAuthorization: this.sessionService.get().token,
      ...environment.additionalHeaders,
      ...addHeaders
    });
  }

  logout(): void {
    this.logout$.next();
  }
}

export interface LoginResponse {
  token: string;
  identities: Identity[];
  customer: CustomerSSO;
}

interface HeadersMap {
  [s: string]: string;
}
