import { shareReplay } from 'rxjs/operators';
import { ShareReplayConfig } from 'rxjs/internal/operators/shareReplay';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { NgZone } from '@angular/core';

/**
 * ShareReplay erhaelt die Subscription zum Source-Observable am leben. Der Operator ist optiomal
 * um z.B: Http-Request zu cachen. In unseren Faellen verwenden wir den Operator aber in Kombination
 * mit langlebigen Observables welche nicht completen. Deswegen muessen wir die Subscription zur Source
 * loesen sobald keine innere Subscription mehr vorhanden ist. Dafuer gibt es das refCount Flag in der
 * Config des ShareReplay Operators. Um diese Config nicht jedes mal erzeugen zu muessen und da wir
 * ShareReplay nur in Kombination mit BehaviourSubjects und bufferSize 1 verwenden, gibt es dafuer nun
 * diesen Operator.
 *
 * Eine andere Moeglichkeit waere gewesen refCount in Kombination mit publishReplay zu verwenden. Der große
 * Unterschied ist allerdings das publishReplay das ReplaySubject recycled wird. So erhaelt man einmal den letzten
 * Wert wenn neu subscribed wird. Da wir den Operator aber nur in Kombination mit BehaviourSubjects verwenden
 * und daher sowieso den letzten Wert emittet bekommen, ist es besser das interne ReplaySubject nicht zu recyceln.
 *
 * Das verhalten sollte also multicast(x => new Rx.ReplaySubject(1)), refCount() entsprechen.
 *
 * @param config ShareReplayConfig die an den intern genutzt ShareReplay Operator weitergeleitet wird
 */
export function shareBehaviour<T>(config?: ShareReplayConfig): MonoTypeOperatorFunction<T> {
  return shareReplay({
    bufferSize: 1,
    refCount: true,
    ...config
  });
}

/**
 * Wenn ein Observable außerhalb der Angular Zone läuft, kann mit diesem Operator das Observable
 * wieder innerhalb der Zone läuft, damit die ChangeDetection von Angular funktioniert.
 * https://stackoverflow.com/questions/37580071/how-can-i-monkey-patch-an-observable-for-zone-js
 *
 * @param zone NgZone
 */
export function onZone<T>(zone: NgZone): MonoTypeOperatorFunction<T> {
  return function runInZone<TP>(source: Observable<T>): Observable<TP> {
    return new Observable(observer => {
      const onNext = value => zone.run(() => observer.next(value));
      const onError = e => zone.run(() => observer.error(e));
      const onComplete = () => zone.run(() => observer.complete());
      return source.subscribe(onNext, onError, onComplete);
    });
  };
}
