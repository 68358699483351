import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from '../../../core/service/clipboard.service';

export interface MailPreviewDialogData {
  subject: string;
  body: string;
}

@Component({
  selector: 'ca-mail-preview',
  templateUrl: './mail-preview.component.html',
  styleUrls: ['./mail-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailPreviewComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MailPreviewDialogData,
    private clipboardService: ClipboardService
  ) {}

  copy(): void {
    void this.clipboardService.copy(`${this.data.subject}\n\n${this.data.body}`);
  }
}
