import { Session } from 'src/app/core/service/session.service';

export class SetSession {
  static readonly type = '[Session] set Session';
  constructor(public payload: Session) {}
}

export class PatchSession {
  static readonly type = '[Session] patch Session';
  constructor(public payload: Partial<Session>) {}
}
