import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

/**
 * Bricht einen HTTP-Request ab, wenn zu lange kein Progressevent / Response kommt.
 * Die ProgressEvents wurden extra ueber das reportProgress Flag im HttpService
 * angeschaltet. Diese kommen nun hier in dem Interceptor an und refreshen den Timeout,
 * landen aber nicht in dem Observable des HttpRequests. Dieses erhaelt nach wie vor nur
 * den Response. Um die Progressevents auch dort zu erhalten muesste zusaetzlich beim
 * senden des Request die Option 'observe' auf den Wert 'events' gesetzt werden.
 *
 * Zeit kann über den HTTP-Header 'timeout' gesteuert werden
 */
@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  /**
   * Zeit im Millisekunden, ab wann ein Request abbricht
   * 900000ms = 900s = 15min
   */
  private TIMEOUT = 900000;

  /**
   * Fängt den Request ab
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutStr = req.headers.get('timeout');
    const timeoutValue = timeoutStr ? Number(timeoutStr) : this.TIMEOUT;
    return next.handle(req).pipe(timeout(timeoutValue));
  }
}
