import { AlertDialComponent } from 'src/app/common-ui/modal/alert-dial/alert-dial.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ConfirmDialComponent } from 'src/app/common-ui/modal/confirm-dial/confirm-dial.component';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/material/dialog";
export class DialogService {
    constructor(translate, dialog) {
        this.translate = translate;
        this.dialog = dialog;
    }
    /**
     * Öffnet einen Dialog abhängig vom mitgegebenen Fehler
     *
     * @param error Der Fehler, der aufgetreten ist
     */
    displayError(error) {
        console.error(error);
        const data = {
            title: this.translate.instant('ERROR.TITLE'),
            body: this.translate.instant('ERROR.GENERIC'),
            btnLbl: this.translate.instant('DIALOG.OK')
        };
        if (error.status === 0) {
            data.title = this.translate.instant('ERROR.OFFLINE_TITLE');
            data.body = this.translate.instant('ERROR.OFFLINE');
        }
        else if (error.error) {
            switch (error.error.appErrorCode) {
                case 12:
                    data.body = this.translate.instant('ERROR.TOO_MANY_TRIES');
                    break;
                case 21:
                    data.body = this.translate.instant('ERROR.INSUFFICIENT_RIGHTS');
                    break;
                case 23:
                    data.title = this.translate.instant('ERROR.WRONG_PASSWORD');
                    data.body = this.translate.instant('ERROR.WRONG_ADMIN_PASSWORD');
                    break;
                case 60:
                    data.title = this.translate.instant('ERROR.EMAIL_ALREADY_USED_TITLE');
                    data.body = this.translate.instant('ERROR.EMAIL_ALREADY_USED');
                    break;
                case 14003:
                    data.body = this.translate.instant('ERROR.GROUPMANAGER_MUST_REMAIN');
                    break;
                case 14004:
                    data.body = this.translate.instant('ERROR.ADMIN_MUST_REMAIN');
                    break;
            }
        }
        return this.dialog
            .open(AlertDialComponent, { data })
            .afterClosed()
            .pipe(map(() => (error.error ? error.error.appErrorCode : null)));
    }
    /**
     * Öffnet einen Dialog zur Benachrichtigung an den Benutzer
     * @param error Error
     */
    displayErrorNoPermission(error) {
        const data = {
            title: this.translate.instant('ERROR.TITLE'),
            body: this.translate.instant('ERROR.NO_PERMISSIONS'),
            btnLbl: this.translate.instant('DIALOG.OK'),
            error: error.message
        };
        return this.dialog
            .open(AlertDialComponent, { data })
            .afterClosed()
            .toPromise();
    }
    /**
     * Wird genutzt um Konfliktfehler anzuzeigen
     *
     * @param error Error der vom Backend kommt
     */
    displayConflictError(error) {
        console.error(error);
        let body = 'ERROR.GENERIC';
        switch (error.error.appErrorCode) {
            case 14001:
                body = 'ERROR.CONFLICT_USER_BODY';
                break;
            case 14002:
                body = 'ERROR.CONFLICT_GROUP_BODY';
                break;
        }
        const data = {
            title: this.translate.instant('ERROR.CONFLICT_TITLE'),
            body: this.translate.instant(body),
            btnLbl: this.translate.instant('DIALOG.OK')
        };
        return this.dialog.open(AlertDialComponent, { data }).afterClosed();
    }
    /**
     * Öffnet einen Dialog, falls Einladungsmails fehlgeschlagen sind
     *
     * @param names Array mit Strings, die ausgegeben werden, um zu sagen, bei welchem User das Versenden fehlgeschlagen ist.
     *              Aktuell werden die E-Mails mitgegeben und nicht die Namen der Nutzer!
     */
    displayMailFailedError(names) {
        const namestr = names.reduce((str, name, idx) => {
            str += name;
            if (idx < names.length - 1) {
                str += ', ';
            }
            return str;
        }, '');
        const data = {
            title: this.translate.instant('ERROR.MAIL_FAILED_TITLE'),
            body: this.translate.instant('ERROR.MAIL_FAILED_BODY', {
                names: namestr
            }),
            btnLbl: this.translate.instant('DIALOG.OK')
        };
        return this.dialog.open(AlertDialComponent, { data }).afterClosed();
    }
    /**
     * Öffnet einen Dialog, der eine Warnung anzeigt, dass man sich selbst die Adminrechte entzieht
     */
    displayRemoveOwnRightsWarning() {
        const data = {
            title: this.translate.instant('WARNING.REMOVE_OWN_RIGHTS_TITLE'),
            body: this.translate.instant('WARNING.REMOVE_OWN_RIGHTS_BODY'),
            btnLbl: this.translate.instant('DIALOG.OK')
        };
        return this.dialog.open(AlertDialComponent, { data }).afterClosed();
    }
    /**
     * Wird aufgerufen, wenn versucht wird, einem Nutzer aus einer Gruppe zu entfernen,
     * für die man nicht zuständig ist, und man kein Admin ist.
     */
    removeGroupsNotManagerOfDialog() {
        const data = {
            title: this.translate.instant('EDIT_USER.CLICK_UNSELECTABLE_DIALOG_TITLE'),
            body: this.translate.instant('EDIT_USER.CLICK_UNSELECTABLE_DIALOG_BODY'),
            btnLbl: this.translate.instant('DIALOG.OK')
        };
        return this.dialog.open(AlertDialComponent, { data }).afterClosed();
    }
    /**
     * Dialog zum anzeigen, dass eine Gruppe nicht gelöscht werden kann. Wenn die Gruppe aktiv ist,
     * wird gefragt, ob die Gruppe stattdessen deaktiviert werden soll.
     *
     * @param group Benutzergruppe die nicht gelöscht werden kann
     * @return Observable, dass true emittet, wenn die Gruppe deaktiviert werden soll, ansonsten false
     */
    showCannotDeleteDialog(group) {
        if (group.active) {
            return this.dialog
                .open(ConfirmDialComponent, {
                data: {
                    title: this.translate.instant('DELETE_GROUP.DELETE_NOT_POSSIBLE'),
                    body: this.translate.instant('DELETE_GROUP.CANNOT_DELETE_GROUP', { groupname: group.name }),
                    confirmBtnLbl: this.translate.instant('DIALOG.YES'),
                    cancelBtnLbl: this.translate.instant('DIALOG.NO')
                }
            })
                .afterClosed()
                .pipe(map(result => !!result));
        }
        return this.dialog
            .open(AlertDialComponent, {
            data: {
                title: this.translate.instant('DELETE_GROUP.DELETE_NOT_POSSIBLE'),
                body: this.translate.instant('DELETE_GROUP.CANNOT_DELETE_AND_DEACTIVATE_GROUP', { groupname: group.name })
            }
        })
            .afterClosed()
            .pipe(map(_ => false));
    }
    /**
     * Gibt zurück, ob ein Dialog geöffnet ist
     */
    hasOpenDialogs() {
        return this.dialog.openDialogs && this.dialog.openDialogs.length > 0;
    }
}
DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.MatDialog)); }, token: DialogService, providedIn: "root" });
