import { EnvironmentService } from '../environment.service';
import * as i0 from "@angular/core";
import * as i1 from "../environment.service";
import * as i2 from "../../utils/injection-tokens";
/**
 * Service, welcher Berechtigungen von den Cordova Endgeräten abfragt.
 */
export class AndroidPermissionService {
    /**
     * Permissionservice-Konstruktor zur Berechtigungsanfrage am Android Cordova-Systemen.
     * @param envService EnvironmentService
     * @param window CAWindow
     */
    constructor(envService, window) {
        this.envService = envService;
        this.window = window;
        /** Parameter für Leserechte */
        this.READ_EXTERNAL_STORAGE = 'android.permission.READ_EXTERNAL_STORAGE';
        /** Fehlernachricht */
        this.ERR_MSG = 'permission filesystem read denied';
    }
    /**
     * Prüft, ob Berechtigungen (READ_EXTERNAL_STORAGE) für den Lesezugriff auf den Speicher des Gerätes erteilt wurden
     * und fordert diese ggf. neu an.
     */
    verifyFilesystemRead() {
        if (this.isActive()) {
            return this.has(this.READ_EXTERNAL_STORAGE, this.ERR_MSG);
        }
        return Promise.reject(this.ERR_MSG);
        // return this.has(this.isActive() ? this.READ_EXTERNAL_STORAGE : null);
    }
    isActive() {
        return this.envService.isAndroid && null != this.window.plugins.Permission;
    }
    has(permission, msg) {
        return new Promise((resolve, reject) => {
            this.window.plugins.Permission.has(permission, permResults => {
                permResults[permission] ? resolve() : resolve(this.request(permission, msg));
            }, err => {
                console.error(msg, err);
                reject();
            });
        });
    }
    request(permission, msg) {
        return new Promise((resolve, reject) => {
            this.window.plugins.Permission.request(permission, permResults => {
                if (permResults[permission]) {
                    resolve();
                }
                else {
                    console.log('permission', permission, permResults[permission]);
                    reject(new Error(msg));
                }
            }, err => {
                console.error(msg, err);
                reject(err);
            });
        });
    }
}
AndroidPermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AndroidPermissionService_Factory() { return new AndroidPermissionService(i0.ɵɵinject(i1.EnvironmentService), i0.ɵɵinject(i2.WINDOW_REF)); }, token: AndroidPermissionService, providedIn: "root" });
