import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const onDeviceReady = (): void => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
};

if (environment.production) {
  enableProdMode();
}

if (environment.isCordova) {
  document.addEventListener('deviceready', onDeviceReady, false);
} else {
  onDeviceReady();
}
