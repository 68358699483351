<div fxLayout="column">
  <ca-dlg-header [headerTitle]="'LOGIN.ABOUT' | translate" [hideInfoButton]="true"></ca-dlg-header>
</div>

<div class="mg-4-top" mat-dialog-content>
  <p class="MsoNormal">
    <span>
      {{ 'IMPRINT.DESC' | translate }}<br />
      EXEC IT Solutions GmbH.<br />
      <b><br />{{ 'IMPRINT.HEAD_OFFICE' | translate }}:</b><br />
      S&uuml;dstra&szlig;e 24, D 56235 Ransbach-Baumbach<br /><br />
      {{ 'IMPRINT.PHONE' | translate }}: +49 (0) 26 23 / 98 79 - 0<br />
      {{ 'IMPRINT.FAX' | translate }}: +49&nbsp; (0) 26 23 / 98 79 - 23<br />
      E-Mail: info(at)cocuun.de
    </span>
  </p>

  <p class="MsoNormal">
    <b
      ><span>{{ 'IMPRINT.BRANCH_OFFICE' | translate }}:</span></b
    >
    <span><br />Invalidenstra&szlig;e 90, D 10115 Berlin</span>
  </p>

  <p class="MsoNormal">
    <span
      ><br /><b>{{ 'IMPRINT.CEO' | translate }}:</b> <br />
      Dipl.-Kfm. Stefan Schwaderlapp<br />
      Dipl.-Inf. Bernd Philippi<br />
      <br />{{ 'IMPRINT.COURT' | translate }} Montabaur<br />HRB 4457<br />
      <br />{{ 'IMPRINT.VAT' | translate }}<br />DE 153436799<br />
    </span>
  </p>
</div>
