import { GenericMap } from './utility-types';
import { CustomerInfo } from 'src/app/entity/usergroup';

export function hasChanges<T>(initialObj: T, changedObj: T): boolean {
  return Object.keys(getChanges(initialObj, changedObj)).length > 0;
}

export function getChanges<T>(initialObj: T, changedObj: T): Partial<T> {
  const changes: Partial<T> = {};
  const keys = Object.keys(changedObj);
  for (const key of keys) {
    if (initialObj[key] !== changedObj[key]) {
      changes[key] = changedObj[key];
    }
  }
  return changes;
}

/**
 * Gibt zurück, ob sich die Zusatzinfo geändert hat
 */
export function hasCustomerInfoChanged(oldInfo: GenericMap<CustomerInfo>, newInfo: GenericMap<CustomerInfo>): boolean {
  if (oldInfo && newInfo) {
    if (Object.keys(oldInfo).length !== Object.keys(newInfo).length) {
      return true;
    }

    for (const uId in oldInfo) {
      if (
        !newInfo[uId] ||
        newInfo[uId].infoAdmin !== oldInfo[uId].infoAdmin ||
        newInfo[uId].infoAll !== oldInfo[uId].infoAll
      ) {
        if (
          newInfo[uId] &&
          !oldInfo[uId].infoAdmin &&
          !newInfo[uId].infoAdmin &&
          (!oldInfo[uId].infoAll && !newInfo[uId].infoAll)
        ) {
          return false;
        }
        return true;
      }
    }
  }
  return false;
}

/**
 * Gibt die Änderungen von zwei CustomerInfoMaps zurück
 * @param oldInfo alte CustomerInfoMap
 * @param newInfo neue CustomerInfoMap
 */
export function getCustomerInfoMapChanges(
  oldInfo: GenericMap<CustomerInfo>,
  newInfo: GenericMap<CustomerInfo>
): GenericMap<CustomerInfo> {
  const changes: GenericMap<CustomerInfo> = {};

  for (const uId in newInfo) {
    if (
      !oldInfo[uId] ||
      newInfo[uId].infoAdmin !== oldInfo[uId].infoAdmin ||
      newInfo[uId].infoAll !== oldInfo[uId].infoAll
    ) {
      changes[uId] = newInfo[uId];
    }
  }

  return changes;
}
