import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogData {
  title: string;
  body: string;
  confirmBtnLbl: string;
  cancelBtnLbl: string;
}

@Component({
  selector: 'ca-confirm-dial',
  templateUrl: './confirm-dial.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}
}
