import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RAWLANG_TOKEN } from '../utils/injection-tokens';
import { LocalStorageService } from './local-storage.service';

export interface Language {
  id: number;
  name: string;
  code: string;
  culture: string;
  icon: string;
  fullName: string;
}

/**
 * Interface für ein Objekt mit lokalisierten Texten pro Sprache
 * MUSS die gleichen Felder haben, welche in "availLangs" definiert sind!
 */
interface LangKey {
  de: string;
  en: string;
}

export interface LangDefinition {
  [id: string]: LangKey | LangDefinition;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private cookieKey = 'cc_lang_id';

  private all: Language[] = [
    {
      id: 1,
      name: 'DE',
      code: 'de',
      culture: 'de_DE',
      icon: 'flags:de',
      fullName: 'COMMON.LANGUAGE.DE'
    },
    {
      id: 2,
      name: 'EN',
      code: 'en',
      culture: 'en_US',
      icon: 'flags:gb',
      fullName: 'COMMON.LANGUAGE.EN'
    }
  ];

  private selLang: Language;

  constructor(
    public localStorageService: LocalStorageService,
    public translate: TranslateService,
    @Inject(RAWLANG_TOKEN) private rawLang: LangDefinition
  ) {}

  init(): void {
    const id = this.localStorageService.get(this.cookieKey);
    this.selLang = this.all.find(lang => lang.id === id) || this.all[0];

    this.initTransformedLangs();
    this.translate.setDefaultLang('de');
    this.translate.use(this.selLang.code);
  }

  get languages(): Language[] {
    return this.all;
  }

  get selectedLang(): Language {
    return this.selLang;
  }

  changeLang(language: Language): void {
    this.localStorageService.set(this.cookieKey, language.id);
    this.translate.use(language.code);
    this.selLang = language;
  }

  private initTransformedLangs(): void {
    // Verfügbare Sprachen initialisieren
    this.all.forEach(lang => this.translate.setTranslation(lang.code, {}));

    // Eigene Defintion parsen und in den Translate-Service schreiben
    this.analyseLangObject(null, this.rawLang);
  }

  private analyseLangObject(path: string, obj: object): void {
    // Für jeden Key...
    Object.keys(obj).forEach(key => {
      const localpath = path === null ? key : path + '.' + key;
      const value = obj[key];
      if (this.isLangKey(value)) {
        // Ist eine konkrete {de:..en:..}-Definition, pro Sprache hinterlegen
        this.all.forEach(lang => {
          this.translate.set(localpath, value[lang.code], lang.code);
        });
      } else {
        // Rekursiv weitersuchen
        this.analyseLangObject(localpath, value);
      }
    });
  }

  /** Stellt fest/rät zur Laufzeit, ob das übergeben Objekt das Lang-Key-Interface unterstützt */
  private isLangKey(obj: object): boolean {
    return this.all.filter(lang => null == obj[lang.code]).length === 0 && Object.keys(obj).length === this.all.length;
  }
}
