<div class="dlg-header" fxLayout="row">
  <div class="dlg-header-title" [ngClass]="hideInfoButton ? 'margin1' : 'margin2'" fxLayoutAlign="center center">
    <h1 mat-dialog-title class="ellipsis" testid="lblDlgHeaderTitle">{{ headerTitle }}</h1>
  </div>
  <div class="dlg-header-button" fxLayoutAlign="center center">
    <ng-content></ng-content>
    <button
      class="dlg-info-button"
      mat-icon-button
      (click)="openInfoDialog()"
      testid="btnDlgShowInfo"
      *ngIf="!hideInfoButton"
    >
      <mat-icon svgIcon="mdi:help_outline"></mat-icon>
    </button>
    <button class="dlg-close-button" mat-icon-button mat-dialog-close testid="btnDlgClose">
      <mat-icon svgIcon="mdi:close"></mat-icon>
    </button>
  </div>
</div>
