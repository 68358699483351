import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialComponent } from 'src/app/common-ui/modal/confirm-dial/confirm-dial.component';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../utils/injection-tokens";
/**
 * Registriert sich am updatesAvailable Observable vom service worker,
 * damit bei existierenden App updates ein Dialog geöffnet wird,
 * welcher den User fragt, ob er die App jetzt Aktualisieren möchte
 */
export class PromptUpdateService {
    constructor(updates, dialogService, translate, window) {
        this.updates = updates;
        this.dialogService = dialogService;
        this.translate = translate;
        this.window = window;
    }
    /**
     * Hier wird einmal eine Subscription am updates available Observable des Service Workers
     * gesetzt, um bei existierenden neuen updates einen Dialog auslösen, welcher den Nutzer fragt,
     * ob er die Anwendung jetzt aktualisieren möchte. Bestätigt der Nutzer diese Abfrage
     * wird der Service Worker angestoßen, das update auszführen und anschließend wird die
     * Anwendung neu geladen.
     */
    initialize() {
        // Subscriben auf dem updatesAvailable Observable
        this.updates.available
            .pipe(switchMap(event => {
            console.log('Current version: ' + JSON.stringify(event.current));
            console.log('Available version: ' + JSON.stringify(event.available));
            return this.dialogService
                .open(ConfirmDialComponent, {
                data: {
                    title: this.translate.instant('UPDATE.DLG_TITLE'),
                    body: this.translate.instant('UPDATE.UPDATE_AVAILABLE'),
                    confirmBtnLbl: this.translate.instant('DIALOG.YES'),
                    cancelBtnLbl: this.translate.instant('DIALOG.NO')
                }
            })
                .afterClosed();
        }))
            // Nach dem schließen des Dialogs
            .subscribe((updateNow) => {
            // ... wird geprüft, ob der user jetzt aktualisieren möchte
            if (updateNow) {
                // wenn ja wird das update ausgeführt und die Seite neu geladen
                this.updates.activateUpdate().then(() => this.window.location.reload());
            }
        });
    }
}
PromptUpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PromptUpdateService_Factory() { return new PromptUpdateService(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.MatDialog), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.WINDOW_REF)); }, token: PromptUpdateService, providedIn: "root" });
