import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
export class EnvironmentService {
    constructor() {
        this.definitions = {
            APP_VERSION: process.env.APP_VERSION,
            APP_ID: process.env.APP_ID,
            APP_NAME: process.env.APP_NAME,
            AUTHOR_NAME: process.env.AUTHOR_NAME,
            AUTHOR_EMAIL: process.env.AUTHOR_EMAIL,
            AUTHOR_HREF: process.env.AUTHOR_HREF,
            BUILD_NUMBER: process.env.BUILD_NUMBER,
            BUILD_TIME: Number(process.env.BUILD_TIME),
            DEBUG: Boolean(process.env.DEBUG),
            VCS_BRANCH: process.env.VCS_BRANCH,
            VCS_HASH: process.env.VCS_HASH
        };
    }
    /**
     * If environment is electron.
     */
    get isElectron() {
        return environment.isElectron;
    }
    /**
     * If environment is cordova.
     */
    get isCordova() {
        return environment.isCordova;
    }
    /**
     * If environment is web.
     */
    get isWeb() {
        return !environment.isCordova && !environment.isElectron;
    }
    /**
     * If environment is android.
     */
    get isAndroid() {
        return environment.isAndroid;
    }
    /**
     * If environment is iOS.
     */
    get isIOS() {
        return environment.isIOS;
    }
    /**
     * If angular runs in production mode.
     */
    get production() {
        return environment.production;
    }
    /**
     * Get a copy of app definitions.
     *
     * @return A copy of app definitions.
     */
    getDefinitions() {
        return Object.assign({}, this.definitions);
    }
    /**
     * Get a copy of app environment.
     *
     * @return A copy of app environment.
     */
    getEnvironment() {
        return Object.assign({}, environment);
    }
}
EnvironmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnvironmentService_Factory() { return new EnvironmentService(); }, token: EnvironmentService, providedIn: "root" });
