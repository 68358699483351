import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { FilterChipsComponent } from './filter-chips/filter-chips.component';
import { SearchbarFilterComponent } from './filter/searchbar-filter.component';
import { SearchbarInputComponent } from './input/searchbar-input.component';
import { SearchbarSortComponent } from './sort/searchbar-sort.component';

@NgModule({
  declarations: [SearchbarInputComponent, SearchbarFilterComponent, SearchbarSortComponent, FilterChipsComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MaterialModule,
    FormsModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  exports: [SearchbarInputComponent, SearchbarFilterComponent, SearchbarSortComponent, FilterChipsComponent]
})
export class SearchbarModule {}
