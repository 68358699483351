import { Routes } from '@angular/router';
import { AutologinGuard } from './core/guard/autologin.guard';
import { SessionCheckGuard } from './core/guard/session-check.guard';
import { LoginPageComponent } from './module/login/page/login/login-page.component';
import { environment } from 'src/environments/environment';
const ɵ0 = () => import("./module/client/client.module.ngfactory").then(m => m.ClientModuleNgFactory), ɵ1 = { page: 'clientoverview' };
const routes = [
    {
        path: '',
        canActivate: !environment.production || environment.isCordova ? [AutologinGuard] : [],
        component: LoginPageComponent
    },
    {
        path: 'clients/:clientId',
        canActivate: [SessionCheckGuard],
        loadChildren: ɵ0,
        data: ɵ1
    },
    {
        path: '**',
        redirectTo: ''
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
