import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "../utils/injection-tokens";
export class PermissionService {
    constructor(window) {
        this.window = window;
    }
    /**
     * Check browser compatibility for permissions.
     */
    supportsPermission() {
        return !!this.window.navigator.permissions;
    }
    /**
     * Query permission.
     */
    queryPermission(name) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.supportsPermission()) {
                return true;
            }
            const result = yield this.window.navigator.permissions.query({ name });
            return result.state === 'granted' || result.state === 'prompt';
        });
    }
}
PermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.ɵɵinject(i1.WINDOW_REF)); }, token: PermissionService, providedIn: "root" });
