import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import { LanguageComponent } from './language.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [LanguageComponent],
  imports: [MaterialModule, TranslateModule, CommonModule, FormsModule],
  exports: [LanguageComponent]
})
export class LanguageModule {}
